import { MutationTree, GetterTree } from 'vuex';
import { State } from '@root/modules/issue/types/issue';
import groupBy from 'lodash/groupBy';

export const state: () => State = () => ({
  isIssue: false,
  issueData: null,
  issueHeadlines: [],
});

export const getters: GetterTree<State, Record<string, unknown>> = {
  isIssue: (state) => {
    return state.isIssue;
  },
  getIssueData: (state) => {
    return state.issueData;
  },
  getIssueHeadlines: (state) => {
    return state.issueHeadlines;
  },
  getIssueHeadlinesGrouped: (state) => {
    return groupBy(state.issueHeadlines, (item) => item.primaryCategory?.name);
  },
};

export const mutations: MutationTree<State> = {
  setIssueData(state, issueData) {
    state.isIssue = !!issueData;
    state.issueData = issueData;
  },
  setIssueHeadlines(state, items) {
    state.issueHeadlines = items;
  },
};
