import apiConfig from '../../config/api.config';

const isServer = typeof window === 'undefined';

const envs = (isServer: boolean) => {
  return {
    configApi: isServer ? apiConfig.CONFIG_API_URL_SERVER : apiConfig.CONFIG_API_URL,
    streamsApi: apiConfig.STREAMS_API_URL,
    contentApiV3: isServer ? apiConfig.CONTENT_API_URL_V3_SERVER : apiConfig.CONTENT_API_URL_V3,
    customerDataApi: apiConfig.CUSTOMER_DATA_API_URL,
    mediaApi: isServer ? apiConfig.MEDIA_API_DATA_URL_SERVER : apiConfig.MEDIA_API_DATA_URL,
    mediaApiCropper: isServer ? apiConfig.MEDIA_API_URL_SERVER : apiConfig.MEDIA_API_URL,
    articleExtraContentApi: isServer ? apiConfig.ARTICLE_EXTRA_CONTENT_API_URL_SERVER : apiConfig.ARTICLE_EXTRA_CONTENT_API_URL,
    ipAccessApiUrl: apiConfig.IP_ACCESS_API_URL,
    pianoWorkerApiUrl: apiConfig.PIANO_WORKER_API_URL,
  };
};

export function getEnv(envType: keyof ReturnType<typeof envs>) {
  return envs(isServer)[envType] || '';
}
