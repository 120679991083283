
import { HeadlineTitle, HeadlineImage, HeadlineAuthor, HeadlineIcons, HeadlinePublishDate } from '../../fragments';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/1-10/blockType5';

const defaultSettings = {
  icons: true,
  author: true,
  titleComment: false,
  publishDate: true,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlineAuthor,
    HeadlineIcons,
    HeadlinePublishDate,
  },
});
