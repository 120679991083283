
import { Fragment } from './Fragment';
import { loadScript, Settings as LoadScriptSettings } from '@core/utils/helpers';
import srcConfig from '@core/config/src.config';

import { CoreWindow } from '@core/types/window';

const fragment = new Fragment();
fragment.setRequiredAttrs(['code', 'type', 'id']);

const FragmentComponent = fragment.createComponent();

// TODO: executeScript is it necessary?
export default FragmentComponent.extend({
  name: 'FragmentEmbed',
  async mounted() {
    const { code, type, id } = this;

    if (!code || !type) {
      return;
    }

    const src = srcConfig[type];

    this.setMountedState(true);

    await this.$nextTick();

    if (src) {
      await this.loadScriptWithSrc({ src, id });
    } else {
      this.executeScript({ code });
    }

    this.reInitScripts(type);
  },
  methods: {
    async loadScriptWithSrc({ src, id }: { src: string; id?: number }) {
      const loadScriptSettings: LoadScriptSettings = {
        attr: {
          async: true,
        },
        script: src,
      };

      if (id) {
        loadScriptSettings.attr.id = id;
      }

      try {
        await loadScript(loadScriptSettings);
      } catch (e) {
        console.error('FragmentEmbed loadScript error', e);
      }
    },
    executeScript({ code }: { code: string }) {
      const regExp = /<script\b[^>]*>(.*?)<\/script>/gm;
      const scripts = code.match(regExp);

      if (!scripts) {
        return;
      }

      scripts.forEach(async (script: string) => {
        const code = script.replace(/<\/?script\b[^>]*>/g, '');
        if (code) {
          eval(code);
        } else {
          const srcRegEx = /src="?(.*?)["> ]/g;
          const source = srcRegEx.exec(script);

          if (source && source.length > 0) {
            const loadScriptSettings: LoadScriptSettings = {
              attr: {
                async: true,
              },
              script: source[1],
            };

            try {
              await loadScript(loadScriptSettings);
            } catch (e) {
              console.error('FragmentEmbed loadScript error', e);
            }
          }
        }
      });
    },
    // Some embed services on SPA pages have to be re-inited,
    // because on first page load whole SDK was loaded, and after that only rendering functions have to triggered
    reInitScripts(type: string) {
      // FIXME:
      const win = window as unknown as CoreWindow;
      switch (type) {
        case 'playbuzz':
          if (win?.Playbuzz?.render) {
            win.Playbuzz.render();
          }
          break;
        case 'instagram':
          if (win?.instgrm?.Embeds?.process) {
            win.instgrm.Embeds.process();
          }
          break;
        case 'imgur':
          if (win?.imgurEmbed?.createIframe) {
            win.imgurEmbed.createIframe();
          }
          break;
        case 'twitter':
          if (win?.twttr?.widgets?.load) {
            win.twttr.widgets.load();
          }
          break;
      }
    },
  },
});
