
import Vue from 'vue';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import type { SectionCustomAdsOptions } from '@root/modules/feed/types';

type Items = Record<string, any>[];

interface Group {
  blocks: Items;
  ads: {
    type: string;
    class: string;
  } | null;
}

interface Props {
  items: Items;
  customAdsAlgorithm: SectionCustomAdsOptions[];
}

interface Computed {
  groupedItems: Group[];
  disabledAdsList: string[];
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    AdFormBanner,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    customAdsAlgorithm: {
      type: Array,
      required: false,
      default: null,
    },
  },
  computed: {
    groupedItems() {
      const items = [...this.items];
      const groupedItems: Group[] = [];
      let lastPosition = 0;

      this.customAdsAlgorithm.forEach((adOptions) => {
        const nextPosition = 'from' in adOptions ? adOptions.from : adOptions.after;

        const end = 'after' in adOptions ? adOptions.after - lastPosition : adOptions.to - adOptions.from + 1;
        const chunk: Group = {
          blocks: nextPosition === lastPosition ? [] : items.splice(0, end),
          ads: {
            type: adOptions.type,
            class: adOptions.type === 'tower' ? 'col-tower' : 'col-12',
          },
        };
        groupedItems.push(chunk);
        lastPosition = 'after' in adOptions ? adOptions.after : adOptions.to;
      });

      // Push rest blocks to the last chunk if exist
      if (items.length) {
        const chunk = {
          blocks: items,
          ads: null,
        };
        groupedItems.push(chunk);
      }
      return groupedItems;
    },
    disabledAdsList() {
      return this.$store.getters['adForm/getDisabledAdsList'];
    },
  },
});
