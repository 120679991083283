import { GetterTree, MutationTree } from 'vuex';
import { AlertComponentProps } from '@core/components/UI/alert/types';
import { PaywallState } from '@root/common/types/PaywallModal';

export const state = () => ({
  storage: {} as Record<string, unknown>,
  pageContentLoaded: null as string | null,
  alerts: [] as AlertComponentProps[],
  paywall: null as PaywallState | null,
  pianoInitializationError: false,
});

export type RootState = ReturnType<typeof state>;

export const getters: GetterTree<RootState, Record<string, unknown>> = {
  getStorage: (state) => (type: string) => {
    return state.storage[type];
  },
  paywall: (state) => state.paywall,
  getPianoInitializationError: (state) => state.pianoInitializationError,
};

export const mutations: MutationTree<RootState> = {
  setStorage(state, value: Record<string, unknown>) {
    state.storage = {
      ...state.storage,
      ...value,
    };
  },
  resetStorage(state, keys: string[]) {
    for (const key of keys) {
      if (key in state.storage) {
        delete state.storage[key];
      }
    }
  },
  setPageContentLoadTimeStamp(state, timestamp: string) {
    state.pageContentLoaded = timestamp;
  },
  alert(state, alertData: AlertComponentProps) {
    state.alerts.push(alertData);
  },
  openPaywall(state, paywall: PaywallState) {
    state.paywall = paywall;
    document.body.style.overflow = 'hidden';
  },
  closePaywall(state) {
    state.paywall = null;
    document.body.style.overflow = '';
  },
  setPianoInitializationError(state, value) {
    state.pianoInitializationError = value;
  },
};
