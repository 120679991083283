export const selfAdjustingInterval = (cb: (nr: number) => void, ms: number) => {
  let expected = Date.now() + ms;
  let timeout: ReturnType<typeof setTimeout> | undefined;

  const step = () => {
    const delta = Date.now() - expected;
    const ticks = Math.max(1, 1 + Math.round(delta / ms));
    cb(ticks);
    const addToExpected = ms * ticks;
    expected += addToExpected;

    timeout = setTimeout(step, addToExpected - delta);
  };

  timeout = setTimeout(step, ms);

  const stopInterval = () => {
    clearTimeout(timeout);
  };

  return stopInterval;
};
