import { Analytics } from '@analytics/types/Analytics';
import Awindow from '@analytics/types/Awindow';
import { GemiusOptions } from './types/Gemius';

import logger from '@analytics/util/logger';
import { loadScripts } from '@analytics/util/scriptLoader';

declare let window: Awindow;

export default class Gemius implements Analytics {
  private uid!: string;
  private firstHit = true;

  /**
   * Load script and initialize tracker
   */
  public async init(options: GemiusOptions) {
    if (!options?.uid) {
      logger.error('gemius id not provided');
      return;
    }

    await this.loadScript();

    if (!window.gemius_hcconn) {
      logger.error('gemius script does not exists on page');
      return;
    }

    this.changeTrackerUid(options?.uid);
    this.createGemiusActions();
  }

  public changeTrackerUid(uid?: string) {
    if (uid) {
      this.uid = uid;
      window.pp_gemius_identifier = this.uid;
    }
  }

  public sendPageEvent() {
    if (!window.gemius_hcconn) {
      logger.error('gemius script does not exists on page');
      return;
    }

    const identifier = this.firstHit
      ? this.uid
      : this.uid?.replace('USED_', '');

    if (window.pp_gemius_hit) {
      window.pp_gemius_hit(identifier);
      this.firstHit = false;
    }
  }

  private createGemiusActions() {
    const actions = [
      'gemius_hit',
      'gemius_event',
      'pp_gemius_hit',
      'pp_gemius_event',
    ];

    actions.forEach((action) => {
      const win = window as any;

      if (win[action]) {
        return;
      }

      this.addGemiusActionToWindow(action);
    });
  }

  private addGemiusActionToWindow(action: string) {
    const win = window as any;

    win[action] = (...args: any) => {
      const x = (win[`${action}_pdata`] = win[`${action}_pdata`] || []);
      x[x.length] = args;
    };
  }

  private async loadScript() {
    if (!window.gemius_hcconn) {
      const script = `http${
        window.location.protocol === 'https:' ? 's://s' : '://'
      }gaee.hit.gemius.pl/xgemius.js`;

      try {
        await loadScripts(script);
      } catch (e) {
        console.error('Gemius loadScript error', e);
      }
    }
  }
}
