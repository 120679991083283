
import Vue from 'vue';
const calculateHorizontalAnimationDuration = (contentWidth: number, parentWidth?: number): number => {
  let desiredSpeed = 0;

  if (parentWidth) {
    desiredSpeed = contentWidth / parentWidth;
  }

  return desiredSpeed * 1000;
};
interface Props {
  text: string;
}

interface Data {
  isInitialTitleAnimation: boolean;
  isFinalTitleAnimation: boolean;
  timer: ReturnType<typeof setTimeout> | undefined;
  animationDuration: number;
}

interface Methods {
  getAnimationDuration(): Promise<number>;
  clearTimer(): void;
  startAnimation(): void;
}

interface Computed {
  isAnimated: boolean;
}
export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isInitialTitleAnimation: false,
      isFinalTitleAnimation: false,
      timer: undefined,
      animationDuration: 0,
    };
  },
  watch: {
    text: {
      async handler() {
        this.clearTimer();
        this.isInitialTitleAnimation = false;
        this.isFinalTitleAnimation = false;
        await this.$nextTick();
        if (this.isAnimated) {
          this.startAnimation();
        }
      },
      immediate: true,
    },
  },
  computed: {
    isAnimated() {
      return this.text.length > 50;
    },
  },
  methods: {
    async startAnimation() {
      const animationDuration = await this.getAnimationDuration();
      this.animationDuration = animationDuration * 10;
      this.isInitialTitleAnimation = true;

      this.timer = setTimeout(
        () => {
          this.animationDuration = (animationDuration + animationDuration / 2) * 10;
          this.isInitialTitleAnimation = false;
          this.isFinalTitleAnimation = true;
        },
        animationDuration * 10 + 5500
      );
    },
    async getAnimationDuration() {
      const parentElement = this.$refs.titleContainer as HTMLElement;
      return calculateHorizontalAnimationDuration(parentElement.scrollWidth, parentElement.clientWidth);
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = undefined;
    },
  },
  mounted() {
    this.startAnimation();
  },
});
