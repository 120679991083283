export default {
  AD_BLOCK_LAYER: {
    types: {
      view: {
        action: 'ab_view',
        label: '{"event_name": "ab_view", "block_name": "Adblock layer 2021"}',
      },
      click: {
        action: 'ab_click',
        label: '{"event_name": "ab_click", "block_name": "Adblock layer 2021", "link_title": "${data.link_title || "Delfi Kogupakett"}"}',
      },
    },
  },
  NPS: {
    types: {
      click: {
        action: 'event',
        label:
          '{"category": "NPS", "event_name": "NPS_rating", "satisfaction": "${data.satisfaction}", "type": "${data.type}", "comment": "${data.comment}", "article_id": "${data.article_id}" }',
      },
    },
  },
};
