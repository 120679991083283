import { type Route } from 'vue-router';
import { type StartCheckoutByTypeOptions, type StartCheckoutOptions } from '@piano/types/pianoTp';

interface OpenCheckout {
  type: 'startCheckout';
  params: StartCheckoutOptions;
}

interface OpenCheckoutByType {
  type: 'startCheckoutByType';
  params: StartCheckoutByTypeOptions;
}

type CheckoutField = 'offerId' | 'termId' | 'checkoutFlowId' | 'position' | 'promoCode';
type CheckoutInfo = Record<CheckoutField, string | undefined>;

export class CheckoutLink {
  private readonly _query: Route['query'];
  private readonly _checkoutInfo: CheckoutInfo = {
    checkoutFlowId: undefined,
    offerId: undefined,
    termId: undefined,
    position: undefined,
    promoCode: undefined,
  };

  constructor(query: Route['query']) {
    this._query = query;

    // In order to fix "No index signature with a parameter of type string was found on type CheckoutInfo"
    let key: keyof CheckoutInfo;
    for (key in this._checkoutInfo) {
      this._checkoutInfo[key] = this.getFieldFromQueryIfValid(key);
    }
  }

  private getFieldFromQueryIfValid(key: string): string | undefined {
    const value = this._query[key];
    return typeof value === 'string' ? value : undefined;
  }

  public getCheckoutOpenParamsIfValid(): OpenCheckout | OpenCheckoutByType | null {
    const { offerId, termId, checkoutFlowId, position, promoCode } = this._checkoutInfo;

    if (position) {
      return {
        type: 'startCheckoutByType',
        params: {
          type: position,
          promoCode,
        },
      };
    }

    if (offerId && termId && checkoutFlowId) {
      return {
        type: 'startCheckout',
        params: {
          termId,
          offerId,
          checkoutFlowId,
          promoCode,
        },
      };
    }

    return null;
  }

  public getQueryWithoutCheckoutFields(): Route['query'] {
    const query = Object.assign({}, this._query);

    for (const key in this._checkoutInfo) {
      delete query[key];
    }

    return query;
  }
}
