
import Vue from 'vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import SocialLinks from '@root/common/components/base/SocialLinks.vue';
import { Footer } from '@root/modules/navigation/types/navigation';

interface Props {
  navigation: Footer;
}
interface Methods {
  openCookieConsent: () => void;
}
export default Vue.extend<unknown, Methods, unknown, Props>({
  components: {
    LinkHandler,
    SocialLinks,
  },
  props: {
    navigation: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openCookieConsent() {
      this.$store.commit('analytics/setConsentSettingsVisibility', true);
    },
  },
});
