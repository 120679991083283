import { formatCustomDate, isDateBetween, isDateSameOrBefore } from '@core/filters/dateFormater';
import { Stream } from '@headlines/components/block/21-30/block-type-23/types/blockType23';

export const getTime = (stream: Stream) => {
  const startDate = stream.date.start;
  const filter = 'HH:mm';
  return formatCustomDate(startDate, filter);
};

export const formatDateForDay = (startDate: string, locale: string) => {
  const formattedLocale = locale === 'et_EE' ? 'et' : 'ru';
  const filter = formattedLocale === 'et' ? '· DD. MMM' : '· DD MMM';

  const suffix = formatCustomDate(startDate, filter, formattedLocale);
  const currentDayFilter = 'dd';
  const currentDay = formatCustomDate(startDate, currentDayFilter, formattedLocale);

  return formattedLocale === 'et' ? `${currentDay[0]} ${suffix}` : `${currentDay} ${suffix}`;
};

export const isDateToday = (date: string) => {
  const inputDate = new Date(date);
  const today = new Date();

  return inputDate.getFullYear() === today.getFullYear() && inputDate.getMonth() === today.getMonth() && inputDate.getDate() === today.getDate();
};

export const isLive = (stream: Stream) => {
  return isDateBetween(new Date().toString(), stream.date.start, stream.date.end);
};

export const isStreamUpcoming = (stream: Stream) => {
  return isDateSameOrBefore(new Date().toString(), stream.date.end);
};
