
import Vue from 'vue';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import getItemFromObject from 'lodash/get';

interface Props {
  headline: HeadlineWithRelatedArticles;
  separator: boolean;
}

interface Computed {
  classModifier: Record<string, any>;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    LinkHandler,
  },
  props: {
    headline: {
      type: Object,
      required: true,
    },
    separator: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    classModifier() {
      return { 'headline-related-title--with-separator': this.separator };
    },
  },
  methods: {
    getItemFromObject,
  },
});
