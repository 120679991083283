import { ApolloClient, InMemoryCache, HttpLink, PureQueryOptions, MutationOptions, ApolloQueryResult, FetchResult } from '@apollo/client/core';
import fetch from 'isomorphic-fetch';
import type { OperationVariables } from '@apollo/client/core/types';

// Cache implementation
const cache = new InMemoryCache();

export async function handleApolloClientQuery<T>(options: PureQueryOptions, api: string): Promise<ApolloQueryResult<T>> {
  try {
    return await apolloClient(api).query<T>(options);
  } catch (error) {
    throw new Error('Apollo Client query failed');
  }
}

export async function handleApolloClientMutation<TData, TVariables extends OperationVariables = OperationVariables>(
  options: MutationOptions<TData, TVariables>,
  api: string
): Promise<FetchResult<TData>> {
  try {
    return await apolloClient(api).mutate<TData, TVariables>(options);
  } catch (error) {
    throw new Error('Apollo Client mutation failed');
  }
}

function apolloClient(uri: string) {
  const httpLink = new HttpLink({ uri: uri.concat('/graphql'), fetch });

  return new ApolloClient({
    link: httpLink,
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });
}
