import { Analytics } from '@analytics/types/Analytics';
import logger from '@analytics/util/logger';
import { ClarityOptions } from './types/Clarity';
import loadClarityScript from './utils/loadClarityScript';

export default class Clarity implements Analytics {
  private options!: ClarityOptions;

  public init(options: ClarityOptions) {
    if (!options.key) {
      logger.error('Clarity key not provided');
      return;
    }
    this.options = options;
    this.loadScript();
  }

  private loadScript() {
    const { key } = this.options;
    loadClarityScript(key);
  }
}
