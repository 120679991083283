import { Plugin } from '@nuxt/types';
import initCurrentChannelId from '@root/libs/init-channel/utils/initCurrentChannelId';
import initApplicationType from '@root/libs/init-channel/utils/initApplicationType';

const initSettings: Plugin = async (ctx) => {
  const { req, res, $config, store } = ctx;
  const application = initApplicationType(req);

  if (process.server) {
    const isDev: boolean = $config.FAKE_DOMAIN_ENABLED;
    const channelId = initCurrentChannelId({ req, res, isDev });

    const channelConfig = store.getters['getStorage']('channelConfig');
    channelConfig.settings.application = application;

    // Save data to avoid redundant data fetching in CSR
    store.commit('setStorage', { channelConfig, channelId });
  } else if (process.client) {
    const channelConfig = store.getters['getStorage']('channelConfig');
    channelConfig.settings.application = application;
    // Remove SSR application data from CSR channelConfig
    store.commit('setStorage', { channelConfig });
  }
};

export default initSettings;
