import et_EE from './aliases/et_EE';
import ru_EE from './aliases/ru_EE';

type Aliases = Record<string, string>;

// Add all locales aliases into one object
const locales: Record<string, Aliases> = { et_EE, ru_EE };
const combinedAliases: Record<string, string[]> = {};
const paths: string[] = [];

// Merge locale aliases into one object
Object.keys(locales).forEach((locale) => {
  Object.keys(locales[locale]).forEach((alias) => {
    combinedAliases[alias] = combinedAliases[alias] ? combinedAliases[alias] : [];
    combinedAliases[alias].push(locales[locale][alias]);

    paths.includes(alias) ? paths.push(locales[locale][alias]) : paths.push(alias, locales[locale][alias]);
  });
});

export { paths, locales };
export default combinedAliases;
