// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
export default function loadBbcTrackerScript() {
  !((s, e, n, c, _) => {
    let r: HTMLElement = '';
    (_ = s._ns_bbcws = s._ns_bbcws || _),
      s[_] ||
        ((s[_ + '_d'] = s[_ + '_d'] || []),
        (s[_] = function () {
          // eslint-disable-next-line prefer-rest-params
          s[_ + '_d'].push(arguments);
        }),
        (s[_].sources = [])),
      c &&
        s[_].sources.indexOf(c) < 0 &&
        (((r = e.createElement(n)).async = 1),
        (r.src = c),
        (n = e.getElementsByTagName(n)[0]).parentNode.insertBefore(r, n),
        s[_].sources.push(c));
  })(
    window,
    document,
    'script',
    'https://static.files.bbci.co.uk/ws-partner-analytics/v5/js/pageTracker.min.js',
    's_bbcws',
  );
}
