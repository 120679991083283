
import Vue, { PropType } from 'vue';
import VideoPlayer from '@media/components/video/VideoPlayer.vue';
import { State as ArticleStoreState } from '@root/modules/article/types/article';
import { buildAbsoluteRoute } from '@root/router/utils/buildRoutePath';
import buildPictureUrl from '@core/utils/buildPictureUrl';

type widgetType = 'video' | 'stream';
interface Settings {
  reRenderFragment?: boolean;
  shouldPlayerFloat?: boolean;
}

interface Props {
  content: {
    attrs: any;
  };
  type: widgetType;
  settings?: Settings;
}

interface Computed {
  activeArticleData: ArticleStoreState['activeArticle'];
  getKeywords: string[];
  adFree: boolean;
  ads: {
    adFree: boolean;
    keywords: string[];
  };
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    VideoPlayer,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    type: {
      type: String as () => widgetType,
      required: false,
      default: 'video',
    },
    settings: {
      type: Object as PropType<Settings>,
      required: false,
    },
  },
  jsonld() {
    if (!this.activeArticleData) {
      return null;
    }

    const pathOptions = {
      type: 'article',
      id: this.activeArticleData.id,
      slug: this.activeArticleData.slug,
      channel: this.activeArticleData.primaryCategory.channel,
    };
    const activeArticleURL = buildAbsoluteRoute({ pathOptions });

    const jsonLdObject = {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      name: this.activeArticleData.content.title.text,
      description: this.activeArticleData.content.lead.text,
      uploadDate: this.activeArticleData.publishAt,
    };

    if (!this.activeArticleData.content.paywall.enabled) {
      Object.assign(jsonLdObject, { contentUrl: activeArticleURL });
    }

    const previewImgId = this.content.attrs.metadata.videoPreviewImage?.id || this.activeArticleData.metaImage?.id;

    if (previewImgId) {
      const imageSize = { width: 1200, height: 800 };
      const thumbnailImgUrl = buildPictureUrl({ id: previewImgId, cropperData: imageSize });
      Object.assign(jsonLdObject, { thumbnailUrl: thumbnailImgUrl });
    }

    return jsonLdObject;
  },
  computed: {
    activeArticleData() {
      return this.$store.getters['article/getActiveArticle'];
    },
    getKeywords() {
      return this.$store.getters['adForm/getAdsKeywords'];
    },
    adFree() {
      return this.$store.state.piano.access.adFree || false;
    },
    ads() {
      return {
        adFree: this.adFree,
        keywords: this.getKeywords,
      };
    },
  },
});
