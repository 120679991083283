import { render, staticRenderFns } from "./BlockType11List.vue?vue&type=template&id=66811a8f&scoped=true"
import script from "./BlockType11List.vue?vue&type=script&lang=ts"
export * from "./BlockType11List.vue?vue&type=script&lang=ts"
import style0 from "./BlockType11List.vue?vue&type=style&index=0&id=66811a8f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66811a8f",
  null
  
)

export default component.exports