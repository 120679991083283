import BlockType1Component from '@headlines/components/block/1-10/BlockType1.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType1: AdminBlockType = {
  component: BlockType1Component,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    author: false,
    titleComment: true,
    channel: false,
    category: false,
    relatedTitles: true,
    articlesLinksHash: '',
  }),
  articlesCount: 1,
};

export default AdminBlockType1;
