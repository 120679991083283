
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import { HeadlineTitle, HeadlineImage, HeadlineIcons } from '../../fragments';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/11-20/blockType20';
import chunkArray from '@headlines/utils/ChunkArray';
import { Headline } from '@headlines/types/headline';
import { GetCategoriesWithHeadlines } from '@root/modules/category/types/categories';
import CategoriesWithHeadlinesService from '@headlines/services/CategoriesWithHeadlines.service';
import { TrackingData } from '@root/common/types/fragment';
import getHeadlineWithHref from '@root/modules/headlines/utils/getHeadlineWithHref';

interface Methods {
  chunkArray: (items: Headline[], perChunk: number) => Headline[][];
}

interface Data {
  mappedCategoriesWithHeadlines: {
    name: string;
    link?: string;
    decorationColor?: string;
    headlines: GetCategoriesWithHeadlines['data']['headlines']['items'];
  }[];
}

interface Computed {
  categories: {
    name?: string;
    id: number;
    link?: string;
    decorationColor?: string;
  }[];
}

interface Props {
  data: {
    categories: {
      name?: string;
      id: number;
      link?: string;
      decorationColor?: string;
    }[];
  };
  tracking: TrackingData | undefined;
  domain: string | undefined;
}

const defaultSettings = {
  icons: true,
  category: true,
  showFbShares: false,
  data: {},
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<Data, Methods, Computed, Props>({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlineIcons,
    LinkHandler,
  },
  methods: {
    chunkArray,
  },
  async created() {
    const categoriesWithHeadlinesService = new CategoriesWithHeadlinesService();
    const fakeDomainEnabled = this.$config.FAKE_DOMAIN_ENABLED;

    const [...categoriesWithHeadlines] = await Promise.all(
      this.categories.map((category) => categoriesWithHeadlinesService.fetch({ id: category.id, limit: 4 }))
    );

    for (const dataCategory of this.categories) {
      const categoryWithHeadlines = categoriesWithHeadlines.find((category) => category.categories.data[0].id === dataCategory.id);

      if (!categoryWithHeadlines) {
        continue;
      }

      this.mappedCategoriesWithHeadlines.push({
        name: dataCategory.name || categoryWithHeadlines.categories.data[0].name,
        link: dataCategory.link,
        decorationColor: dataCategory.decorationColor,
        headlines: categoryWithHeadlines.headlines.items.map((headline) => {
          return getHeadlineWithHref({ headline, domain: this.domain || '', fakeDomainEnabled, tracking: this.tracking });
        }),
      });
    }
  },
  data() {
    return {
      mappedCategoriesWithHeadlines: [],
    };
  },
  computed: {
    categories() {
      return this.data.categories || this.getSettings.data.categories;
    },
  },
});
