
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { ExtendedProfile, PianoCustomerProfile } from '@piano/types/pianoState';
import HeaderNewBubble from '~/src/common/components/layout/header/HeaderNewBubble.vue';

interface Props {
  data: {
    isLoggedIn: boolean;
    profile: PianoCustomerProfile | null;
    extendedProfile: ExtendedProfile | null;
  };
  openLoginModal: () => void;
  logout: () => void;
}

interface Computed {
  title: string;
  hasChannelSubscription: boolean;
  isLoggedIn: boolean;
  list: {
    class?: string;
    type?: string;
    content?: {
      class?: string;
      target?: string;
      href: string | TranslateResult;
      icon: string[];
      text: string | TranslateResult;
    };
    hideItem?: boolean;
    isNew?: boolean;
  }[];
  hideOrdering: boolean;
  isFirstNameFilled: boolean;
  isB2bCustomer: boolean;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: { HeaderNewBubble },
  props: {
    data: {
      type: Object,
      required: true,
    },
    openLoginModal: {
      type: Function,
      required: true,
    },
    logout: {
      type: Function,
      required: true,
    },
  },
  computed: {
    list() {
      const list = [
        {
          class: 'header-customer-menu__item-content--separator display-none display-lg-block',
        },
        {
          type: 'profile',
          content: {
            class: `header-customer-menu__login-button${!this.isFirstNameFilled && this.data.isLoggedIn ? ' header-customer-menu__login-button--red-dot' : ''}`,
            href: this.$t('login.profile_url'),
            icon: ['user', 'angle-right'],
            target: '_blank',
            text: this.$t('login.profile'),
          },
          hideItem: !this.isLoggedIn,
        },
        {
          type: 'profile',
          content: {
            href: this.$t('login.subscription_url'),
            icon: ['crown', 'angle-right'],
            target: '_blank',
            text: this.$t('login.subscriptions'),
          },
          hideItem: !this.isLoggedIn,
        },
        {
          type: 'bookmarks',
          content: {
            href: this.$t('bookmark.bookmark_url'),
            icon: ['bookmark', 'angle-right'],
            text: this.$t('login.read_later'),
          },
          hideItem: !this.isLoggedIn,
        },
        {
          type: 'support',
          class: 'header-customer-menu__item-content',
          content: {
            href: 'https://www.delfimeedia.ee/kontakt',
            icon: ['question-bubble', 'angle-right'],
            text: this.$t('login.support'),
          },
          hideItem: !this.isLoggedIn,
        },
        {
          type: 'monitoring',
          class: 'header-customer-menu__item-content--last',
          content: {
            href: 'https://delfimonitor.texta.ee/',
            icon: ['eye-protection', 'angle-right'],
            target: '_blank',
            text: this.$t('login.monitoring'),
          },
          hideItem: !(this.isLoggedIn && this.isB2bCustomer),
          isNew: true,
        },
        {
          class: 'header-customer-menu__item-content--separator display-none display-lg-block',
        },
      ];
      return list.filter((item) => !item.hideItem);
    },
    title() {
      const name = this.data.extendedProfile?.displayName;
      return name ? `${this.$t('login.hello')}, ${name}!` : `${this.$t('login.hello')}!`;
    },
    hasChannelSubscription() {
      return this.$store.state.piano.access.channelAccess;
    },
    isLoggedIn() {
      return this.data.isLoggedIn;
    },
    hideOrdering() {
      const { specialFeatures } = this.$channelConfig('settings').application;
      return specialFeatures.hideSignupAndOrders.enabled;
    },
    isFirstNameFilled() {
      return this.$store.state.piano.profile?.firstName || false;
    },
    isB2bCustomer() {
      return this.$store.state.piano.access.monitoring || false;
    },
  },
});
