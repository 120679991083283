import BlockType6Component from '@headlines/components/block/1-10/BlockType6.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType6: AdminBlockType = {
  component: BlockType6Component,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    titleComment: true,
    publishDate: false,
    contentSeparator: false,
    articlesLinksHash: '',
  }),
  articlesCount: 1,
};

export default AdminBlockType6;
