
import Vue from 'vue';
import Toggle from '@core/components/UI/form/Toggle.vue';
import { Purpose } from '@iabtechlabtcf/core';

type LegitimateGroups = {
  purpose: Purpose;
  selected: boolean;
}[];

interface Props {
  purposes: Purpose[];
  legitimateInterestPurposes: number[];
}

interface Data {
  allGroupsSelected: boolean;
  legitimateInterestPurposesLocal: number[];
  alwaysSelectedGroupId: number;
}

interface Computed {
  legitimateGroups: LegitimateGroups;
}

interface Methods {
  selectAllGroup: () => void;
  selectGroup: (group: any) => void;
}

const alwaysSelectedGroupId = 1;

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Toggle,
  },
  props: {
    purposes: {
      type: Object,
      required: true,
    },
    legitimateInterestPurposes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      alwaysSelectedGroupId,
      allGroupsSelected: false,
      legitimateInterestPurposesLocal: [alwaysSelectedGroupId],
    };
  },
  computed: {
    legitimateGroups() {
      const legitimateGroups: LegitimateGroups = [];
      Object.keys(this.purposes).forEach((key) => {
        const index = Number(key);
        const group = {
          purpose: this.purposes[index],
          selected:
            this.legitimateInterestPurposesLocal.length === 0 || this.legitimateInterestPurposesLocal.includes(index) || index === this.alwaysSelectedGroupId,
        };
        legitimateGroups.push(group);
      });
      return legitimateGroups;
    },
  },
  watch: {
    allGroupsSelected() {
      this.selectAllGroup();
    },
    legitimateInterestPurposes: {
      handler() {
        this.legitimateInterestPurposesLocal = [this.alwaysSelectedGroupId, ...this.legitimateInterestPurposes];
        this.allGroupsSelected = this.legitimateInterestPurposesLocal.length - 1 === this.legitimateGroups.length;
      },
      immediate: true,
    },
  },
  methods: {
    selectGroup(group) {
      const selectedPurposes: number[] = [];
      this.legitimateGroups.forEach((gr) => {
        if (gr.purpose.id === group.purpose.id) {
          gr.selected = group.selected;
        }
        if (gr.selected) {
          selectedPurposes.push(gr.purpose.id);
        }
      });

      this.$emit('consent-legitimate-purposes', selectedPurposes);
    },
    selectAllGroup() {
      this.legitimateInterestPurposesLocal = this.allGroupsSelected
        ? Object.keys(this.purposes).map((key) => this.purposes[Number(key)].id)
        : [this.alwaysSelectedGroupId];
    },
  },
});
