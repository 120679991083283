
import Vue from 'vue';
import Player from '@media/components/base/Player.vue';
import config from '@media/config/media.config';
import { getAudio } from '@media/services/getAudio';
import { AudioItem } from '@media/types/getAudio';
import { PortalPlayer } from '@media/services/player';

interface Props {
  data: {
    attrs: AudioItem;
  };
  type: string;
}

interface Data {
  containerId: string;
  audioData: AudioItem | null;
}

interface Methods {
  setSettings: () => Record<string, unknown>;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  components: {
    Player,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      containerId: `media-lead-${this.type}-${this.data.attrs.id}`,
      audioData: null,
    };
  },
  methods: {
    setSettings() {
      const { attrs } = this.data;
      const file = this.audioData?.audioData?.m3u8;
      const imageId = attrs.metadata?.audioPreviewImage?.id || this.audioData?.metadata.audioPreviewImage?.id;
      const title = attrs.metadata?.title || this.audioData?.metadata.title;

      return {
        preload: 'auto',
        autoPause: {
          pauseAds: false,
          viewability: false,
        },
        aspectratio: '16:9',
        height: '100%',
        width: '100%',
        horizontalVolumeSlider: true,
        pipIcon: 'disabled',
        file,
        title,
        image: imageId ? `${config.api.mediaApiUrl}/${imageId}.jpg` : null,
        playbackRateControls: true,
        intl: {
          en: {
            advertising: {
              cuetext: '',
            },
          },
        },
      };
    },
  },
  mounted() {
    if (!this.data.attrs.id) {
      return false;
    }
    this.$nextTick(async () => {
      const data = await getAudio({ id: this.data.attrs.id });
      this.audioData = (data && data.audio.items[0]) || null;
      if (this.audioData) {
        const settings = this.setSettings();
        const player = new PortalPlayer(this.containerId, settings, this.audioData);
        player.on('player-ready', () => {
          player.setLayout(`${this.data.attrs.type}_LEAD`);
        });
      }
    });
  },
});
