
import Vue from 'vue';
import { AdFormOptions, AdBlockFallbackType } from '@ads/types/AdForm';
import Embed from '@ads/components/adBlock/Embed.vue';
import AdNet from '@ads/components/adBlock/AdNet.vue';

interface Computed {
  fallbackComponent?: string;
}
interface Props {
  settings: AdFormOptions['adblockFallback'];
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    AdNet,
    Embed,
  },
  props: {
    settings: {
      type: Object as () => AdFormOptions['adblockFallback'],
      default: () => ({}),
      required: false,
    },
  },
  computed: {
    fallbackComponent() {
      switch (this.settings?.type) {
        case AdBlockFallbackType.embed:
          return 'Embed';
        case AdBlockFallbackType.adnet:
          return 'AdNet';
        default:
          console.log('Invalid ad fallback configuration type');
      }
      return undefined;
    },
  },
});
