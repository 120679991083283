import { Plugin } from '@nuxt/types';
import { getConsentCookies } from '@root/modules/analytics/utils/cookieConsent';
import { localConsentCookieName, euConsentCookieName, nonEuConsentCookieName } from '@root/modules/analytics/config/cookie-consent/cookieConsent.config';

const cookieConsentPlugin: Plugin = async (ctx) => {
  const { $sentry, store, app } = ctx;
  const { specialFeatures } = app.$channelConfig('settings').application;
  const disableConsent = specialFeatures.disableConsentModal || false;

  if (disableConsent) {
    return;
  }

  try {
    store.commit('analytics/setCookieConsentSSRFetchStatus', false);
    await getConsentCookies([localConsentCookieName, euConsentCookieName, nonEuConsentCookieName]);
  } catch (error) {
    $sentry.captureException(error, { tags: { cookieConsent: 'cookiesFetchFailedOnConsentPlugin', 'process.type': process.server ? 'server' : 'client' } });
  }
};

export default cookieConsentPlugin;
