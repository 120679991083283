import { PianoState } from '@piano/types/pianoState';

export const defaultLocale = 'et_EE';
export const pianoLocales: Record<string, string> = {
  et_EE: 'et_EE',
  ru_EE: 'ru_RU',
};

export const defaultProfile: PianoState['profile'] = {
  lastName: '',
  firstName: '',
  email: '',
  uid: '',
  sub: '',
  emailConfirmationRequired: false,
  confirmed: false,
  valid: false,
  subscriptionId: '',
  uidHash: '',
};

export const defaultExtendedProfile: PianoState['extendedProfile'] = {
  birthday: '',
  phone: '',
  sex: '',
  location: '',
  language: '',
  displayName: '',
  cxenseUserHash: '',
  cxenseUserId: '',
  agreedToMarketingConsent: false,
  hasRecentlyEndedSubscription: false,
  abandonedCartData: null,
  isAbandonedCartActive: false,
};

export const defaultAccess = {
  channelAccess: false,
  adFree: false,
  monitoring: false,
};

export const defaultActiveCheckoutInfo = {
  offerId: null,
  termId: null,
  termName: null,
  startedWithoutAuthentication: true,
};
