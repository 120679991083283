import merge from 'lodash/merge';
import { Cluster, Redis } from 'ioredis';
const redis = process.client ? null : require('../redisInstance').default;
const RedisDataLoader = process.client ? null : require('@eg/redis-dataloader').RedisDataloader;

type RedisDataLoaderOptions = {
  locker?: {
    lockInterval: number;
    maxLockPeriod: number;
  };
  redis?: Redis | Cluster | null;
  local?: {
    cache?: boolean;
    expireTimeMs?: number;
    maxBatchSize?: number;
    batchScheduleFn?: (callback: () => void) => void;
  };
  remote?: {
    keyPrefix: string;
    expireTimeMs?: number;
    gracePeriodMs?: number;
  };
  gracePeriodLoadTimeMs?: number;
  cacheKeyFn?: (key: any) => string;
};

const dataLoader = <CallbackArguments, Response>(fetchData: (callBackParams: CallbackArguments) => Promise<Response>, customConfig: RedisDataLoaderOptions) => {
  if (!RedisDataLoader || redis?.status !== 'ready') {
    return null;
  }

  const dataloaderConfig: RedisDataLoaderOptions = {
    redis,
    // ApolloClient query/mutation timeout in Portal Connector
    gracePeriodLoadTimeMs: 5000,
    local: {
      cache: false,
      expireTimeMs: 10000,
      maxBatchSize: 100,
      batchScheduleFn: (callback): any => setTimeout(callback, 50),
    },
    remote: {
      keyPrefix: 'portal-root',
      expireTimeMs: 60000, // 1 minute
      gracePeriodMs: 4 * 3600 * 1000, // 4 hours
    },
    cacheKeyFn: (params: Record<string, unknown>) => {
      const key = JSON.stringify(params.variables || params);
      return key;
    },
  };

  merge(dataloaderConfig, customConfig);

  const batchFunction = async (callBackParams: CallbackArguments[]) => {
    const response = await Promise.all(
      callBackParams.map(async (params) => {
        return await fetchData(params);
      })
    );

    return response;
  };
  return new RedisDataLoader(batchFunction, dataloaderConfig);
};

export { dataLoader, RedisDataLoaderOptions };
