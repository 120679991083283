import { GetChannelConfigTypes } from '../types';
import { Config } from '../../../modules/channel/types/channel';

export default function initChannelConfig(channelConfig: Config) {
  const initChannelConfig = (key: GetChannelConfigTypes) => {
    if (!key || key === 'config') {
      return channelConfig;
    } else {
      return channelConfig[key];
    }
  };

  return initChannelConfig;
}
