
import Vue from 'vue';
import Player from '@media/components/base/Player.vue';
import config from '@media/config/media.config';
import { getAudio } from '@media/services/getAudio';
import { AudioItem } from '@media/types/getAudio';
import { PortalPlayer } from '@media/services/player';
import jwplayer from '@media/types/JwPlayer';
import { popupWindow, copyLink } from '@media/utils/sharing';
import { AdsData, getPlayerAdSchedule } from '@media/utils/getPlayerAdSchedule';
import translit from '@ekd/translit';

import AudioPlayerPlaylist from '@media/components/audio/AudioPlayerPlaylist.vue';
import AudioPlayerTitle from '@media/components/audio/AudioPlayerTitle.vue';

type OptionsType = 'rate' | 'sharing';

type ShareData = {
  title: string;
  text: string;
  url: string;
};

interface ShNavigator extends Navigator {
  share: (data?: ShareData) => Promise<void>;
}

interface ShWindow extends Window {
  [index: string]: any;
  navigator: ShNavigator;
}

declare let window: ShWindow;

interface Props {
  data: {
    attrs: AudioItem;
  };
  type: string;
  ads: AdsData;
}

interface AudioMeta {
  title: string | null;
  credit: string;
  pic: string | null;
  podcastId: string;
}

interface Data {
  activeEpisodeId: string;
  trackChanged: boolean;
  player: jwplayer.JWPlayer | null;
  containerId: string;
  isPlaying: boolean;
  audioData: AudioItem | null;
  audioMeta: AudioMeta;
  playlist: AudioItem[];
  showModal: boolean;
  playBackRate: number;
  elapsedTime: number | string;
  optionsType: OptionsType;
  sharing: {
    facebook: string;
    twitter: string;
  };
}

interface Computed {
  toggleIcon: string;
  toggleClass: string;
  hasChannelAccess: boolean;
}

interface Methods {
  handleTrackChange: (id: string) => void;
  mountPlayer: (settings: Record<string, unknown>, mediaData: AudioItem, startPlaying?: boolean) => void;
  setPlayerSettings: (mediaData: AudioItem) => Record<string, unknown>;
  getAudioMeta: (mediaData: AudioItem) => AudioMeta;
  userHasAccessToMedia: (id: string) => boolean;
  rewind: () => void;
  fastForward: () => void;
  handlePlayPause: (episodeId: string) => void;
  updatePlayerLayout: () => void;
  removeOldElements: (sliderbarElement: HTMLElement) => void;
  addPlayerEventListeners: (sliderbarElement: HTMLElement, buttonContainerElement: Element | null, elapsedTimeElement: HTMLElement) => void;
  toggleOptions: (type: OptionsType) => void;
  setPlayBackRate: (rate: number) => void;
  shareUrl: (type: string) => void;
  nativeShare: () => void;
  initializePlayer: (id: string) => Promise<void>;
  showPaywall: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    AudioPlayerTitle,
    AudioPlayerPlaylist,
    Player,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    ads: {
      type: Object as () => AdsData,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeEpisodeId: '',
      containerId: `media-widget-${this.type}-${this.data.attrs.id}`,
      isPlaying: false,
      audioData: null,
      trackChanged: false,
      audioMeta: {} as AudioMeta,
      playlist: [],
      player: null,
      showModal: false,
      playBackRate: 1,
      playbackRates: config.player.playbackRates,
      optionsType: 'rate',
      elapsedTime: '00:00',
      sharing: {
        facebook: 'https://www.facebook.com/sharer/sharer.php',
        twitter: 'https://twitter.com/share',
      },
    };
  },
  computed: {
    toggleIcon() {
      return this.isPlaying ? 'toggle-pause' : 'toggle-play';
    },
    toggleClass() {
      return this.isPlaying ? 'audio-player__control--pause' : 'audio-player__control--play';
    },
    hasChannelAccess() {
      return this.$store.state.piano.access.channelAccess;
    },
  },
  methods: {
    handleTrackChange(id) {
      if (!this.userHasAccessToMedia(id)) {
        this.showPaywall();
        return;
      }

      const episode = this.playlist.find((episode) => episode.id === id);
      if (episode) {
        this.trackChanged = true;
        this.isPlaying = false;
        this.activeEpisodeId = episode.id;

        this.audioMeta.title = episode?.metadata.title ?? '';
        const settings = this.setPlayerSettings(episode);
        this.mountPlayer(settings, episode, true);
      }
    },
    getAudioMeta(mediaData) {
      let credit = this.data?.attrs.metadata?.credit || mediaData?.metadata.credit || '';
      const title = this.data?.attrs.metadata?.title || mediaData?.metadata.title || '';
      const pic = mediaData.metadata.audioPreviewImage?.id ? `${config.api.mediaApiUrl}/${mediaData.metadata.audioPreviewImage?.id}.jpg?w=172&h=172` : null;
      const podcastId = mediaData?.podcasts?.items[0]?.id ?? '';

      if (this.playlist.length > 0) {
        credit = mediaData?.podcasts?.items[0]?.metadata?.title || credit;
      }

      return { credit, title, pic, podcastId };
    },
    setPlayerSettings(mediaData) {
      const ads = Object.create(this.ads);
      const metadata = mediaData?.metadata;
      const file = mediaData.audioData?.m3u8;
      const parentTitle = metadata.parentTitle;
      const parentTitleSlug = translit(parentTitle || '');

      if (parentTitleSlug) {
        ads.keywords = [...(ads.keywords || []), `audio-collection-name-${parentTitleSlug}`];
      }

      const advertising = metadata.adsDisabled ? {} : getPlayerAdSchedule('audio', ads);

      return {
        preload: 'auto',
        width: '100%',
        height: 1,
        volume: 50,
        mute: false,
        title: this.audioMeta.title,
        file,
        playbackRateControls: true,
        advertising,
        intl: {
          en: {
            advertising: {
              cuetext: '',
            },
          },
        },
      };
    },
    mountPlayer(settings, mediaData, startPlaying?: boolean) {
      const player = new PortalPlayer(this.containerId, settings, mediaData);
      player.on('player-ready', (jwplayer) => {
        player.setLayout(this.data.attrs.type);
        this.player = jwplayer;

        this.updatePlayerLayout();

        if (this.player) {
          this.playBackRate = this.player.getPlaybackRate();
          this.player.on('playbackRateChanged', (data) => {
            this.playBackRate = data.playbackRate;
          });
        }
      });
      if (startPlaying) {
        this.handlePlayPause(mediaData.id);
      }
    },
    updatePlayerLayout() {
      const playerContainer = this.player?.getContainer() as HTMLElement;
      const buttonContainerElement = playerContainer?.querySelector('.jw-controlbar');
      const elapsedTimeElement = buttonContainerElement?.querySelector('.jw-text-elapsed') as HTMLElement;
      elapsedTimeElement.innerText = '00:00';
      const durationTime = buttonContainerElement?.querySelector('.jw-text-duration') as HTMLElement;
      durationTime.innerText = '00:01';
      const sliderbarElement = this.$refs.controlBar as HTMLElement;

      if (this.trackChanged) {
        this.removeOldElements(sliderbarElement);
      }

      sliderbarElement.appendChild(durationTime);
      playerContainer.style.height = '0px';
      this.elapsedTime = elapsedTimeElement.innerHTML;

      this.addPlayerEventListeners(sliderbarElement, buttonContainerElement, elapsedTimeElement);
    },
    removeOldElements(sliderbarElement) {
      const currentDuration = sliderbarElement.querySelector('.jw-text-duration:last-child');
      if (currentDuration) {
        currentDuration.remove();
      }
      const currentElapsedTime = sliderbarElement.querySelector('.jw-text-elapsed:first-child');
      if (currentElapsedTime) {
        currentElapsedTime.remove();
      }
      const currentSlider = sliderbarElement.querySelector('.jw-slider-time:first-child');
      if (currentSlider) {
        currentSlider.remove();
      }
    },
    addPlayerEventListeners(sliderbarElement, buttonContainerElement, elapsedTimeElement) {
      this.player?.on('seeked', () => {
        this.player?.play();
        this.isPlaying = true;
      });
      this.player?.on('adPlay', (data) => {
        if (data.oldstate === 'buffering') {
          const slider = buttonContainerElement?.querySelector('.jw-slider-time') as HTMLElement;
          slider.classList.add('jw-slider-time--ads-slider');
          sliderbarElement.prepend(slider);
        }
        this.isPlaying = true;
      });
      this.player?.on('firstFrame', () => {
        const slider = buttonContainerElement?.querySelector('.jw-slider-time') as HTMLElement;
        slider.classList.remove('jw-slider-time--ads-slider');
        sliderbarElement.prepend(slider);
      });
      this.player?.on('adTime', () => {
        this.elapsedTime = elapsedTimeElement.innerHTML;
      });
      this.player?.on('time', () => {
        this.elapsedTime = elapsedTimeElement.innerHTML;
      });
      this.player?.on('adComplete', () => {
        this.elapsedTime = elapsedTimeElement.innerHTML;
      });
      this.player?.on('complete', () => {
        this.isPlaying = false;
      });
    },
    rewind() {
      if (!this.userHasAccessToMedia(this.activeEpisodeId)) {
        return;
      }

      const position = this.player?.getPosition();
      if (position) {
        const endPosition = position - 15 >= 0 ? position - 15 : 0;
        this.player?.seek(endPosition);
      }
    },
    fastForward() {
      if (!this.userHasAccessToMedia(this.activeEpisodeId)) {
        return;
      }

      this.player?.seek(this.player.getPosition() + 15);
    },
    handlePlayPause(episodeId) {
      if (!this.isPlaying && !this.userHasAccessToMedia(episodeId)) {
        this.showPaywall();
        return;
      }
      this.isPlaying ? this.player?.pause() : this.player?.play();
      this.isPlaying = !this.isPlaying;
    },
    nativeShare() {
      const url = window.location.href;
      const title = window.document.title;
      const text = window.document.querySelector('meta[name="description"]')?.getAttribute('content') || '';
      if (window.navigator.share) {
        window.navigator.share({
          title,
          text,
          url,
        });
      } else if (window.AndroidWebAppMobileAPI && window.AndroidWebAppMobileAPI.sharePage) {
        window.AndroidWebAppMobileAPI.sharePage(url, title, text);
      } else {
        this.showModal = !this.showModal;
      }
    },
    toggleOptions(type) {
      this.optionsType = type;
      if (type === 'sharing') {
        this.nativeShare();
      } else {
        this.showModal = !this.showModal;
      }
    },
    setPlayBackRate(rate) {
      this.player!.setPlaybackRate(rate);
      this.showModal = false;
    },
    shareUrl(type) {
      switch (type) {
        case 'link':
          copyLink(window.location.href);
          break;
        case 'facebook':
          // eslint-disable-next-line no-case-declarations
          const urlFb = `${this.sharing.facebook}?u=${encodeURI(window.location.href)}&t=${encodeURI(document.title)}`;
          popupWindow(urlFb);
          break;
        case 'twitter':
          // eslint-disable-next-line no-case-declarations
          const urlTw = `${this.sharing.twitter}?u=${encodeURI(window.location.href)}&t=${encodeURI(document.title)}`;
          popupWindow(urlTw);
          break;
      }
    },
    async initializePlayer(id: string) {
      const res = await getAudio({ id });

      if (!res?.audio.items[0]) {
        return;
      }

      this.audioData = res.audio.items[0];

      // Overwrite adsDisabled property if provided by content-api fragment
      if (this.data.attrs.metadata.adsDisabled) {
        this.audioData.metadata.adsDisabled = this.data.attrs.metadata.adsDisabled;
      }

      const podcastEpisodes = this.audioData.podcasts?.items[0]?.episodes.items || [];
      const parentTitle = this.audioData.podcasts?.items[0]?.metadata?.title || '';
      this.audioData.metadata.parentTitle = parentTitle;

      // If more than one episode exists, adjust episode
      // object and set it into playlist array
      if (podcastEpisodes.length > 0) {
        this.activeEpisodeId = this.audioData.id;
        this.playlist = podcastEpisodes.map((episode) => {
          episode.metadata.parentTitle = parentTitle;
          return episode;
        });
      }

      this.audioMeta = this.getAudioMeta(this.audioData);
      const settings = this.setPlayerSettings(this.audioData);
      this.mountPlayer(settings, this.audioData);
    },
    userHasAccessToMedia(id) {
      const episode = this.playlist.find((episode) => episode.id === id);
      const isEpisodePaywallEnabled = episode?.paywallEnabled[0]?.value || false;
      const isPodcastPaywallEnabled = this.audioData?.podcasts?.items?.[0]?.paywallEnabled?.[0]?.value || false;

      return !(isPodcastPaywallEnabled || isEpisodePaywallEnabled) || this.hasChannelAccess;
    },
    showPaywall() {
      const paywallPayload = {
        description: this.$t('paywall_modal.article_audio.description'),
        perks: this.$t('paywall_modal.article_audio.perks'),
      };

      this.$store.commit('openPaywall', paywallPayload);
    },
  },
  watch: {
    playBackRate(val, oldVal) {
      if (val !== oldVal) {
        this.setPlayBackRate(val);
      }
    },
  },
  async mounted() {
    if (!this.data.attrs.id) {
      return false;
    } else {
      await this.initializePlayer(this.data.attrs.id);
    }
  },
});
