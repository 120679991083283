import { Plugin } from '@nuxt/types';

const ArticleViewStatisticsPlugin: Plugin = async (ctx) => {
  const { app, store } = ctx;
  const analytics = app.$channelConfig('analytics');

  if (!analytics?.articleStatistics?.url) {
    return;
  }

  const { articleStatistics } = analytics;

  // Watch activeArticlePath store state to send article page view
  store.watch(
    (state) => state.article.activeArticlePath,
    async (activeArticlePath) => {
      if (activeArticlePath && store.state.article.activeArticle) {
        new Image().src = `${articleStatistics.url}/${store.state.article.activeArticle.id}`;
      }
    },
    {
      immediate: true,
    }
  );
};

export default ArticleViewStatisticsPlugin;
