const hmacSha256 = async (str: string | number, secret: string) => {
  const enc = new TextEncoder();
  const key = await window.crypto.subtle.importKey(
    'raw',
    enc.encode(secret),
    {
      name: 'HMAC',
      hash: { name: 'SHA-256' },
    },
    false,
    ['sign', 'verify']
  );
  const signature = await window.crypto.subtle.sign('HMAC', key, enc.encode(str.toString()));
  const b = new Uint8Array(signature);
  return Array.prototype.map.call(b, (x) => x.toString(16).padStart(2, '0')).join('');
};

export const hashCustomerId = async (id: string | undefined) => {
  if (process.client && id) {
    return await hmacSha256(id, 'cxensesecret');
  } else {
    return '';
  }
};
