import { PianoConfig } from '@piano/types/pianoState';

export const getOfferType = (offerId: string | null, offersConfiguration: PianoConfig['offersConfiguration']): string | undefined => {
  for (const [type, offer] of Object.entries(offersConfiguration)) {
    for (const configuration of Object.values(offer)) {
      if (configuration.offerId === offerId) {
        return type;
      }
    }
  }
};
