import { VendorList } from '@iabtechlabtcf/core';
import Service from '../../../common/base/Service';
import { nonTcfVendorsListUrl, nonTcfPartners, vendorsListUrl } from '../config/cookie-consent/cookieConsent.config';
import { CookieConsentNonTcfVendor } from '../types/analytics';

export const fetchVendorsList = async () => {
  const service = new Service();
  const apiProvider = service.createProvider('HTTP');
  const vendors = await apiProvider.request<VendorList>({
    url: `${vendorsListUrl}/vendor-list.json`,
    method: 'get',
  });
  return vendors;
};

export const fetchNonTcfVendorsListUrl = async () => {
  const service = new Service();
  const apiProvider = service.createProvider('HTTP');
  const vendors = await apiProvider.request<CookieConsentNonTcfVendor[]>({
    url: nonTcfVendorsListUrl,
    method: 'get',
    params: {
      ids: nonTcfPartners,
    },
  });
  return vendors;
};

export const fetchPurposes = async (lang: string) => {
  const service = new Service();
  const apiProvider = service.createProvider('HTTP');
  const purposes = await apiProvider.request<VendorList>({
    url: `${vendorsListUrl}/purposes-${lang.toLowerCase()}.json`,
    method: 'get',
  });
  return purposes;
};

export const fetchConsentData = async (lang: string) => {
  const service = new Service();
  const apiProvider = service.createProvider('HTTP');
  const vendors = await apiProvider.request<VendorList>({
    url: `/api/vendors-list-v3`,
    method: 'get',
    params: {
      lang,
    },
  });

  return vendors || null;
};
