export default {
  SYNC_SEGMENTS: {
    types: {
      sync: {
        eventType: 'sync',
        params: '{ "segmentIds": "${data.segmentIds}", "userId": "${data.userId}" }',
      },
    },
  },
  AUDIO_PLAYER_INITED: {
    types: {
      playerInited: {
        eventType: 'player',
        params: '{ "taskuPlayer": "${data.taskuPlayer}" }',
      },
    },
  },
};
