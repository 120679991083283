import { Plugin } from '@nuxt/types';
import { GetSiteControl } from '@analytics/trackers';
import getEventByKey from '../utils/getEventByKey';
import events from '../config/getSiteControl';
import interpolate from '@root/common/utils/interpolate';
import { GetSiteControlEvent } from '@root/modules/analytics/types/analytics';
import merge from 'lodash/merge';

interface BaseParams {
  segmentIds: string;
  userId: string;
  taskuPlayer: boolean;
}

const GetSiteControlPlugin: Plugin = async (ctx) => {
  const { app, store } = ctx;
  const analytics = app.$channelConfig('analytics');
  const { id, locale } = app.$channelConfig('settings');

  if (!(analytics?.getSiteControl?.enabled && analytics?.getSiteControl?.url)) {
    return;
  }

  const gsc = new GetSiteControl();

  try {
    await gsc.init({ url: analytics.getSiteControl.url });
  } catch (e) {
    console.error(e);
    return;
  }

  const baseParams: BaseParams = {
    segmentIds: '',
    userId: '',
    taskuPlayer: false,
  };

  const setGscParams = (baseParams: BaseParams, customerExtra?: Record<string, unknown>) => {
    const loggedIn = store.state.piano.isLoggedIn;
    const customerProfile = '';
    let hasChannelAccess = '';
    const productType: string[] = [];

    if (loggedIn) {
      if (store.state.piano.subscriptionInfo) {
        productType.push(store.state.piano.subscriptionInfo.type);
      }
      hasChannelAccess = store.state.piano.access.channelAccess.toString();
    }

    let params: Record<string, string> = {
      profile: customerProfile,
      site: id,
      segmentIds: baseParams.segmentIds,
      userId: baseParams.userId,
      taskuPlayer: baseParams.taskuPlayer.toString(),
      loginStatus: loggedIn.toString(),
      productType: productType.length ? String(productType) : 'empty',
      hasChannelAccess,
    };

    const { appName, appVersion, type } = app.$channelConfig('settings').application;

    const appParams = {
      ...(appName && { using_app: appName }),
      ...(appVersion && { using_app_version: appVersion }),
      ...(type && { using_app_os: type }),
    };

    params = merge(params, appParams);

    if (customerExtra) {
      params = merge(params, customerExtra);
    }

    gsc.params(params);
  };

  const handleNewClickEvent = (clickEventName: any) => {
    if (!clickEventName) {
      return;
    }

    const event = getEventByKey<GetSiteControlEvent>(events, clickEventName, locale);

    if (event) {
      if (!store.state.analytics.tracker.getSiteControl.clickEventData) {
        return false;
      }

      const { eventType, data } = store.state.analytics.tracker.getSiteControl.clickEventData;
      const eventData = event.types[eventType];
      const { params } = eventData;
      const eventParams = params ? JSON.parse(interpolate(params, { data })) : '';
      baseParams.segmentIds = eventParams.segmentIds ?? baseParams.segmentIds;
      baseParams.userId = eventParams.userId ?? baseParams.userId;
      baseParams.taskuPlayer = eventParams.taskuPlayer ?? baseParams.taskuPlayer;

      setGscParams(baseParams);
    }

    store.commit('analytics/setClickEvent', { getSiteControl: { eventName: null, eventData: null } });
  };

  // Check for store state before applying watcher
  handleNewClickEvent(store.state.analytics.tracker.getSiteControl.clickEventName);

  // Watch user login/logout
  store.watch(
    (state) => state.piano.access,
    async () => {
      const mappedCustomerExtra = mapCustomerExtra();
      setGscParams(baseParams, mappedCustomerExtra);
    }
  );

  // Watch analytics store eventName change, set segmentId & userId
  store.watch(
    (state) => state.analytics.tracker.getSiteControl.clickEventName,
    async (clickEventName) => handleNewClickEvent(clickEventName)
  );

  const mapCustomerExtra = () => {
    const isLoggedIn = store.state.piano.isLoggedIn;

    if (isLoggedIn) {
      const extendedProfile = store.state.piano.extendedProfile;
      return {
        firstName: store.state.piano.profile.firstName ? 'not missing' : 'missing',
        lastName: store.state.piano.profile.lastName ? 'not missing' : 'missing',
        location: extendedProfile?.location || 'missing',
        gender: extendedProfile?.sex || 'missing',
        birthday: extendedProfile?.birthday || 'missing',
        language: extendedProfile?.language || 'missing',
      };
    }

    return {};
  };
};

export default GetSiteControlPlugin;
