import channels, { Channel } from '@root/modules/channel/config/channel.config';

type BuildNavigationUrl = (url: string, fakeDomainEnabled?: boolean) => string;

function buildNavigationDevUrl(channelConfig: Channel, url?: string) {
  let pathName;

  if (url) {
    try {
      pathName = new URL(url).pathname;
    } catch (e) {
      console.log('Failed to parse url in buildNavigationDevUrl', e);
    }
  }

  // In case path is frontpage, pahtName is /
  // in case path is latest, pathName is /latest
  // So in case of frontpage function return will make double slash
  // TODO: Try to use URL class, to combine path properly
  if (pathName === '/') {
    pathName = '';
  }

  const channelDomain = channelConfig.domain;
  const channelParam = channelDomain ? `?site=${channelDomain}` : '';
  const origin = process.client ? document.location.origin : '';

  // URL pathname parameter already contains slash
  return `${origin}${pathName}/${channelParam}`;
}

// Build dev or live issue url
const buildNavigationUrl: BuildNavigationUrl = (url, fakeDomainEnabled) => {
  let newUrl = url;

  const isTest = typeof window === 'undefined' ? process.env.CHANNEL_CONFIG_ENV === 'test' : /.test.delfi/.test(document.location.hostname);

  if (!fakeDomainEnabled) {
    return isTest ? newUrl.replace('.delfi', '.test.delfi') : newUrl;
  }
  // TODO: Change after Content-api channel object will return channel url
  const channelConfig =
    channels.find((ch) => {
      const urlHost = url.replace(/^https?:\/\//, '').split('/');
      const chUrlHost = ch.url.replace(/^https?:\/\//, '').split('/');

      return urlHost[0] === chUrlHost[0];
    }) || null;

  if (channelConfig) {
    newUrl = buildNavigationDevUrl(channelConfig, url);
  }

  return newUrl;
};

export default buildNavigationUrl;
