import { Analytics } from '@analytics/types/Analytics';
import Awindow from '@analytics/types/Awindow';
import { DelfiTEEOptions } from './types/DelfiTEE';

import logger from '@analytics/util/logger';
import { loadScripts } from '@analytics/util/scriptLoader';

declare let window: Awindow;

export default class DelfiTEE implements Analytics {
  private options!: DelfiTEEOptions;
  private edTracker!: Awindow['EDTracker'];

  /**
   * Load script and initialize tracker
   */
  public async init(options?: DelfiTEEOptions) {
    if (options) {
      this.options = options;
    }

    await this.loadScript();

    if (!window.EDTracker) {
      logger.error('EDTracker script does not exists on page');
      return;
    }

    // @ts-ignore
    this.edTracker = new window.EDTracker(this.options);
  }

  public signal(
    type: string,
    body?: Record<string, unknown> | string,
    priority?: number,
  ) {
    if (!this.edTracker) {
      logger.error('edTracker does not exists in context');
      return;
    }

    this.edTracker.signal(type, body, priority);
  }

  private async loadScript() {
    if (!window.EDTracker) {
      try {
        // Can't be loaded over HTTP, keeps giving 502
        await loadScripts('https://s.delfi.ee/t.js?instanced');
      } catch (e) {
        console.error('DelfiTEE loadScript error', e);
      }
    }
  }
}
