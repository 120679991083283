
import Vue from 'vue';
import GalleryWrapper from '@gallery/components/GalleryWrapper.vue';
import GalleryData from '@gallery/utils/GalleryData';
import { GetGalleries } from '@gallery/types/Gallery';

interface Data {
  galleryData: GalleryData | null;
}

interface Methods {
  fetchMoreImages: () => Promise<void>;
}

interface Computed {
  gallery: GetGalleries['data']['gallery']['items'][0] | null;
}

interface Props {
  galleryId: string | null;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    GalleryWrapper,
  },
  computed: {
    gallery() {
      return this.galleryData?.gallery.images.items.length ? this.galleryData.gallery : null;
    },
  },
  data() {
    return {
      galleryData: null,
    };
  },
  props: {
    galleryId: {
      type: String,
      required: true,
    },
  },
  methods: {
    async fetchMoreImages() {
      await this.galleryData?.fetch();
    },
  },
  watch: {
    galleryId: {
      async handler(newId) {
        if (!newId) {
          console.log('[Gallery] No gallery ID given');
          return;
        }
        this.galleryData = new GalleryData(newId);
        await this.galleryData.fetchInitialGallery();
      },
      immediate: true,
    },
  },
});
