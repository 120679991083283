import BlockType1 from '@headlines/components/block/1-10/BlockType1.vue';
import BlockType2 from '@headlines/components/block/1-10/block-type-2/BlockType2.vue';
import BlockType3 from '@headlines/components/block/1-10/block-type-3/BlockType3.vue';
import BlockType4 from '@headlines/components/block/1-10/block-type-4/BlockType4.vue';
import BlockType5 from '@headlines/components/block/1-10/BlockType5.vue';
import BlockType6 from '@headlines/components/block/1-10/BlockType6.vue';
import BlockType7 from '@headlines/components/block/1-10/BlockType7.vue';
import BlockType8 from '@headlines/components/block/1-10/BlockType8.vue';
import BlockType9 from '@headlines/components/block/1-10/BlockType9.vue';
import BlockType10 from '@headlines/components/block/1-10/BlockType10.vue';
import BlockType11 from '@headlines/components/block/11-20/block-type-11/BlockType11.vue';
import BlockType12 from '@headlines/components/block/11-20/BlockType12.vue';
import BlockType13 from '@headlines/components/block/11-20/block-type-13/BlockType13.vue';
import BlockType14 from '@headlines/components/block/11-20/block-type-14/BlockType14.vue';
import BlockType15 from '@headlines/components/block/11-20/BlockType15.vue';
import BlockType16 from '@headlines/components/block/11-20/BlockType16.vue';
import BlockType17 from '@headlines/components/block/11-20/BlockType17.vue';
import BlockType18 from '@headlines/components/block/11-20/BlockType18.vue';
import BlockType19 from '@headlines/components/block/11-20/block-type-19/BlockType19.vue';
import BlockType20 from '@headlines/components/block/11-20/BlockType20.vue';
import BlockType21 from '@headlines/components/block/21-30/BlockType21.vue';
import BlockType22 from '@headlines/components/block/21-30/BlockType22.vue';
import BlockType23 from '@headlines/components/block/21-30/block-type-23/BlockType23.vue';
import BlockType24 from '@headlines/components/block/21-30/BlockType24.vue';
import BlockType25 from '@headlines/components/block/21-30/BlockType25.vue';
import BlockType26 from '@headlines/components/block/21-30/BlockType26.vue';
import BlockType27 from '@headlines/components/block/21-30/block-type-27/BlockType27.vue';
import BlockType28 from '@headlines/components/block/21-30/BlockType28.vue';
import BlockType29 from '@headlines/components/block/21-30/BlockType29.vue';
import BlockType30 from '@headlines/components/block/21-30/BlockType30.vue';

export const BlockCollection = {
  BlockType1,
  BlockType2,
  BlockType3,
  BlockType4,
  BlockType5,
  BlockType6,
  BlockType7,
  BlockType8,
  BlockType9,
  BlockType10,
  BlockType11,
  BlockType12,
  BlockType13,
  BlockType14,
  BlockType15,
  BlockType16,
  BlockType17,
  BlockType18,
  BlockType19,
  BlockType20,
  BlockType21,
  BlockType22,
  BlockType23,
  BlockType24,
  BlockType25,
  BlockType26,
  BlockType27,
  BlockType28,
  BlockType29,
  BlockType30,
};
