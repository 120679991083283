
import Vue from 'vue';
import { AdFormOptions, AdFormPlacementType, AdFormProps } from '@ads/types/AdForm';
import { AdsWindow } from '@ads/types/window';
import { loadScript } from '@ads/utils/scriptLoader';
import { afp as afpUrl } from '@ads/config/api.config';
import adsConfig from '@ads/config/adForm.config';
import adsSettings from '@ads/config/placementsSettings.config';
import AdBlockFallback from '@ads/components/adBlock/AdBlockFallback.vue';

interface Data {
  divId: string;
  adFormType: AdFormPlacementType | null;
  isMounted: boolean;
  hasBeenRendered: boolean;
  adBlockFallback?: AdFormOptions['adblockFallback'];
}

interface Computed {
  classModifier: Record<string, unknown>;
  disableAd: boolean;
  isMobileOrTablet: boolean;
  disabledAdsList: string[];
  adRenderingState: boolean;
  isAdBlocked: boolean;
}

interface Props {
  adType: string;
  uniqueIdentifier: string;
  settings: AdFormOptions;
}

interface Methods {
  adProperties: (placements: Record<string, AdFormOptions>) => AdFormProps;
  initAfp: () => Promise<void>;
  getAdFormType: (adType: Props['adType']) => AdFormPlacementType | null;
  placeAd: (adProps: AdFormProps) => void;
}
declare const window: AdsWindow;

const mobileAds = adsConfig.mobileAds as AdFormPlacementType[];
const mobileFallbackAds = adsConfig.mobileFallbackAds as Record<string, AdFormPlacementType>;
let adCount = 0;

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'AdFromBanner',
  components: {
    AdBlockFallback,
  },
  props: {
    adType: {
      type: String,
      default: '',
      required: false,
    },
    uniqueIdentifier: {
      type: String,
      default: '',
      required: false,
    },
    settings: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      divId: '',
      adFormType: null,
      isMounted: false,
      hasBeenRendered: false,
      adBlockFallback: undefined,
    };
  },
  computed: {
    isMobileOrTablet() {
      return (typeof window !== 'undefined' && window.innerWidth <= 991) || false;
    },
    disableAd() {
      return this.disabledAdsList.includes(this.adType);
    },
    disabledAdsList() {
      return this.$store.getters['adForm/getDisabledAdsList'];
    },
    classModifier() {
      return { [`banner--${this.adFormType}`]: this.adFormType };
    },
    adRenderingState() {
      return this.$store.getters['adForm/setAdsRenderingState'];
    },
    isAdBlocked() {
      return this.$store.getters['adForm/getAdBlockLayerState'];
    },
  },
  watch: {
    async adRenderingState(canRender) {
      if (canRender && !this.$isServer) {
        await this.initAfp();
      }
    },
    async disableAd(isDisabled, wasDisabled) {
      // Trigger this check after user with subscriber/adFree account logs out
      if (!isDisabled && wasDisabled && this.hasBeenRendered) {
        this.hasBeenRendered = false;
        await this.$nextTick();
        await this.initAfp();
      }
    },
  },
  methods: {
    adProperties(placements) {
      const keywords: string[] = this.$store.getters['adForm/getAdsKeywords'];
      const keyValues: Record<string, string> = this.$store.getters['adForm/getAdsKeyValues'];

      const settingsMkw = this.settings.mkw || [];
      const settingsMkv = this.settings.mkv || {};

      const placementSettings = adsSettings[this.adFormType as AdFormPlacementType] || {};

      const adProps: AdFormProps = {
        dom: this.uniqueIdentifier || this.divId,
        ...placements[this.adFormType as AdFormPlacementType], // Channel config ads section placements settings
        ...placementSettings, // Local placements settings
        ...this.settings, // Prop placements settings
        // mkw, mkv, interscrollerWrappers and lazyload should be at the bottom of the object
        // it's necessary to overwrite other settings, that may be invalid (Wrong channel config user input etc.)
        mkw: [...keywords, ...settingsMkw],
        mkv: { ...keyValues, ...settingsMkv },
        interscrollerWrappers: true,
        lazyload: true,
      };

      return adProps;
    },
    async initAfp() {
      this.adFormType = this.getAdFormType(this.adType);

      if (this.disableAd || !this.adRenderingState || this.hasBeenRendered) {
        return;
      }

      if (!this.adFormType) {
        console.log('[Portal Ads] No adType given');
        return;
      }

      const placements: Record<string, AdFormOptions> = this.$store.getters['adForm/getAdsPlacements'];
      // In case only settings prop is sent
      const adProps: AdFormProps = this.adProperties(placements || {});

      if (this.isAdBlocked && adProps.adblockFallback) {
        const hideBecauseMobile = adProps.adblockFallback.hideInMobile && this.isMobileOrTablet;
        const hideBecauseDesktop = adProps.adblockFallback.hideInDesktop && !this.isMobileOrTablet;

        if (!hideBecauseMobile && !hideBecauseDesktop) {
          this.adBlockFallback = adProps.adblockFallback;
        }
      } else {
        this.placeAd(adProps);
      }
      this.hasBeenRendered = true;
    },
    placeAd(adProps) {
      const isSiteheader = this.adFormType === 'siteheader' || this.adFormType === 'mobilesiteheader';
      const isInterstitial = this.adFormType === 'interstitial' || this.adFormType === 'mobileinterstitial';

      if (isSiteheader && window.delfi_afp.show_siteheader) {
        window.delfi_afp.show_siteheader(adProps);
      } else if (isInterstitial && window.delfi_afp.show_interstitial) {
        window.delfi_afp.show_interstitial(adProps);
      } else {
        window.delfi_afp.banners.push(adProps);
      }
    },
    getAdFormType(adType) {
      const fallBackAdFormType = mobileFallbackAds[adType] || mobileAds.find((ad) => ad === adType) || null;
      return this.isMobileOrTablet && !this.settings.mid ? fallBackAdFormType : (adType as AdFormPlacementType);
    },
  },
  async mounted() {
    if (!this.divId) {
      this.divId = `vue-adx-${++adCount}`;
      await this.$nextTick();
    }

    window.delfi_afp = window.delfi_afp || {};
    window.delfi_afp.banners = window.delfi_afp.banners || [];

    if (!window.delfi_afp.loaded) {
      loadScript(afpUrl);
    }

    await this.initAfp();
  },
});
