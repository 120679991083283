
import Vue from 'vue';
import { HeadlineWithRelatedArticles, Headline } from '@headlines/types/headline';
import ContentImage from '@core/components/UI/ContentImage.vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import getItemFromObject from 'lodash/get';
import { CustomCrop } from '@root/common/types/fragment';

interface Srcset {
  w?: number;
  h?: number;
  r?: string;
}

interface Props {
  href: HeadlineWithRelatedArticles['href'];
  headline: Headline;
  fallbackAltText: string;
  // Support Content API v2 style response
  pictures: Record<string, string>[];
  pictureSrcset: { xs: Srcset; sm: Srcset; md: Srcset; lg: Srcset; xl: Srcset } | null;
}

interface Methods {
  getItemFromObject: typeof getItemFromObject;
  getCropperData(): Record<string, unknown> | null;
  getCustomCrop(ratio: string): CustomCrop | undefined;
  getFocuspoint(): Record<string, unknown>;
}

const sizeList: ['xl', 'lg', 'md', 'sm', 'xs'] = ['xl', 'lg', 'md', 'sm', 'xs'];

export default Vue.extend<unknown, Methods, unknown, Props>({
  components: {
    LinkHandler,
    ContentImage,
  },
  props: {
    href: {
      type: String,
      required: false,
    },
    headline: {
      type: Object,
      required: false,
    },
    pictures: {
      type: Array,
      required: false,
    },
    pictureSrcset: {
      type: Object,
      required: false,
    },
    fallbackAltText: {
      type: String,
      required: false,
    },
  },
  methods: {
    getItemFromObject,
    getCustomCrop(ratio) {
      const customCrops = this.headline.metaImage?.attributes?.cropperData?.customCrops || [];
      return customCrops.find((crop) => crop.ratio === ratio);
    },
    getFocuspoint() {
      const cropperProps: Record<string, unknown> = {};
      const focuspoint = this.headline.metaImage?.attributes?.cropperData?.focuspoint;

      if (focuspoint?.x !== undefined && focuspoint?.x !== null) {
        cropperProps.fx = focuspoint.x;
      }

      if (focuspoint?.y !== undefined && focuspoint?.y !== null) {
        cropperProps.fy = focuspoint.y;
      }

      return cropperProps;
    },
    getCropperData() {
      if (!this.headline) {
        return null;
      }

      const cropperData: Record<string, unknown> = {};

      for (const size of sizeList) {
        if (this.pictureSrcset?.[size]) {
          const sizeSrcset = this.pictureSrcset[size];

          const customCrop = this.getCustomCrop(sizeSrcset.r || '16:9');

          if (customCrop) {
            cropperData[size] = { ...sizeSrcset, customCrop };
          } else {
            cropperData[size] = sizeSrcset;
          }
        }
      }

      return {
        ...cropperData,
        ...(this.headline.metaImage?.attributes?.cropperData || {}),
        base: this.getFocuspoint(),
      };
    },
  },
});
