import config from '@media/config/media.config';
import adScheduleConfig from '@media/config/videoPlayerAdSchedule.config';
import stringTemplate from '@ekd/js-stringtemplate';
import { AdScheduleSettings, AdSchedule } from '@media/types/PlayerAdSchedule';

type vast = 'stream' | 'default' | 'special' | 'audio';

// https://docs.jwplayer.com/players/reference/advertising-config-ref#advertisingschedule

export interface AdsData {
  adFree?: boolean;
  keywords?: string[];
  ppid?: string;
}

type VastIdObjectType = Record<string, number>;

type VastIdsType = Record<string, number | VastIdObjectType>;

const { minVideoDurationForMidRollAd, subscriberDisabledAds, adOffsets } = adScheduleConfig;

const getPlayerAdSchedule = (type: string, adsData?: AdsData, isSubscribed?: boolean, videoDurationSeconds?: number): AdScheduleSettings => {
  const { ads } = config;
  const vastIds = ads.ids[type as vast] || ads.ids.default;

  const schedule = buildAdSchedule(vastIds, isSubscribed, adsData, videoDurationSeconds);

  const adScheduleWithSettings = {
    client: 'vast',
    autoplayadsmuted: true,
    preloadAds: true,
    schedule,
  };

  return adScheduleWithSettings;
};

function buildAdSchedule(vastIds: VastIdsType, isSubscribed?: boolean, adsData?: AdsData, videoDurationSeconds?: number): AdSchedule[] {
  const schedule: AdSchedule[] = [];
  const time = +new Date();

  for (const [key, value] of Object.entries(vastIds)) {
    if (isSubscribed && subscriberDisabledAds.includes(key) && typeof value !== 'object') {
      continue;
    }

    let offset: string | number = key;
    // Enable mid-roll, if video length exceeds maxVideoDuration threshold
    const midRollAdEnabled: boolean = videoDurationSeconds ? videoDurationSeconds >= minVideoDurationForMidRollAd : false;

    if (key === 'mid') {
      if (midRollAdEnabled) {
        offset = adOffsets.mid;
      } else {
        continue;
      }
    } else if (key === 'overlay') {
      offset = adOffsets.overlay;
    }

    // Construct ad object
    const adObject: AdSchedule = { offset };
    const mkw = (adsData?.keywords || []).join(',') || '';
    const ppid = adsData?.ppid || '';

    const tagUrlBuilder = (vastId: number | string) => stringTemplate(config.ads.vastUrl, { vastId, time, mkw, ppid });

    // Pod is used for multiple consecutive ads
    // Tag is used for a singular ad
    if (typeof value === 'object') {
      const podArray = [];
      for (const subKey in value) {
        if (isSubscribed && subscriberDisabledAds.includes(subKey)) {
          continue;
        }

        podArray.push(tagUrlBuilder(value[subKey]));
      }
      adObject.pod = podArray;
    } else {
      adObject.tag = tagUrlBuilder(value);
    }

    schedule.push(adObject);
  }

  return schedule;
}

export { getPlayerAdSchedule };
