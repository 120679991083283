import { StorageObj, StorageItem, StorageShareIframe } from '@root/modules/localstorage/types/localStorage';

type StorageMode = 'shared' | 'local';

export default class LocalStorageService {
  private _iframeId = 'dlfi-storage';
  private _iframeOrigin = 'https://www.delfi.ee';
  private _iframePath = '/shared.html?q=v3';
  private _defaultStorageKey = '_dlfi_storage';
  public _storageMode!: StorageMode;
  public _storageKey!: string;
  public _iframe!: StorageShareIframe | null;
  private _timeout = 10000;

  private get _iframeSrc() {
    return `${this._iframeOrigin}${this._iframePath}`;
  }

  constructor(input?: { storageKey?: string; storageMode?: StorageMode }) {
    if (process.server) {
      throw new Error(`Localstorage can't be used in server`);
    }

    const { storageKey = this._defaultStorageKey, storageMode = 'shared' } = input || {};
    this._storageKey = storageKey;
    this._storageMode = storageMode;
  }

  async init() {
    if (process.server) {
      return;
    }

    const iframe = document.getElementById(this._iframeId) as StorageShareIframe | null;

    if (!this.isSharedMode() || iframe?.contentWindow) {
      if (iframe?.contentWindow) {
        this._iframe = iframe;
      }
      return;
    }

    try {
      const newIframe = document.createElement('iframe') as StorageShareIframe;

      if (process.client && process.env.FAKE_DOMAIN_ENABLED) {
        this._iframeOrigin = window.location.origin;
      }

      newIframe.id = this._iframeId;
      newIframe.style.display = 'none';
      newIframe.src = this._iframeSrc;

      let loadCallback: any;
      document.body.appendChild(newIframe);

      const iframeLoadPromise = new Promise<boolean>((resolve) => {
        loadCallback = () => {
          newIframe.removeEventListener('load', loadCallback);
          resolve(true);
        };

        newIframe.addEventListener('load', loadCallback);
      });

      const iframeLoadTimeout = new Promise<boolean>((_, reject) =>
        setTimeout(() => {
          newIframe.removeEventListener('load', loadCallback);
          return reject('iframeLoadTimeout');
        }, this._timeout)
      );

      const iframeCreated = await Promise.race([iframeLoadPromise, iframeLoadTimeout]);
      if (iframeCreated) {
        this._iframe = newIframe;
      }
    } catch (err) {
      this.onError(err);
    }
  }

  private isSharedMode() {
    return this._storageMode === 'shared';
  }

  private now(): string {
    return Date.now().toString();
  }

  private onError(err?: any) {
    console.log('LocalStorageService error:', err);
  }

  private createId(): string {
    return Math.random().toString(36).substring(2) + new Date().getTime().toString(36);
  }

  private getNewStorageObj(): StorageObj {
    return {
      updatedAt: this.now(),
      items: [],
    };
  }

  private async getItems(): Promise<StorageItem[]> {
    let storageData: string | null = null;
    let storedValues: StorageObj;

    try {
      if (process.server) {
        throw new Error(`Localstorage can't be used in server`);
      }

      if (this.isSharedMode() && this._iframe) {
        let dataListener: any;

        const storagePromise = new Promise<string>((resolve) => {
          const iframe = this._iframe;

          if (iframe && iframe.contentWindow) {
            dataListener = (event: MessageEvent) => {
              if (event.data.type === 'getResponse') {
                window.removeEventListener('message', dataListener, false);
                resolve(event.data.value);
              }
            };
            window.addEventListener('message', dataListener, false);

            iframe.contentWindow.postMessage(
              JSON.stringify({
                type: 'get',
                storageKey: this._storageKey,
              }),
              this._iframeOrigin
            );
          }
        });

        const storagePromiseTimeout = new Promise<string>((_, reject) =>
          setTimeout(() => {
            window.removeEventListener('message', dataListener, false);
            return reject('timeout');
          }, this._timeout)
        );

        storageData = await Promise.race([storagePromise, storagePromiseTimeout]);
      } else {
        storageData = localStorage.getItem(this._storageKey) as string;
      }
    } catch (err) {
      this.onError(err);
    }

    if (storageData) {
      try {
        storedValues = JSON.parse(storageData);
      } catch (err) {
        storedValues = this.getNewStorageObj();
      }
    } else {
      storedValues = this.getNewStorageObj();
    }

    const sortedStoredValues =
      storedValues?.items?.sort((a, b) => {
        return parseInt(a.updatedAt || '0') > parseInt(b.updatedAt || '0') ? -1 : 1;
      }) || [];

    return sortedStoredValues;
  }

  private save(storedValues: StorageObj) {
    try {
      if (this.isSharedMode() && this._iframe) {
        const iframe = this._iframe;

        iframe?.contentWindow?.postMessage(
          JSON.stringify({
            type: 'save',
            storageKey: this._storageKey,
            data: storedValues,
          }),
          this._iframeOrigin
        );
      } else {
        localStorage.setItem(this._storageKey, JSON.stringify(storedValues));
      }
    } catch (err) {
      this.onError(err);
    }
  }

  async get(data?: { key?: string; id?: string; limit?: number }) {
    const items = await this.getItems();
    const { key, id, limit } = data || {};

    if (key || id) {
      const filterKey = key ? 'key' : 'id';
      const filterValue = key || id;
      const filteredItems = items.filter((d) => {
        return d[filterKey] === filterValue;
      });

      if (limit && limit > 0) {
        return filteredItems.slice(0, limit);
      } else {
        return filteredItems;
      }
    }

    return items;
  }

  async batchCreate(data: { key: string; value: string }[]) {
    const items = await this.getItems();

    for (const item of data) {
      const newItem: StorageItem = {
        key: item.key,
        value: item.value,
        updatedAt: this.now(),
        id: this.createId(),
      };

      items.push(newItem);
    }

    this.save({
      updatedAt: this.now(),
      items,
    });
  }

  async batchUpdate(data: { id?: string; key?: string; value: string }[]) {
    const items = await this.getItems();

    for (const inputItem of data) {
      const { key, id } = inputItem || {};
      if (key || id) {
        const filterKey = key ? 'key' : 'id';
        const filterValue = key || id;
        items.forEach((item) => {
          if (item[filterKey] === filterValue) {
            item.value = inputItem.value;
            item.updatedAt = this.now();
          }
        });
      }
    }

    this.save({
      updatedAt: this.now(),
      items,
    });
  }

  async batchDelete(data: { id?: string; key?: string }[]) {
    const items = await this.getItems();

    const updatedItems = items.filter((item) => {
      for (const inputItem of data) {
        const { key, id } = inputItem || {};
        if (key || id) {
          const filterKey = key ? 'key' : 'id';
          const filterValue = key || id;
          if (item[filterKey] !== filterValue) {
            return true;
          }
        }
      }
      return false;
    });

    this.save({
      updatedAt: this.now(),
      items: updatedItems,
    });
  }

  clear() {
    this.save({
      updatedAt: this.now(),
      items: [],
    });
  }
}
