import jwplayer, { JWPlayerStatic } from '@media/types/JwPlayer';
import { AudioItem } from '../types/getAudio';
import { VideoItem } from '../types/getVideo';

import getRootConfig from '../utils/getRootConfig';
import { loadScript } from '../utils/loadScript';
import { EventHandler } from '../utils/EventHandler';
import config from '../config/media.config';
import initAnalytics from '../utils/analytics/analytics';

import et from '../locales/et';
import ru from '../locales/ru';
import NoParamEvent = jwplayer.NoParamEvent;
type Locales = 'et' | 'ru';

interface InternalSettings {
  shouldPlayerFloat: boolean;
}

interface JwWindow extends Window {
  jwplayer: JWPlayerStatic;
}
declare let window: JwWindow;

const locales = { et, ru };

class PortalPlayer extends EventHandler {
  public id!: string;
  public settings!: Record<string, unknown>;
  public mediaData!: AudioItem | VideoItem;
  public jwplayer!: jwplayer.JWPlayer;
  private internalSettings: InternalSettings;

  constructor(id: string, settings: Record<string, unknown>, mediaData: AudioItem | VideoItem, internalSettings?: Partial<InternalSettings>) {
    super();
    this.id = id;
    this.settings = this.playerSettings(settings);
    this.mediaData = mediaData;
    this.internalSettings = {
      shouldPlayerFloat: internalSettings?.shouldPlayerFloat || false,
    };

    const { jwplayer } = window;

    if (!(id && settings)) {
      new Error('settings or container Id are not defined');
      return;
    }

    if (!jwplayer) {
      loadScript({ script: config.jwPlayerCdnUrl, attr: { async: 'async ' } })
        .then(() => {
          this.initPlayer();
        })
        .catch((e) => console.error('player loadScript error', e));
    } else {
      this.initPlayer();
    }
  }

  public initPlayer() {
    const { jwplayer } = window;
    this.jwplayer = jwplayer(this.id);
    this.jwplayer.setup(this.settings);
    this.jwplayer.on('setupError', (message) => {
      this.emit('setup-error', message);
    });
    this.jwplayer.on('ready', () => {
      initAnalytics(this.jwplayer, this.mediaData);
      this.emit('player-ready', this.jwplayer);
    });
    this.jwplayer.on('fullscreen', async ({ fullscreen }) => {
      // Fullscreen is broken (shows black screen) if floating mode is enabled
      // So, forcefully disabling floating mode when fullscreen is toggled
      if (fullscreen) {
        this.decideFloatingModeToggle(!fullscreen);
      } else {
        // Fullscreen event fires before player is moved back to its original position
        // So, we need to skip tick to let player move back
        setTimeout(() => {
          this.decideFloatingModeToggle();
        }, 0);
      }
    });

    this.jwplayer.on('meta', () => {
      this.setVideoTitle();
    });

    this.jwplayer.on(<NoParamEvent>'providerFirstFrame', () => {
      this.setVideoTitle();
    });

    // @types package is missing event that fires
    // @ts-ignore
    this.jwplayer.on('playlistComplete', () => {
      this.setVideoTitle();
    });
  }

  private setVideoTitle() {
    document.querySelector('.jw-float-bar-title')!.innerHTML = 'Video';
  }

  private playerSettings(settings: Record<string, unknown>) {
    const { lang } = getRootConfig('settings');

    const intl = locales[lang as Locales] || {};
    const baseSettings: Record<string, any> = { ...settings };
    baseSettings.intl[lang] = intl;

    return { ...baseSettings, key: config.jwPlayerKey };
  }

  public decideFloatingModeToggle(forceStateTo?: boolean) {
    if (!this.internalSettings.shouldPlayerFloat) {
      return;
    }

    const state = forceStateTo ?? this.isPlayerScrolledOver();

    this.jwplayer.setFloating(state);
  }

  public isPlayerScrolledOver() {
    const playerContainer = this.jwplayer?.getContainer();
    if (!playerContainer) {
      return false;
    }

    const { top } = playerContainer.getBoundingClientRect();
    return top < -200;
  }

  public setLayout(type: string) {
    this.baseLayout();
    switch (type) {
      case 'AUDIO_LEAD':
        this.audioPlayerLeadLayout();
        break;
      case 'VIDEO':
      case 'STREAM':
        this.videoPlayerLayout();
        break;
    }
  }

  private baseLayout() {
    const playerContainer = this.jwplayer?.getContainer();
    const buttonContainer = playerContainer?.querySelector('.jw-button-container');

    // Remove the default rewind button added by JW Player
    const defaultRewindButton = buttonContainer?.querySelector('.jw-icon-rewind');
    if (defaultRewindButton) {
      defaultRewindButton.remove();
    }

    const playbackButton = buttonContainer?.querySelector('.jw-icon-playback');
    const settingsMenu = playerContainer?.querySelector('.jw-settings-menu');

    // Controlbar buttons
    // Play/Pause buttons
    const playIcon = playbackButton?.querySelector('.jw-svg-icon-play') as HTMLElement;
    const playIconNew = require(`!!html-loader!@media/assets/svg/play.svg`);
    playIcon.outerHTML = playIconNew;
    const pauseIcon = playbackButton?.querySelector('.jw-svg-icon-pause') as HTMLElement;
    const pauseIconNew = require(`!!html-loader!@media/assets/svg/pause.svg`);
    pauseIcon.outerHTML = pauseIconNew;

    // Create forward button
    const forwardButton = document.createElement('div');
    const fwIcon = require(`!!html-loader!@media/assets/svg/fw-15.svg`);
    forwardButton.setAttribute('class', 'jw-icon jw-icon-inline jw-button-color jw-reset jw-icon-forward');
    forwardButton.innerHTML = fwIcon;

    const seekOffset = 15;
    forwardButton.addEventListener('click', () => {
      const currentPlayerPosition = this.jwplayer.getPosition();
      const newPosition = currentPlayerPosition + seekOffset;
      this.jwplayer?.seek(newPosition);
    });
    playbackButton?.insertAdjacentElement('afterend', forwardButton);

    // Rewind button
    const rewindButton = document.createElement('div');
    const rwIcon = require(`!!html-loader!@media/assets/svg/rw-15.svg`);
    rewindButton.setAttribute('class', 'jw-icon jw-icon-inline jw-button-color jw-reset jw-icon-rewind');
    rewindButton.innerHTML = rwIcon;
    rewindButton.addEventListener('click', () => {
      const currentPlayerPosition = this.jwplayer.getPosition();
      const newPosition = currentPlayerPosition - seekOffset;

      // Stream position is always negative relative representation of the whole stream
      // seeking to 0 throws to the beginning, but beginning of the stream is not 0
      if (currentPlayerPosition < 0) {
        this.jwplayer?.seek(newPosition);
      } else {
        this.jwplayer?.seek(Math.max(newPosition, 0));
      }
    });
    playbackButton?.insertAdjacentElement('afterend', rewindButton);

    // Replace volume buttons
    const volume0Icon = buttonContainer?.querySelector('.jw-svg-icon-volume-0') as HTMLElement;
    const volume0IconNew = require(`!!html-loader!@media/assets/svg/volume-0.svg`);
    volume0Icon.outerHTML = volume0IconNew;
    const volume50Icon = buttonContainer?.querySelector('.jw-svg-icon-volume-50') as HTMLElement;
    if (volume50Icon) {
      const volume50IconNew = require(`!!html-loader!@media/assets/svg/volume-50.svg`);
      volume50Icon.outerHTML = volume50IconNew;
    }
    const volume100Icon = buttonContainer?.querySelector('.jw-svg-icon-volume-100') as HTMLElement;
    const volume100IconNew = require(`!!html-loader!@media/assets/svg/volume-100.svg`);
    volume100Icon.outerHTML = volume100IconNew;

    // Replace cc buttons
    const ccOnIcon = buttonContainer?.querySelector('.jw-svg-icon-cc-on') as HTMLElement;
    const ccOnIconNew = require(`!!html-loader!@media/assets/svg/cc-on.svg`);
    ccOnIcon.outerHTML = ccOnIconNew;
    const ccOffIcon = buttonContainer?.querySelector('.jw-svg-icon-cc-off') as HTMLElement;
    const ccOffIconNew = require(`!!html-loader!@media/assets/svg/cc-off.svg`);
    ccOffIcon.outerHTML = ccOffIconNew;

    // Replace fullscreen buttons
    const fullScreenOnIcon = buttonContainer?.querySelectorAll('.jw-svg-icon-fullscreen-on');
    const fullScreenOnIconNew = require(`!!html-loader!@media/assets/svg/fullscreen-on.svg`);
    fullScreenOnIcon?.forEach((icon) => {
      icon.outerHTML = fullScreenOnIconNew;
    });
    const fullScreenOffIcon = buttonContainer?.querySelectorAll('.jw-svg-icon-fullscreen-off');
    const fullScreenOffIconNew = require(`!!html-loader!@media/assets/svg/fullscreen-off.svg`);
    fullScreenOffIcon?.forEach((icon) => {
      icon.outerHTML = fullScreenOffIconNew;
    });
    // Replace sharing icons in setting/sharing block
    // @ts-ignore
    this.jwplayer.on('playAttempt', () => {
      const settingsIcon = buttonContainer?.querySelector('.jw-svg-icon-settings') as HTMLElement;
      const settingsIconNew = require(`!!html-loader!@media/assets/svg/settings.svg`);
      settingsIcon.outerHTML = settingsIconNew;
      // Sharing button
      const sharingIcon = buttonContainer?.querySelector('.jw-svg-icon-sharing');
      const sharingIconNew = require(`!!html-loader!@media/assets/svg/sharing.svg`);
      if (sharingIcon) {
        sharingIcon.outerHTML = sharingIconNew;
      }
      const sharingIconMenu = settingsMenu?.querySelector('.jw-svg-icon-sharing');
      if (sharingIconMenu) {
        sharingIconMenu.outerHTML = sharingIconNew;
      }

      // Subtitles
      const ccOnIcon = settingsMenu?.querySelector('.jw-svg-icon-cc-off') as HTMLElement;
      const ccOnIconNew = require(`!!html-loader!@media/assets/svg/cc-on.svg`);
      if (ccOnIcon) {
        ccOnIcon.outerHTML = ccOnIconNew;
      }
      // Social icons
      const facebookIcon = settingsMenu?.querySelectorAll('.jw-svg-icon-facebook');
      const facebookIconNew = require(`!!html-loader!@media/assets/svg/facebook.svg`);
      facebookIcon?.forEach((icon) => {
        icon.outerHTML = facebookIconNew;
      });
      const twitterIcon = settingsMenu?.querySelector('.jw-svg-icon-twitter');
      const twitterIconnNew = require(`!!html-loader!@media/assets/svg/twitter.svg`);
      if (twitterIcon) {
        twitterIcon.outerHTML = twitterIconnNew;
      }
      const linkIcon = settingsMenu?.querySelector('.jw-svg-icon-link');
      const linkIconnNew = require(`!!html-loader!@media/assets/svg/link.svg`);
      if (linkIcon) {
        linkIcon.outerHTML = linkIconnNew;
      }

      const speedIcon = settingsMenu?.querySelector('.jw-svg-icon-playback-rate');
      const speedIconnNew = require(`!!html-loader!@media/assets/svg/speed.svg`);
      if (speedIcon) {
        speedIcon.outerHTML = speedIconnNew;
      }
    });

    // Display control buttons
    const displayContainer = playerContainer?.querySelector('.jw-display-controls') as HTMLElement;
    const displayPlayIcon = displayContainer?.querySelector('.jw-svg-icon-play') as HTMLElement;
    const displayPauseIcon = displayContainer?.querySelector('.jw-svg-icon-pause') as HTMLElement;
    const playControlIconNew = require(`!!html-loader!@media/assets/svg/play-big.svg`);
    const pauseControlIconNew = require(`!!html-loader!@media/assets/svg/pause-big.svg`);
    displayPlayIcon.outerHTML = playControlIconNew;
    displayPauseIcon.outerHTML = pauseControlIconNew;
    const displayRewindButton = displayContainer?.querySelector('.jw-icon-rewind') as HTMLElement;
    displayRewindButton.innerHTML = rwIcon;

    const displayReplayIconNew = require(`!!html-loader!@media/assets/svg/replay.svg`);
    const displayReplayIcon = displayContainer?.querySelector('.jw-svg-icon-replay') as HTMLElement;
    displayReplayIcon.outerHTML = displayReplayIconNew;

    const displayForwardButton = document.createElement('div');
    const displayForwardButtonContainer = document.createElement('div');
    displayForwardButtonContainer.setAttribute('class', 'jw-icon jw-icon-forward jw-button-color jw-reset');
    displayForwardButtonContainer.innerHTML = fwIcon;
    displayForwardButton.appendChild(displayForwardButtonContainer);
    displayForwardButton.setAttribute('class', 'jw-display-icon-container jw-display-icon-forward jw-reset');
    displayForwardButton.addEventListener('click', () => {
      this.jwplayer?.seek(this.jwplayer.getPosition() + 15);
    });
    displayContainer?.appendChild(displayForwardButton);
  }

  private audioPlayerLeadLayout() {
    const playerContainer = this.jwplayer?.getContainer();

    // Replace play/pause button svg icons
    const playIconNew = require(`!!html-loader!@media/assets/svg/play-round.svg`);

    // Display control buttons
    const displayContainer = playerContainer?.querySelector('.jw-display-controls') as HTMLElement;
    const displayPlayIcon = displayContainer?.querySelector('.jw-svg-icon-play') as HTMLElement;
    displayPlayIcon.outerHTML = playIconNew;

    const displayIcon = displayContainer?.querySelector('.jw-icon-display') as HTMLElement;
    const resumeButton = document.createElement('div');
    resumeButton.style.color = 'white';
    const displayPlayResumeIcon = require(`!!html-loader!@media/assets/svg/play-resume.svg`);
    resumeButton.innerHTML = displayPlayResumeIcon;
    displayIcon.appendChild(resumeButton);
  }

  private videoPlayerLayout() {
    const playerContainer = this.jwplayer?.getContainer();
    const settingsMenu = playerContainer?.querySelector('.jw-settings-menu');

    // Display control buttons
    const displayContainer = playerContainer?.querySelector('.jw-display-controls');
    const displayIcon = displayContainer?.querySelector('.jw-icon-display') as HTMLElement;

    const resumeButton = document.createElement('div');
    resumeButton.style.color = 'white';
    const displayPlayResumeIcon = require(`!!html-loader!@media/assets/svg/play-resume.svg`);
    resumeButton.innerHTML = displayPlayResumeIcon;
    displayIcon.appendChild(resumeButton);

    // Change settings quality icon
    this.jwplayer.once('play', () => {
      const qualityIcon = settingsMenu?.querySelector('.jw-svg-icon-quality-100');
      const qualityIconnNew = require(`!!html-loader!@media/assets/svg/quality-100.svg`);
      if (qualityIcon) {
        qualityIcon.outerHTML = qualityIconnNew;
      }
    });
  }
}

export { PortalPlayer };
