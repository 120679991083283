
import Vue from 'vue';
import BaseIcon from '../BaseIcon.vue';
import BaseButton from '../BaseButton.vue';
import { AlertComponentProps, Button, EventDataWithLinkTitle, EventDataWithoutLinkTitle, EventEmitData } from './types';
import CustomIcon from '@core/components/CustomIcon.vue';

type Props = AlertComponentProps;

interface Data {
  isFadeActive: boolean;
  iconsMapping: Record<string, string>;
}

interface Methods {
  removeFade: () => void;
  triggerFadeAnimation: () => void;
  close: () => void;
  handleButtonClick: (button: Button) => void;
  getButtonHref: (button: Button) => Button['href'];
  fadeAnimationEnd: () => void;
  closeButtonHandler: () => void;
  trackEvent: (data: EventDataWithLinkTitle | EventDataWithoutLinkTitle) => void;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  components: {
    CustomIcon,
    BaseIcon,
    BaseButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
    },
    type: {
      type: String as () => Props['type'],
      required: false,
    },
    buttons: {
      type: Array,
      required: false,
    },
    fadeTrigger: {
      type: Number,
      required: false,
    },
    closeCallback: {
      type: Function,
      required: false,
    },
    customIcon: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isFadeActive: false,
      iconsMapping: {
        info: 'info-circle',
        warning: 'warning',
        error: 'exclamation',
        success: 'success',
      },
    };
  },
  mounted() {
    this.triggerFadeAnimation();
    this.trackEvent({
      eventType: 'view',
    });
  },
  methods: {
    removeFade() {
      this.isFadeActive = false;
    },
    trackEvent(data) {
      const emitData: EventEmitData = {
        title: String(this.$t(this.title)),
        ...data,
      };

      this.$emit('track-event', emitData);
    },
    triggerFadeAnimation() {
      if (this.fadeTrigger) {
        setTimeout(() => {
          this.isFadeActive = true;
        }, this.fadeTrigger);
      }
    },
    handleButtonClick(button) {
      if (button.action) {
        button.action();
      }

      this.trackEvent({
        eventType: 'click',
        linkTitle: String(this.$t(button.text)),
      });

      this.close();
    },
    getButtonHref(button) {
      return button.href && this.$te(button.href) ? String(this.$t(button.href)) : button.href;
    },
    fadeAnimationEnd() {
      this.trackEvent({
        eventType: 'hide',
      });

      this.close();
    },
    closeButtonHandler() {
      this.trackEvent({
        eventType: 'click',
        linkTitle: 'X',
      });

      this.close();
    },
    close() {
      if (this.closeCallback) {
        this.closeCallback();
      }

      this.$emit('close');
    },
  },
});
