import Cookie from 'cookie-universal';

import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { AdFormState } from '@ads/types/AdForm';

import { mergeAdsPlacements } from '@ads/utils/mergeAdsPlacements';
import { getDisabledAdsList } from '@ads/utils/getDisabledAdsList';
import adsConfig from '@ads/config/adForm.config';

export const state: () => AdFormState = () => ({
  allowAdsRendering: true,
  enableAdBlockLayer: false,
  ppid: '',
  disableAdsCondition: {
    adFree: false,
    subscriber: false,
  },
  default: {
    disableAds: [],
    placements: {},
    mkw: [],
    mkv: {},
  },
  page: {
    disableAds: [],
    enableAds: [],
    placements: {},
    mkw: [],
    mkv: {},
    uniqueKey: [],
  },
});

export const getters: GetterTree<AdFormState, Record<string, unknown>> = {
  // Return unique key that ads components can watch and use it for re-rendering their content
  getAdsUniqueKey: (state) => {
    return state.page.uniqueKey;
  },
  setAdsRenderingState: (state) => {
    return state.allowAdsRendering;
  },
  getAdBlockLayerState(state) {
    return state.enableAdBlockLayer;
  },
  // Return default ads keywords merged with page specific ads keywords
  getAdsKeywords: (state) => {
    return [...state.default.mkw, ...state.page.mkw];
  },
  // Return default ads key values merged with page specific ads key values
  getAdsKeyValues: (state) => {
    return {
      ...state.default.mkv,
      ...state.page.mkv,
    };
  },
  // Return page specific ads placements or faLLback to default ads placements
  getAdsPlacements: (state) => {
    const pagePlacements = mergeAdsPlacements(state.default.placements, state.page.placements);
    const pagePlacementsFound = !(Object.keys(pagePlacements).length === 0 && pagePlacements.constructor === Object);

    return pagePlacementsFound ? pagePlacements : state.default.placements;
  },
  // Return page specific disabled ads list or faLLback to default ads disabled ads list
  getDisabledAdsList: (state) => {
    const pageDisableAds = getDisabledAdsList(state.default.disableAds, state.page.disableAds, state.page.enableAds);

    if (state.disableAdsCondition.adFree) {
      return adsConfig.types;
    }

    if (state.disableAdsCondition.subscriber) {
      return pageDisableAds.length > 0 ? pageDisableAds : state.default.disableAds;
    }

    return [];
  },
  getPPID: (state) => state.ppid,
};

export const actions: ActionTree<AdFormState, Record<string, any>> = {
  init({ commit }) {
    const cookies = Cookie();
    const [ppid] = String(cookies.get('delfi-adid') || '').split(',');
    commit('setPPID', ppid);
  },
};

export const mutations: MutationTree<AdFormState> = {
  setDefaultAdsSettings(state, adsSettings: AdFormState['default']) {
    const adsData = adsSettings;
    const storedAdsData = state.default;

    storedAdsData.placements = adsData.placements;
    storedAdsData.disableAds = adsData.disableAds || adsConfig.disableAds;
    storedAdsData.mkw = adsData.mkw;
    storedAdsData.mkv = adsData.mkv;
  },
  setPageAdsSettings(state, adsSettings: AdFormState['page']) {
    const adsData = adsSettings;
    const storedAdsData = state.page;

    storedAdsData.placements = adsData.placements;
    storedAdsData.disableAds = adsData.disableAds;
    storedAdsData.enableAds = adsData.enableAds;
    storedAdsData.mkw = adsData.mkw;
    storedAdsData.mkv = adsData.mkv;
    storedAdsData.uniqueKey = adsData.uniqueKey;
  },
  resetPageAdsSettings(state) {
    const storedAdsData = state.page;
    storedAdsData.placements = {};
    storedAdsData.mkw = [];
    storedAdsData.mkv = {};
  },
  setDisabledAdsCondition(state, { type, disable }: { type: keyof AdFormState['disableAdsCondition']; disable: boolean }) {
    state.disableAdsCondition[type] = disable;
  },
  setAdBlockLayerState(state, enabled: boolean) {
    state.enableAdBlockLayer = enabled;
  },
  setAdsRenderingState(state, enabled: boolean) {
    state.allowAdsRendering = enabled;
  },
  setPPID(state, ppid: string) {
    state.ppid = ppid;
  },
};

export const adFormStoreModule = {
  adForm: {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
  },
};
