
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import ContentImage from '@core/components/UI/ContentImage.vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/11-20/blockType19';

const defaultSettings = {
  title: true,
  lead: true,
  picture: true,
  type: 'list',
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    BaseIcon,
    ContentImage,
    LinkHandler,
  },
});
