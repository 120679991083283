import jwplayer from '@media/types/JwPlayer';
import { AudioItem } from '../../types/getAudio';
import { VideoItem } from '../../types/getVideo';

import { cXenseTracker } from './cXense';
import { EdTracker } from './DelfiTEE';
import { GemiusTracker } from './Gemius';

export default (player: jwplayer.JWPlayer, mediaData: AudioItem | VideoItem) => {
  new cXenseTracker(player, mediaData);
  new EdTracker(player, mediaData);
  new GemiusTracker(player, mediaData);
};
