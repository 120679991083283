// TODO: Maybe should move to analytics/services/
import Cookie from 'cookie-universal';
import { getBaseDomain } from '@root/common/utils/url';
import { GVL, TCModel, TCString, Stack, VendorList } from '@iabtechlabtcf/core';
import { cmpData } from '@root/modules/analytics/config/cookie-consent/cookieConsent.config';
import { LegitimateInterest, CookieConsentGroup } from '@root/modules/analytics/types/analytics';

import Service from '@root/common/base/Service';

const setServerCookie = (data: unknown) => {
  const service = new Service();
  const apiProvider = service.createProvider('HTTP');
  try {
    apiProvider.request({
      url: '/api/set-server-cookie',
      method: 'post',
      data,
      timeout: 1000,
    });
  } catch (error) {
    process.sentry?.captureException(`setServerCookie ${data} failed with error: ${error.message}`, {
      tags: { 'process.type': process.server ? 'server' : 'client' },
    });
    return '';
  }
};

const getServerCookie = async (params: unknown) => {
  const service = new Service();
  const apiProvider = service.createProvider('HTTP');
  try {
    const response = await apiProvider.request<{ data: { cookie: any } }>({
      url: '/api/get-server-cookie',
      method: 'post',
      data: params,
      timeout: 1000,
    });

    return response.data?.cookie || '';
  } catch (error) {
    process.sentry?.captureException(`getServerCookie ${params} failed with error: ${error.message}`, {
      tags: { 'process.type': process.server ? 'server' : 'client' },
    });
    return '';
  }
};

const exdate = new Date();
exdate.setDate(exdate.getDate() + 365);

const cdata = {
  domain: getBaseDomain() || '',
  path: '/',
  expires: exdate,
};

const getConsentCookies = async (names: string[], ssrFetch = true) => {
  const cookies = Cookie();
  const cookiesInfo: Record<string, string> = {};
  let serverCookiesInfo: Record<string, string> = {};
  const serverCookies: string[] = [];

  names.forEach((cookie) => {
    const clientCookie = cookies.get(cookie);
    if (clientCookie || !ssrFetch) {
      cookiesInfo[cookie] = clientCookie;
    } else {
      serverCookies.push(`server_${cookie}`);
    }
  });

  if (serverCookies.length > 0) {
    serverCookiesInfo = await getServerCookie({ cookies: serverCookies });

    if (serverCookiesInfo) {
      Object.keys(serverCookiesInfo).forEach((key) => {
        if (key.indexOf('server_') === 0) {
          serverCookiesInfo[key.replace('server_', '')] = serverCookiesInfo[key];
          delete serverCookiesInfo[key];
        }
      });
      setConsentCookies(serverCookiesInfo, false);
    }
  }

  const cookiesData = { ...cookiesInfo, ...serverCookiesInfo };

  return cookiesData;
};

const setConsentCookies = (data: Record<string, string>, serverCookieSet = true) => {
  const cookies = Cookie();
  const cookiesToSet = { ...data };

  Object.keys(cookiesToSet).forEach((key) => {
    cookies.set(key, cookiesToSet[key], cdata);
    cookiesToSet[`server_${key}`] = cookiesToSet[key];
    delete cookiesToSet[key];
  });

  if (serverCookieSet) {
    setServerCookie({ cookies: cookiesToSet, settings: cdata });
  }
};

export const getGroupConsentData = (gvl: GVL, group: CookieConsentGroup) => {
  let vendorsIds: number[] = [];
  let groupPurposes: number[] = [];
  let specialFeatures: number[] = [];

  group.settings.forEach((gr) => {
    gr.value.forEach((val) => {
      const groupData = (gvl && gr.type && gvl[gr.type]?.[val]) as Stack;

      if (groupData.purposes || gr.type === 'purposes') {
        groupPurposes = [...groupPurposes, ...(groupData.purposes || gr.value.map(Number))];
        groupPurposes.forEach((id: number) => {
          const vendors = gvl.getVendorsWithConsentPurpose(id) as VendorList['vendors'];
          vendorsIds = [...vendorsIds, ...Object.keys(vendors).map(Number)];
        });
      }

      if (groupData.specialFeatures || gr.type === 'specialFeatures') {
        specialFeatures = [...specialFeatures, ...(groupData.specialFeatures || gr.value.map(Number))];
        specialFeatures.forEach((id: number) => {
          const vendors = gvl.getVendorsWithSpecialFeature(id) as VendorList['vendors'];
          vendorsIds = [...vendorsIds, ...Object.keys(vendors).map(Number)];
        });
      }
    });
  });

  return {
    vendorsIds,
    groupPurposes,
    specialFeatures,
  };
};

const getGroupsConsent = (gvl: GVL, groups: CookieConsentGroup[], addVendors?: boolean) => {
  let consentVendorsList: number[] = [];
  let purposes: number[] = [];
  let specialFeatures: number[] = [];

  groups.forEach((group) => {
    const groupData = getGroupConsentData(gvl, group);
    if (group.selected) {
      purposes = [...new Set([...purposes, ...groupData.groupPurposes])];
      specialFeatures = [...new Set([...specialFeatures, ...groupData.specialFeatures])];

      // Fill array af consent vendors ids if consentVendors is empty
      if (addVendors) {
        consentVendorsList = [...consentVendorsList, ...groupData.vendorsIds];
      }
    }
  });

  return {
    consentVendorsList,
    purposes,
    specialFeatures,
  };
};

const createConsentString = (gvl: GVL, groups: CookieConsentGroup[], legitimateInterest: LegitimateInterest, consentVendors: number[]) => {
  const tcModel = new TCModel(gvl);
  tcModel.cmpId = cmpData.id;
  tcModel.cmpVersion = cmpData.version;
  tcModel.consentScreen = cmpData.consentScreen;
  tcModel.isServiceSpecific = true;
  tcModel.vendorConsents.empty();
  tcModel.vendorLegitimateInterests.empty();
  tcModel.purposeLegitimateInterests.empty();

  const { purposes, specialFeatures, consentVendorsList } = getGroupsConsent(gvl, groups, consentVendors.length === 0);

  tcModel.specialFeatureOptins.set(specialFeatures);
  tcModel.purposeConsents.set(purposes);
  tcModel.vendorConsents.set(consentVendors.length > 0 ? consentVendors : consentVendorsList);

  // Manage legitimate interests purposes and vendors
  if (legitimateInterest.purposes && legitimateInterest.purposes.length > 0) {
    tcModel.purposeLegitimateInterests.set(legitimateInterest.purposes);
  }

  if (legitimateInterest.vendors && legitimateInterest.vendors.length > 0) {
    tcModel.vendorLegitimateInterests.set(legitimateInterest.vendors);
  }

  return TCString.encode(tcModel);
};

// Reference: https://support.google.com/admanager/answer/9681920?hl=en
// Subtopic: The "Additional Consent" (AC) string format
const createNonTcfConsentString = (vendors: number[]) => {
  return `1~${vendors.join('.')}`;
};

export { getConsentCookies, setConsentCookies, createNonTcfConsentString, getGroupsConsent, createConsentString };
