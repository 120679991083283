
import Vue from 'vue';
import VideoPlayer from '@media/components/video/VideoPlayer.vue';
import { VideoItem } from '@media/types/getVideo';

interface Data {
  renderPlayer: boolean;
}

type widgetType = 'video' | 'stream';
interface Props {
  content: {
    attrs: any;
  };
  type: widgetType;
}

interface Computed {
  getKeywords: string[];
  adFree: boolean;
  ads: {
    adFree: boolean;
    keywords: string[];
  };
  videoFragment: {
    externalSource?: boolean;
    attrs: VideoItem;
  };
}

let index = 0;

export default Vue.extend<Data, unknown, Computed, Props>({
  components: {
    VideoPlayer,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    type: {
      type: String as () => widgetType,
      required: false,
      default: 'video',
    },
  },
  data() {
    return {
      renderPlayer: false,
    };
  },
  computed: {
    getKeywords() {
      return this.$store.getters['adForm/getAdsKeywords'];
    },
    adFree() {
      return this.$store.state.piano.access.adFree || false;
    },
    ads() {
      return {
        adFree: this.adFree,
        keywords: this.getKeywords,
      };
    },
    videoFragment() {
      return {
        externalSource: true,
        attrs: {
          id: `custom-playlist-${index}`,
          type: 'VIDEO',
          metadata: {
            title: null,
            credit: null,
            authors: null,
            description: null,
            adsDisabled: null,
            videoPreviewImage: {
              src: '',
            },
          },
          videoData: {
            videoPlaylistLocation: this.content.attrs.code,
          },
        },
      };
    },
  },
  beforeMount() {
    index++;
    this.renderPlayer = true;
  },
});
