import BlockType20 from '@headlines/components/block/11-20/BlockType20.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType20: AdminBlockType = {
  component: BlockType20,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    category: true,
    showFbShares: false,
    articlesLinksHash: '',
  }),
  articlesCount: 0,
};

export default AdminBlockType20;
