import { Plugin } from '@nuxt/types';

import { getChannelId } from '@root/libs/init-channel/utils/getChannelId';
import { channelConfigService } from '@root/modules/channel/services/channelConfig.service';

const saveChannelConfigData: Plugin = async (ctx) => {
  // Reuse config data in CSR if it has been successfully fetched in SSR
  const channelConfigExists = Boolean(ctx.store.getters['getStorage']('channelConfig'));

  if (channelConfigExists) {
    return;
  }

  if (process.server) {
    const req: PortalRootIncomingMessage = ctx.req;
    const channelConfig = req.channelConfig;
    ctx.store.commit('setStorage', { channelConfig });

    return;
  }

  // Fetch config data for CSR if SSR did not fetch configuration successfully
  // This fallback is bugged and should be removed?
  if (process.client) {
    const channelId = getChannelId();
    const channelConfig = await channelConfigService.fetch(channelId);
    ctx.store.commit('setStorage', { channelConfig });
  }
};

export default saveChannelConfigData;
