import BlockType5Component from '@headlines/components/block/1-10/BlockType5.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType5: AdminBlockType = {
  component: BlockType5Component,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    author: false,
    titleComment: true,
    publishDate: false,
    articlesLinksHash: '',
  }),
  articlesCount: 1,
};

export default AdminBlockType5;
