
import BaseBlock from '@headlines/utils/BaseBlock';
import { HeadlineTitle, HeadlineImage, HeadlineAuthor, HeadlineLabels, HeadlineIcons, HeadlineLead, HeadlineRelatedTitle } from '../../fragments';
import ContentFragment from '@core/components/ContentFragment.vue';
import BlockTypeSettings from '@headlines/types/block/21-30/blockType29';
import getItemFromObject from 'lodash/get';

const defaultSettings = {
  icons: true,
  author: true,
  titleComment: true,
  channel: true,
  category: true,
  lead: true,
  relatedTitles: true,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<unknown, unknown, unknown, unknown>({
  components: {
    HeadlineTitle,
    HeadlineImage,
    HeadlineAuthor,
    HeadlineLabels,
    HeadlineIcons,
    ContentFragment,
    HeadlineLead,
    HeadlineRelatedTitle,
  },
  methods: {
    getItemFromObject,
  },
});
