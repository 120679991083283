
import Vue from 'vue';
import { NuxtError } from '@nuxt/types';
import { TranslateResult } from 'vue-i18n';
import { GetMostReadArticles, HeadlineSettings } from '@root/modules/headlines/types/headlines';
import MostReadArticlesService from '@root/modules/headlines/services/MostReadArticles.service';
import HeadlineList from '@root/common/components/base/headline/HeadlineList.vue';

interface Data {
  popularHeadlines: {
    items: GetMostReadArticles['data'] | [];
  };
  headlineSettings: HeadlineSettings;
  mounted: boolean;
}

interface CustomNuxtError extends NuxtError {
  debug?: Record<string, unknown>;
}

interface Props {
  error: CustomNuxtError;
}

interface Computed {
  errorMessage: TranslateResult;
  isFrontpage: boolean;
}

interface Methods {
  getTranslation: (message: string) => TranslateResult;
  fetchMostReadArticles: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    HeadlineList,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      popularHeadlines: {
        items: [],
      },
      headlineSettings: { publishDate: true, titleComment: false, author: true },
      mounted: false,
    };
  },
  computed: {
    errorMessage() {
      const msg = this.error?.message || '';
      // Try to handle all edge cases before showing message
      const showCustomMessage = !!msg && typeof msg === 'string' && msg !== '{}';
      return showCustomMessage ? this.getTranslation(msg) : this.$t('error.page_not_found');
    },
    isFrontpage() {
      return this.$route.name === 'frontpage';
    },
  },
  mounted() {
    const { error } = this;

    this.mounted = true;

    console.error(`Error: ${error?.statusCode}`, error);
    this.$sentry.captureException(error, {
      tags: { 'process.server': process.server ? 'server' : 'client', componentName: 'error.vue' },
      contexts: { service: { ...(error?.statusCode && { responseCode: error.statusCode }) } },
    });
    this.fetchMostReadArticles();
  },
  methods: {
    getTranslation(message) {
      return this.$te(message) ? this.$t(message) : this.$t('error.page_not_found');
    },
    async fetchMostReadArticles() {
      const limit = 6;
      const { domain, lang } = this.$channelConfig('settings');

      const mostReadArticlesService = new MostReadArticlesService({ req: this.$ssrContext?.req });
      const items = await mostReadArticlesService.fetch({ viewsSince: 'SINCE_24_HOURS', limit, domain, authorLanguage: lang.toUpperCase() });
      // TODO:(content-api-v3) Temporarily disable type check due to old and new api types conflicts
      // @ts-ignore
      this.popularHeadlines.items = items;
    },
  },
});
