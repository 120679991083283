import { MutationTree, GetterTree } from 'vuex';
import { State, Data } from '@root/modules/article/types/article';

export const state: () => State = () => ({
  articlesWithBodyContent: [],
  crArticles: [],
  activeArticleId: null,
  activeArticle: null,
  activeArticlePath: null,
  articleExtraContent: null,
});

export const getters: GetterTree<State, Record<string, unknown>> = {
  getBodyContentStatus: (state) => (articleId: string) => {
    return state.articlesWithBodyContent.find((id) => id === articleId);
  },
  getCrArticles: (state) => {
    return state.crArticles;
  },
  getActiveArticleId: (state) => state.activeArticleId,
  getActiveArticle: (state) => state.activeArticle,
  getActiveArticlePath: (state) => state.activeArticlePath,
  getArticleExtraContent: (state) => state.articleExtraContent,
};

export const mutations: MutationTree<State> = {
  setCrArticles(state, crArticles: string[] | []) {
    state.crArticles = crArticles;
  },
  setActiveArticleId(state, articleId: string) {
    state.activeArticleId = articleId;
  },
  setActiveArticlePath(state, articlePath: string) {
    if (state.activeArticlePath !== articlePath) {
      state.activeArticlePath = articlePath;
    }
  },
  setActiveArticle(state, article: Data) {
    state.activeArticle = article;
  },
  setArticleExtraContent(state, extraContent: State['articleExtraContent']) {
    state.articleExtraContent = extraContent;
  },
};
