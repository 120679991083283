
import { Fragment } from './Fragment';
import { loadScript } from '@core/utils/helpers';
import { CoreWindow } from '@core/types/window';
import srcConfig from '@core/config/src.config';

const fragment = new Fragment();
fragment.setRequiredAttrs(['id']);

const FragmentComponent = fragment.createComponent();

export default FragmentComponent.extend({
  name: 'FragmentRecipe',
  data() {
    return {
      uid: '',
    };
  },
  async mounted() {
    const { attrs } = this.content;

    if (!attrs.id) {
      return;
    }

    const win = window as unknown as CoreWindow;
    this.uid = `recipe-embed-${attrs.id}`;

    if (!win.recipeEmbed) {
      try {
        await loadScript({
          script: srcConfig.recipe_web,
          attr: { async: true },
          parentElement: this.$refs.recipeElement as HTMLElement,
        });
      } catch (e) {
        console.error('FragmentRecipe loadScript error', e);
      }
    }
    this.$nextTick(() => {
      win.recipeEmbed?.push({ divId: this.uid, options: { id: attrs.id, locale: 'et_EE' } });
    });
  },
});
