
import { HeadlineTitle, HeadlineImage, HeadlineAuthor, HeadlineLabels, HeadlineIcons, HeadlineRelatedTitle } from '../../fragments';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/1-10/blockType1';
import getItemFromObject from 'lodash/get';

const defaultSettings = {
  icons: true,
  author: true,
  titleComment: true,
  channel: true,
  category: true,
  relatedTitles: true,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlineAuthor,
    HeadlineLabels,
    HeadlineIcons,
    HeadlineRelatedTitle,
  },
  methods: {
    getItemFromObject,
  },
});
