import { Plugin } from '@nuxt/types';
import documentReferrer from '@root/common/utils/documentReferrer';
import cXensePlugin from './cXense';
import GemiusPlugin from './Gemius';
import FacebookPixel from './FacebookPixel';
import HotjarPlugin from './Hotjar';
import DelfiTEE from './DelfiTEE';
import BbcTracker from './BbcTracker';
import ArticleViewStatisticsPlugin from './ArticleViewStatistics';
import GetSiteControlPlugin from './GetSiteControl';
import cookieConsentPlugin from './cookieConsent';
import ClarityPlugin from './Clarity';
import GoogleTagManagerPlugin from './googleTagManager';

const analyticsTrackers: Plugin = (ctx, inject) => {
  ctx.app.router!.afterEach((to, from) => {
    if (from.name) {
      const host = ctx.app.$channelConfig('meta').base.baseUrl;
      documentReferrer(`${host}${from.fullPath}`);
    }
  });

  ctx.app.router?.onReady(async () => {
    if (ctx.app.router?.currentRoute.query.preview) {
      return false;
    }

    await cookieConsentPlugin(ctx, inject);
    cXensePlugin(ctx, inject);
    GemiusPlugin(ctx, inject);
    FacebookPixel(ctx, inject);
    HotjarPlugin(ctx, inject);
    DelfiTEE(ctx, inject);
    BbcTracker(ctx, inject);
    ArticleViewStatisticsPlugin(ctx, inject);
    GetSiteControlPlugin(ctx, inject);
    ClarityPlugin(ctx, inject);
    GoogleTagManagerPlugin(ctx, inject);
  });
};

export default analyticsTrackers;
