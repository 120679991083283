
import Vue from 'vue';

import { PianoCustomerProfile, ExtendedProfile } from '@piano/types/pianoState';
interface Methods {
  openLoginModal: () => void;
  openSignupModal: () => void;
  logout: () => void;
}

interface Computed {
  isLoggedIn: boolean;
  getProfile: PianoCustomerProfile | null;
  getExtendedProfile: ExtendedProfile | null;
}

export default Vue.extend<unknown, Methods, Computed, unknown>({
  computed: {
    isLoggedIn() {
      return this.$store.state.piano.isLoggedIn;
    },
    getProfile() {
      return this.$store.state.piano.profile;
    },
    getExtendedProfile() {
      return this.$store.state.piano.extendedProfile;
    },
  },
  methods: {
    openLoginModal() {
      this.$store.dispatch('piano/showLoginModal', 'login');
    },
    openSignupModal() {
      this.$store.dispatch('piano/showLoginModal', 'register');
    },
    logout() {
      this.$store.dispatch('piano/logout');
    },
  },
});
