import { Plugin } from '@nuxt/types';
import LocalStorageService from '@root/modules/localstorage/services/LocalStorage.service';
import { StorageItem } from '@root/modules/localstorage/types/localStorage';
import { diffFromDate } from '@root/common/filters/dateFormater';
import CustomerDataService from '@root/modules/customer/services/CustomerData.service';
import { userAgentsMaxCount, checkAfterEveryNSeconds, localStorageKeyPrefix } from '@root/modules/piano/config/sessionsLimit.config';

interface CountData {
  count: number;
  date?: string;
}
const sessionsLimit: Plugin = async ({ store, app }) => {
  const { whitelistUserIds } = app.$channelConfig('features').sessionsLimit;

  const localStorageService = new LocalStorageService();
  await localStorageService.init();

  const getLocalStorageKey = (userId: string): string => `${localStorageKeyPrefix}${userId}`;

  const shouldCountUserAgents = (date?: string): boolean => {
    if (!date) {
      return true;
    }

    return diffFromDate({ to: date, unit: 'seconds' }) > checkAfterEveryNSeconds;
  };

  const getLocalStorageItem = async (key: string): Promise<StorageItem | undefined> => {
    const localStorageResponse = await localStorageService.get({ key, limit: 1 });
    return localStorageResponse.find((data) => data && data.key === key);
  };

  const isProfileIdWhitelisted = (id: string): boolean => {
    for (const whitelistId of whitelistUserIds) {
      if (String(whitelistId) === id) {
        return true;
      }
    }

    return false;
  };

  store.watch(
    (state) => state.piano.access,
    async (access) => {
      if (store.state.piano.ipAccessToken || !access.channelAccess || isProfileIdWhitelisted(store.state.piano.profile.uid)) {
        return;
      }

      const key = getLocalStorageKey(store.state.piano.profile.uid);
      const storageItem = await getLocalStorageItem(key);
      let countData: CountData = { count: 0 };

      if (storageItem) {
        try {
          countData = JSON.parse(storageItem.value);
        } catch (err) {
          console.warn('storageItem.value parsing failed', err);
        }
      }

      if (shouldCountUserAgents(countData.date)) {
        const customerDataService = new CustomerDataService();
        customerDataService.setCustomerToken(store.state.piano.token);

        try {
          const response = await customerDataService.countUserAgents();
          countData = { date: new Date().toString(), count: response?.countUserAgents.count || 0 };
        } catch (err) {
          console.warn('Failed to count user agents', err);
        }

        if (!storageItem) {
          await localStorageService.batchCreate([{ key, value: JSON.stringify(countData) }]);
        } else {
          await localStorageService.batchUpdate([
            {
              id: storageItem.id,
              value: JSON.stringify(countData),
            },
          ]);
        }
      }

      if (countData.count > userAgentsMaxCount) {
        await store.dispatch('piano/toggleModal', { name: 'sessionsLimitAlert', show: true });

        store.commit('analytics/setClickEvent', {
          cXenseFunnel: {
            eventName: 'SESSION_LIMIT',
            eventData: {
              type: 'show_alert',
              data: { userAgents: countData.count },
            },
          },
        });
      }
    }
  );

  store.subscribeAction(async (action) => {
    if (action.type === 'piano/dispatchEvent' && action.payload === 'resetUserCompleted') {
      const key = getLocalStorageKey(store.state.piano.profile.uid);
      await localStorageService.batchDelete([{ key }]);

      await store.dispatch('piano/logout');
      await store.dispatch('piano/toggleModal', { name: 'sessionsLimitAlert', show: false });
    }
  });
};

export default sessionsLimit;
