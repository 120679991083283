import jwplayer from '@media/types/JwPlayer';
import { AudioItem } from '../../types/getAudio';
import { VideoItem } from '../../types/getVideo';

import { DelfiTEE } from '@analytics/trackers';
import { loadScript } from '../loadScript';

import PlayerAnalytics from './BaseAnalytics';

interface AnalyticsWindow extends Window {
  [index: string]: any;
}

declare let window: AnalyticsWindow;

class EdTracker extends PlayerAnalytics {
  private _edTracker!: DelfiTEE;

  constructor(player: jwplayer.JWPlayer, mediaData: AudioItem | VideoItem) {
    super(player, mediaData);
  }

  async initAnalytics() {
    if (!window.EDTracker) {
      loadScript({ script: 'https://s.delfi.ee/t.js?instanced', attr: { async: 'async' } })
        .then(async () => {
          this._edTracker = new DelfiTEE();
          await this._edTracker.init();
          this.sendEvent('media_ready');
        })
        .catch((e) => console.error('DelfiTEE loadScript error', e));
    } else {
      this._edTracker = new DelfiTEE();
      await this._edTracker.init();
      this.sendEvent('media_ready');
    }
    this.setEventsListeners();
  }

  public sendEvent(eventName: string) {
    const parameters = this.eventParameters({ event_name: eventName });
    this._edTracker?.signal('event', parameters);
  }
}

export { EdTracker };
