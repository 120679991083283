export const loadScripts = (script: string, attr?: Record<string, boolean>) => {
  return new Promise((resolve, reject) => {
    const scriptEl = document.createElement('script');
    scriptEl.src = script;
    scriptEl.type = 'text/javascript';
    if (attr) {
      Object.keys(attr).forEach((val) => {
        (scriptEl as any)[val] = attr[val];
      });
    }

    // Attach script to head
    document.getElementsByTagName('head')[0].appendChild(scriptEl);

    let scriptLoaded: any = null;
    let scriptErrored: any = null;

    scriptErrored = () => {
      scriptEl.removeEventListener('load', scriptLoaded);
      scriptEl.removeEventListener('error', scriptErrored);
      reject(`Script failed to load ${scriptEl.src}`);
    };

    scriptLoaded = () => {
      scriptEl.removeEventListener('load', scriptLoaded);
      scriptEl.removeEventListener('error', scriptErrored);
      resolve(true);
    };

    // Resolve the promise once the script is loaded
    scriptEl.addEventListener('load', scriptLoaded);

    // Catch any errors while loading the script
    scriptEl.addEventListener('error', scriptErrored);
  });
};
