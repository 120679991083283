import { Context } from '@nuxt/types';
import { getDevChannelIdCookie, setDevChannelIdCookie, getDevChannelId, getLiveChannelId } from './getChannelId';

function initCurrentChannelId({ req, res, isDev }: { req: Context['req']; res: Context['res']; isDev: boolean }) {
  let channelId: string | null = null;

  if (isDev) {
    const devCookieValue = getDevChannelIdCookie(req);
    channelId = getDevChannelId(req);

    if (!devCookieValue || channelId !== devCookieValue) {
      setDevChannelIdCookie({ res, channelId });
    }
  } else {
    channelId = getLiveChannelId(req);
  }
  return channelId;
}

export default initCurrentChannelId;
