
import Vue from 'vue';
interface Props {
  vendor: Record<string, unknown>;
  showDetails: boolean;
  type: string;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  props: {
    vendor: {
      type: Object,
      required: true,
    },
    showDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      default: 'tcf',
    },
  },
});
