import Vue from 'vue';
import { Plugin } from '@nuxt/types';
import { AdaptedGetPlayerArticlesData, Article } from '@root/modules/player/types/PlayerArticles';
import { PlayerData } from '@root/modules/player/types/PlayerData';
import * as playerConfig from '@root/modules/player/config/player.config';
import { loadScripts } from '@root/common/utils/scriptLoader';
import PlayerArticles from '@root/modules/player/services/PlayerArticles.service';
import buildPictureUrl from '@core/utils/buildPictureUrl';
import { getPlayerSettings } from '@root/modules/player/utils/getPlayerSettings';
import { getHumanReadableTime } from '@root/modules/player/utils/getHumanReadableTime';
import { gridConfig } from '@core/config/grid.config';
import CustomerDataService from '@root/modules/customer/services/CustomerData.service';
import { GetCustomerData } from '@root/modules/customer/types/customer';

interface State {
  isFullscreenPlayerOpened: boolean;
  articles: AdaptedGetPlayerArticlesData;
  savedArticles: {
    items: GetCustomerData['data']['customerData']['items'];
  };
  currentPlaylist: Article[];
  currentlyPlayingArticle: Article | null;
  currentlyPlayingArticleIndex: number;
  currentPlayerData: PlayerData | null;
}

interface TtsPlayerPlugin {
  state: State;
  seek(newPosition: number): void;
  togglePlaying(forceStateTo?: boolean): void;
  setPlaybackRate(newPlaybackRate: number): void;
  setVolume(newVolume: number): void;
  toggleFullscreenPlayer(forceStateTo?: boolean): void;
  loadArticlesByIds(articleIds: number[]): Promise<void>;
  setupPlaylistByArticleId(articleId: number): void;
  seekBackward(): void;
  seekForward(): void;
  remove(): void;
  nextPlaylistItem(): void;
  toggleSavedArticleInTasku(articleId: number): Promise<void>;
}

declare module 'vue/types/vue' {
  interface Vue {
    $ttsPlayer: TtsPlayerPlugin;
  }
}

interface JWPLayerWindow extends Window {
  jwplayer: typeof jwplayer;
}

declare const window: JWPLayerWindow;

const ttsPlayer: Plugin = async ({ app, store }, inject) => {
  let jwPlayer: jwplayer.JWPlayer | null = null;
  const customerDataService = new CustomerDataService();

  const state: State = {
    isFullscreenPlayerOpened: false,
    articles: {
      items: [],
    },
    savedArticles: {
      items: [],
    },
    currentPlaylist: [],
    currentlyPlayingArticle: null,
    currentlyPlayingArticleIndex: 0,
    currentPlayerData: null,
  };

  const initJwplayerObject = () => {
    jwPlayer = window.jwplayer(playerConfig.jwPlayerContainerId);
  };

  const getSavedArticles = async () => {
    if (!store.state.piano.token || !state.articles.items.length) {
      state.savedArticles.items = [];
      return;
    }

    const articleIds = state.articles.items.map((article) => String(article.id));
    let data: GetCustomerData['data'] | undefined;

    try {
      data = await customerDataService.get({ key: 'savedArticlesIds', value: articleIds, offset: 0, limit: articleIds.length });
    } catch (e) {
      console.log('Failed to fetch ttsPlayer articles', e);
    }

    state.savedArticles.items = data?.customerData.items || [];
  };

  const toggleSavedArticleInTasku = async (articleId: number) => {
    if (!store.state.piano.token) {
      store.dispatch('piano/showLoginModal', 'login');
      return;
    }

    const savedArticleEntryId = state.savedArticles.items.find((article) => article.value === String(articleId))?.id;

    if (savedArticleEntryId) {
      try {
        await customerDataService.delete({ id: savedArticleEntryId });
        state.savedArticles.items = state.savedArticles.items.filter((article) => article.id !== savedArticleEntryId!);

        app.$alertHandler.addAlert({
          type: 'success',
          title: 'words.removed_from_tasku_library',
          fadeTrigger: 7000,
          options: {
            key: 'listen-button-removed-from-tasku',
          },
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const response = await customerDataService.create({ key: 'savedArticlesIds', value: String(articleId) });

        if (response?.createCustomerData.customerData) {
          state.savedArticles.items.push(response.createCustomerData.customerData);
        }

        app.$alertHandler.addAlert({
          type: 'success',
          title: 'words.added_to_tasku_library',
          fadeTrigger: 7000,
          options: {
            key: 'listen-button-added-to-tasku',
          },
          buttons: [
            {
              text: 'words.watch_my_library',
              href: 'https://tasku.delfi.ee/library/articles',
              size: 'small',
            },
          ],
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const seek = (newPosition: number) => {
    jwPlayer?.seek(newPosition);
  };

  const togglePlaying = (forceStateTo?: boolean) => {
    const newState = forceStateTo ?? !(state.currentPlayerData?.isPlaying ?? true);

    if (newState) {
      jwPlayer?.play();
    } else {
      jwPlayer?.pause();
    }

    if (state.currentPlayerData) {
      state.currentPlayerData.isPlaying = newState;
    }
  };

  const setPlaybackRate = (newPlaybackRate: number) => {
    jwPlayer?.setPlaybackRate(newPlaybackRate);

    if (state.currentPlayerData) {
      state.currentPlayerData.playbackRate = newPlaybackRate;
    }
  };
  const setVolume = (newVolume: number) => {
    jwPlayer?.setVolume(newVolume);

    if (state.currentPlayerData) {
      state.currentPlayerData.audioVolume = newVolume;
    }
  };

  const toggleFullscreenPlayer = (forceStateTo?: boolean) => {
    const screenWidth = window.screen.width;
    const isMobileView = screenWidth <= gridConfig.breakpoints.lg;

    if (!isMobileView) {
      return;
    }

    state.isFullscreenPlayerOpened = forceStateTo ?? !state.isFullscreenPlayerOpened;
    document.body.style.overflow = state.isFullscreenPlayerOpened ? 'hidden' : '';
  };

  const loadArticlesByIds = async (articleIds: number[]) => {
    state.articles = { items: [] };

    if (articleIds.length) {
      const playerArticles = new PlayerArticles();
      const articles = await playerArticles.fetch({ id: articleIds });
      state.articles = {
        ...articles,
        items: articles.items.sort((a, b) => articleIds.indexOf(a.id) - articleIds.indexOf(b.id)),
      };

      await getSavedArticles();
    }
  };

  const setupPlaylistByArticleId = async (articleId: number) => {
    if (!jwPlayer || !state.articles.items.length) {
      return;
    }

    const articleIndex = state.articles.items.findIndex((article) => article.id === articleId);

    if (articleIndex < 0) {
      return;
    }

    const playlistArticles = state.articles.items.slice(articleIndex);
    state.currentPlaylist = playlistArticles;

    const jwPlayerPlaylist = playlistArticles.map((article) => {
      const textToVoiceAttribute = article.voiceSettings.textToVoice.voiceUrl;

      return {
        sources: [],
        file: textToVoiceAttribute,
        image: buildPictureUrl({ id: article.metaImage?.id || '' }),
        title: article.content.title.text || '',
        mediaid: String(article.id),
      };
    });

    return new Promise<void>((resolve) => {
      jwPlayer!.setup(getPlayerSettings({ jwPlayerKey: playerConfig.jwPlayerKey, playlist: jwPlayerPlaylist }));
      jwPlayer!.on('ready', async () => {
        jwPlayer!.on('playlistItem', async (event) => {
          const index = event.index;
          state.currentlyPlayingArticleIndex = index;
          state.currentlyPlayingArticle = playlistArticles[index];
        });

        togglePlaying(true);
        jwPlayer!.on('time', (event) => {
          const audioDuration = Math.floor(event.duration);
          const audioPosition = Math.floor(event.position);

          state.currentPlayerData = {
            audioDuration,
            humanReadableDuration: getHumanReadableTime(audioDuration),
            audioPosition,
            humanReadablePosition: getHumanReadableTime(audioPosition),
            audioListeningPercentage: (audioPosition / audioDuration) * 100,
            audioVolume: state.currentPlayerData?.audioVolume ?? jwPlayer!.getVolume(),
            playbackRate: state.currentPlayerData?.playbackRate ?? jwPlayer!.getPlaybackRate(),
            isPlaying: state.currentPlayerData?.isPlaying ?? jwPlayer!.getState() === 'playing',
          };
        });

        resolve();
      });
    });
  };

  const seekBackward = () => {
    const currentPosition = state.currentPlayerData?.audioPosition || 0;
    const seekedPosition = currentPosition - playerConfig.instantSeekInterval;
    const newPosition = Math.max(seekedPosition, 0);
    seek(newPosition);
  };

  const seekForward = () => {
    const currentPosition = state.currentPlayerData?.audioPosition || 0;
    const newPosition = currentPosition + playerConfig.instantSeekInterval;
    seek(newPosition);
  };

  const remove = () => {
    jwPlayer?.remove();
    state.currentPlaylist = [];
    state.currentlyPlayingArticle = null;
    state.currentlyPlayingArticleIndex = 0;
    state.currentPlayerData = null;
    initJwplayerObject();
  };

  const nextPlaylistItem = () => {
    // @types/jwplayer is missing next() method, but is described in https://docs.jwplayer.com/players/reference/next
    // @ts-ignore
    jwPlayer?.next();
  };

  const injectableObject: TtsPlayerPlugin = {
    state: Vue.observable(state),
    seek,
    togglePlaying,
    setPlaybackRate,
    setVolume,
    toggleFullscreenPlayer,
    loadArticlesByIds,
    setupPlaylistByArticleId,
    seekBackward,
    seekForward,
    remove,
    nextPlaylistItem,
    toggleSavedArticleInTasku,
  };

  inject('ttsPlayer', injectableObject);

  if (!window.jwplayer) {
    await loadScripts(playerConfig.jwPlayerUrl, { async: true });
  }

  const jwPlayerContainerEl = document.createElement('div');
  jwPlayerContainerEl.id = playerConfig.jwPlayerContainerId;
  jwPlayerContainerEl.style.height = '0 !important';
  document.body.appendChild(jwPlayerContainerEl);

  initJwplayerObject();

  store.watch(
    (state) => state.piano.token,
    async (token) => {
      customerDataService.setCustomerToken(token);
      await getSavedArticles();
    },
    { immediate: true }
  );
};

export default ttsPlayer;
