import { AdminBlockAttributeInputType, AdminBlockTypeAttribute } from '@headlines/types/block/adminBlockType';

const articlesLinksHash: AdminBlockTypeAttribute = {
  label: 'Article links hash tag',
  key: 'articlesLinksHash',
  inputType: AdminBlockAttributeInputType.STRING_INPUT,
  defaultValue: '',
};

export default articlesLinksHash;
