
import Vue from 'vue';
import { Config } from '~/src/modules/channel/types/channel';

interface Computed {
  socialLinks: Config['socialLinks'];
}

export default Vue.extend<unknown, unknown, Computed, unknown>({
  computed: {
    socialLinks() {
      return this.$channelConfig('socialLinks');
    },
  },
});
