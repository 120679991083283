
import Vue from 'vue';
import { mapState } from 'vuex';
import Header from '@root/common/components/layout/header/Header.vue';
import HeaderBottom from '@root/common/components/layout/header/HeaderBottom.vue';
import Footer from '@root/common/components/layout/footer/Footer.vue';
import { Header as IHeader, Footer as IFooter, Mobile as IMobile } from '@root/modules/navigation/types/navigation';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import AdFormInterstitial from '@ads/components/adForm/AdFormInterstitial.vue';
import AdFormSiteheader from '@ads/components/adForm/AdFormSiteheader.vue';
import CookieConsent from '@root/common/components/base/cookie-consent/CookieConsent.vue';
import { WithContext, WebSite } from 'schema-dts';
import MobileHeader from '@root/common/components/layout/header/MobileHeader.vue';
import { AlertComponentProps } from '@core/components/UI/alert/types';
import { PaywallState } from '@root/common/types/PaywallModal';

const FullscreenPlayer = () => import('@root/common/components/base/player/FullscreenPlayer.vue');
const FloatingPlayer = () => import('@root/common/components/base/player/floating-player/FloatingPlayer.vue');
const AdBlockLayer = () => import('@root/common/components/layout/AdBlockLayer.vue');
const PaywallModal = () => import('@root/common/components/layout/paywall-modal/PaywallModal.vue');

const PianoModalComponents = () => import('@piano/components/PianoModalComponents.vue');

interface AfpWindow extends Window {
  DelfiAFPcounter: Record<string, unknown>;
}

declare let window: AfpWindow;

interface Computed {
  contentCenterClass: Record<string, boolean>;
  hideHeader: boolean;
  headerNavigation: IHeader;
  footerNavigation: IFooter;
  mobileNavigation: IMobile[];
  showAdBlockLayer: boolean;
  hideOrdering: boolean;
  alerts: AlertComponentProps[];
  keywords: any[];
  keyValues: Record<string, unknown>;
  adFormKey: string;
  isFullscreenPlayerOpened: boolean;
  paywallData: PaywallState;
}

interface Data {
  isAdBlockEnabled: boolean;
}

interface Methods {
  resetAdFormKey: () => void;
}

export default Vue.extend<Data, Methods, Computed, unknown>({
  components: {
    PaywallModal,
    AdFormSiteheader,
    AdFormBanner,
    Header,
    HeaderBottom,
    Footer,
    AdBlockLayer,
    AdFormInterstitial,
    CookieConsent,
    MobileHeader,
    FloatingPlayer,
    FullscreenPlayer,
    PianoModalComponents,
  },
  data() {
    return {
      isAdBlockEnabled: false,
    };
  },
  jsonld() {
    const { base } = this.$channelConfig('meta');
    const pageJsonld: WithContext<WebSite> = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: base.baseUrl,
      author: {
        '@type': 'Person',
        name: base.title,
      },
      publisher: {
        '@type': 'Organization',
        name: 'Delfi',
      },
      description: base.description,
    };
    return pageJsonld;
  },
  head() {
    const { lang } = this.$channelConfig('settings');
    const bodyClass = this.hideHeader ? 'no-header' : '';
    return {
      htmlAttrs: {
        lang,
      },
      bodyAttrs: {
        class: bodyClass,
      },
    };
  },
  computed: {
    contentCenterClass() {
      const disabledAdsList: string[] = this.$store.getters['adForm/getDisabledAdsList'];
      return { 'content-center': disabledAdsList.includes('tower') };
    },
    hideHeader() {
      const { specialFeatures } = this.$channelConfig('settings').application;
      return specialFeatures.disableHeader;
    },
    ...mapState({
      headerNavigation: (state: any) => state.navigation.header.data as IHeader,
      footerNavigation: (state: any) => state.navigation.footer.data as IFooter,
      mobileNavigation: (state: any) => state.navigation.mobile.data as IMobile[],
    }),
    showAdBlockLayer() {
      const { adBlock } = this.$channelConfig('features');
      const currentPath = this.$nuxt.context.route.fullPath;
      const whitelistedUrls = adBlock?.whitelistedUrls || [];
      const onWhiteListedURL = whitelistedUrls.some((whiteListedUrl) => currentPath.includes(whiteListedUrl));

      if (onWhiteListedURL) {
        return false;
      }
      return this.$store.state.adForm.enableAdBlockLayer;
    },
    hideOrdering() {
      const { specialFeatures } = this.$channelConfig('settings').application;
      return specialFeatures.hideSignupAndOrders.enabled;
    },
    alerts() {
      return this.$store.state.index.alerts || [];
    },
    keywords() {
      return this.$store.getters['adForm/getAdsKeywords'];
    },
    keyValues() {
      return this.$store.getters['adForm/getAdsKeyValues'];
    },
    adFormKey() {
      return `${this.keywords}-${JSON.stringify(this.keyValues)}`;
    },
    isFullscreenPlayerOpened() {
      return this.$ttsPlayer?.state.isFullscreenPlayerOpened || false;
    },
    paywallData() {
      return this.$store.getters['paywall'];
    },
  },
  watch: {
    $route() {
      if (!this.$isServer) {
        this.resetAdFormKey();
      }
    },
  },
  methods: {
    // Reset adForm counter on keywords change
    resetAdFormKey() {
      if (window && window.DelfiAFPcounter) {
        window.DelfiAFPcounter = {};
      }
    },
  },
});
