import { AdminBlockAttributeInputType, AdminBlockTypeAttribute } from '@headlines/types/block/adminBlockType';

const includeCategories: AdminBlockTypeAttribute = {
  label: 'Include categories',
  key: 'includeCategories',
  inputType: AdminBlockAttributeInputType.SELECT,
  defaultValue: [],
  typeOptions: [],
};

export default includeCategories;
