import { MutationTree, GetterTree } from 'vuex';
import type { Feed } from '@root/modules/feed/types';

export interface State {
  refetch: boolean;
  tempData: Feed | null;
}

export const state: () => State = () => ({
  refetch: false,
  tempData: null,
});

export const getters: GetterTree<State, Record<string, unknown>> = {
  getTempData: (state) => state.tempData,
};

export const mutations: MutationTree<State> = {
  setRefetchState(state, value: boolean) {
    state.refetch = value;
  },
  setTempData(state, value: Feed | null) {
    state.tempData = value;
  },
  resetTempData(state) {
    state.tempData = null;
  },
};
