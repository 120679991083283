import { Analytics } from '@analytics/types/Analytics';
import Awindow from '@analytics/types/Awindow';
import logger from '@analytics/util/logger';

import loadMarkerIoScript from './utils/loadMarkerIoScript';
import { MarkerConfig } from './types/MarkerIo';

declare let window: Awindow;
export default class MarkerIo implements Analytics {
  public async init(options: Pick<MarkerConfig, 'project'>) {
    if (!options.project) {
      logger.error('MarkerIo project parameter not provided');
      return;
    }

    window.markerConfig = {
      ...options,
      source: 'snippet',
    };

    await this.loadScript();
  }

  private async loadScript() {
    if (!window.__Marker) {
      await loadMarkerIoScript();
    }
  }
}
