import BlockType23Component from '@headlines/components/block/21-30/block-type-23/BlockType23.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType23: AdminBlockType = {
  component: BlockType23Component,
  attributes: AdminBlockTypeAttributes({
    channelDomain: { id: '077cde60-2055-11ec-9724-0242c0a88502', name: 'www.delfi.ee' },
    streamsLimit: 5,
    showAllStreams: false,
    articlesLinksHash: '',
    broadcastsPageUrls: {
      et: 'https://tv.delfi.ee/leht/ulekanded-kava',
      ru: 'https://rus.delfi.ee/stranica/live-programma',
    },
    isRibbon: false,
  }),
  articlesCount: 0,
};

export default AdminBlockType23;
