
import Vue from 'vue';
import BaseButton from '@core/components/UI/BaseButton.vue';

import { HeaderActions } from '@root/modules/navigation/types/navigation';

interface Methods {
  toggleAction: (type: HeaderActions['type'], value: HeaderActions['value']) => void;
}

interface Computed {
  isIssueListOpen: boolean;
}

export default Vue.extend<unknown, Methods, Computed, unknown>({
  components: {
    BaseButton,
  },
  computed: {
    isIssueListOpen() {
      return this.$store.getters['navigation/getHeaderAction']('issueList');
    },
  },
  methods: {
    toggleAction(type, value) {
      this.$store.commit('navigation/setHeaderAction', { type, value });
    },
  },
});
