import { AdFormPlacementType, AdFormProps } from '@ads/types/AdForm';

export default {
  siteheader: {
    async: true,
    portalRootDesktop: true,
    dom: 'delfi-adx-siteheader',
  },
  mobilesiteheader: {
    async: true,
    portalRootMobile: true,
    dom: 'delfi-adx-siteheader',
  },
  content: {
    widthAtLeast: 768,
  },
  leaderboard: {
    widthAtLeast: 768,
  },
  mobile: {
    widthAtMost: 767,
  },
  floating: {
    widthAtLeast: 768,
  },
  scroller: {
    widthAtLeast: 768,
  },
  interstitial: {
    async: true,
    portalRootDesktop: true,
    dom: 'delfi-adx-afp-interstitial',
  },
  mobileinterstitial: {
    async: true,
    portalRootMobile: true,
    dom: 'delfi-adx-afp-interstitial',
  },
  floor: {
    widthAtMost: 767,
  },
  galleryscroller: {},
} as Record<AdFormPlacementType, Partial<AdFormProps>>;
