
import BaseBlock from '@headlines/utils/BaseBlock';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import { HeadlineTitle, HeadlineImage, HeadlineIcons } from '../../fragments';
import BlockTypeSettings from '@headlines/types/block/21-30/blockType24';

const defaultSettings = {
  titleComment: true,
};

interface Data {
  contentTypeFilter: string[];
}

interface Methods {
  getCustomRelatedEntities(contentType: HeadlineWithRelatedArticles['relatedEntities']): HeadlineWithRelatedArticles['relatedEntities'];
  getCustomSettings(settings: HeadlineWithRelatedArticles['settings']): HeadlineWithRelatedArticles['settings'];
}

interface Computed {
  getSideBlockContent: HeadlineWithRelatedArticles[];
}

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<Data, Methods, Computed, unknown>({
  components: {
    HeadlineTitle,
    HeadlineImage,
    HeadlineIcons,
    BaseIcon,
  },
  data() {
    return {
      contentTypeFilter: ['contentMarketing', 'hasStream'],
    };
  },
  methods: {
    getCustomRelatedEntities(relatedEntities) {
      if (!relatedEntities) {
        return relatedEntities;
      }

      const filteredRelatedEntities: Record<string, unknown> = {
        hasVideo: false,
        hasAudio: false,
        hasStream: false,
        hasGallery: false,
      };

      for (const [key, value] of Object.entries(relatedEntities)) {
        if (this.contentTypeFilter.includes(key)) {
          filteredRelatedEntities[key] = value;
        }
      }

      return filteredRelatedEntities as HeadlineWithRelatedArticles['relatedEntities'];
    },
    getCustomSettings(settings) {
      if (settings.length < 1) {
        return settings;
      }

      for (const setting of settings) {
        if (!this.contentTypeFilter.includes(setting.key)) {
          setting.value = false;
        }
      }

      return settings;
    },
  },
  computed: {
    getSideBlockContent() {
      const sideBlockHeadlines = this.data.items.slice(1, 6);
      return sideBlockHeadlines.map((headline) => {
        return { ...headline, relatedEntities: this.getCustomRelatedEntities(headline.relatedEntities), settings: this.getCustomSettings(headline.settings) };
      });
    },
  },
});
