enum Locales {
  default = 'et_EE',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  et = 'et_EE',
  ru = 'ru_EE',
}

function getLocaleByLang(lang: string) {
  return Locales[lang.toLowerCase() as keyof typeof Locales] || Locales.default;
}

export default getLocaleByLang;
