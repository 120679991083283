
import { Fragment } from './Fragment';
import { loadScript } from '@core/utils/helpers';
import srcConfig from '@core/config/src.config';

import { CoreWindow } from '@core/types/window';

const fragment = new Fragment();
fragment.setRequiredAttrs(['code']);

const FragmentComponent = fragment.createComponent();

export default FragmentComponent.extend({
  name: 'FragmentQuiz',
  data() {
    return {
      pollId: null as number | null,
      uid: '',
    };
  },
  async mounted() {
    const { code } = this;
    // FIXME:
    const win = window as unknown as CoreWindow;

    if (!code) {
      return;
    }

    if (!win.renderPoll) {
      try {
        await loadScript({ script: srcConfig['quiz'], attr: { async: true }, parentElement: this.$refs.quizElement as HTMLElement });
      } catch (e) {
        console.error('FragmentQuiz loadScript error', e);
      }
    }

    const id = this.extractId({ code });

    if (!id) {
      this.logger('alert', { message: 'Invalid id', debug: this.content });
      return;
    }

    const uid = `quiz-poll-${id}`;

    if (id && uid) {
      this.pollId = id;
      this.uid = uid;

      this.$nextTick(() => {
        this.renderPoll({ id, uid });
      });
    }
  },
  methods: {
    extractId({ code }: { code: string }) {
      const regex = /quiz\.php#id=(\d+)/gm;
      const match = regex.exec(code);
      const id = match && match.length > 0 ? Number(match[1]) : null;

      return id;
    },
    renderPoll({ id, uid }: { id: number; uid: string }) {
      // FIXME:
      const win = window as unknown as CoreWindow;
      const renderPoll = () => {
        win.renderPoll(uid, id);
        win.removeEventListener('vuepoll_init_render', renderPoll);
      };

      if (!win.renderPollInited) {
        win.addEventListener('vuepoll_init_render', renderPoll);
      } else {
        renderPoll();
      }
    },
  },
});
