import { getOfferConfiguration } from '@piano/utils/getOfferConfiguration';
import { PianoConfig } from '@piano/types/pianoState';
import { PianoWindow } from '@piano/types/pianoTp';

declare const window: PianoWindow;

export const getOfferFromWindowOrConfig = (
  offerType: string,
  locale: PianoConfig['locale'],
  offersConfiguration: PianoConfig['offersConfiguration'],
  hasRecentlyEndedSubscription?: boolean
) => {
  const keyByLocale = `${offerType}_${locale}`;

  const composerOffer = window.composerOffersConfig?.[keyByLocale] || window.composerOffersConfig?.[offerType];

  if (composerOffer) {
    return composerOffer;
  }

  return getOfferConfiguration({
    hasRecentlyEndedSubscription,
    offersConfiguration: offersConfiguration[keyByLocale] || offersConfiguration[offerType],
  });
};
