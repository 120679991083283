
import Vue from 'vue';
import ThumbWrapper from '@gallery/components/thumb/ThumbWrapper.vue';
import { GetGalleries, ImageItem } from '@gallery/types/Gallery';
import BigPictureWrapper from '@gallery/components/BigPictureWrapper.vue';
import Lightbox from '@gallery/components/lightbox/Lightbox.vue';
import { getPictureCredit } from '@gallery/utils/getPictureCredit';
import cloneDeep from 'lodash/cloneDeep';

interface Props {
  gallery: GetGalleries['data']['gallery']['items'][0];
}

interface Methods {
  setActivePictureIndex: (index: number) => void;
  toggleLightbox: (firstPhotoIndex?: number) => void;
  fetchMoreImages: () => void;
}

interface Data {
  activePictureIndex: number;
  showLightbox: boolean;
  initialIndex: number;
  activePictureCredits: string;
}

interface Computed {
  activePicture: ImageItem;
  galleryWithPreviewImage: GetGalleries['data']['gallery']['items'][0];
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ThumbWrapper,
    BigPictureWrapper,
    Lightbox,
  },
  data() {
    return {
      activePictureIndex: 0,
      showLightbox: false,
      initialIndex: 0,
      activePictureCredits: '',
    };
  },
  props: {
    gallery: {
      type: Object,
      required: true,
    },
  },
  computed: {
    activePicture() {
      return this.gallery.images.items[this.activePictureIndex];
    },
    galleryWithPreviewImage() {
      const gallery = cloneDeep(this.gallery);

      if (!gallery.metadata.galleryPreviewImage) {
        return gallery;
      }

      const previewImageIndexInImages = gallery.images.items.findIndex((item) => item.id === gallery.metadata.galleryPreviewImage!.id);

      if (previewImageIndexInImages >= 0) {
        gallery.images.items.splice(previewImageIndexInImages, 1);
      }

      gallery.images.items.unshift(gallery.metadata.galleryPreviewImage);

      return gallery;
    },
  },
  watch: {
    activePicture: {
      handler(newVal) {
        this.activePictureCredits = getPictureCredit(newVal?.metadata);
      },
      immediate: true,
    },
  },
  methods: {
    toggleLightbox(firstPhotoIndex) {
      if (this.showLightbox) {
        this.showLightbox = false;
        this.initialIndex = 0;
        document.body.style.overflow = '';
        history.replaceState(null, '', ' ');
      } else {
        this.initialIndex = firstPhotoIndex || 0;
        this.showLightbox = true;
        document.body.style.overflow = 'hidden';
      }
    },
    setActivePictureIndex(index) {
      if (index < this.gallery.images.items.length && index >= 0) {
        this.activePictureIndex = index;

        if (index > this.gallery.images.items.length - 5) {
          this.fetchMoreImages();
        }
      }
    },
    fetchMoreImages() {
      this.$emit('fetch-more-images');
    },
  },
  mounted() {
    const hash = window.location.hash;

    if (!hash) {
      return;
    }

    const hashParams = new URLSearchParams(hash.substring(1));
    const galleryId = hashParams.get('gallery-id');
    const imageId = hashParams.get('image-id');

    if (galleryId === this.gallery.id) {
      const pictureIndex = this.gallery.images.items.findIndex((picture) => picture.id === imageId);

      if (pictureIndex >= 0) {
        this.toggleLightbox(pictureIndex);
      }
    }
  },
});
