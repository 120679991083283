import { gridConfig } from '@core/config/grid.config';

interface UrlParams {
  adId: string;
  adsAttributes: AdsAttributes;
}

interface AdsAttributes {
  mkw: string[];
  mkv: Record<string, string>;
}

export default (urlParams: UrlParams) => {
  const displayWidth = window.screen.width;
  const isDesktop = displayWidth > gridConfig.breakpoints.lg;

  const mids = {
    mobile: 567873,
    desktop: 567805,
  };

  const mkv = Object.keys(urlParams.adsAttributes.mkv)
    .map((key) => {
      return `${encodeURIComponent(key)}:${encodeURIComponent(urlParams.adsAttributes.mkv[key])}`;
    })
    .join(',');

  const adFormUrl = new URL('https://adx.adform.net/adx/');
  adFormUrl.searchParams.append('mid', String(isDesktop ? mids.desktop : mids.mobile));
  adFormUrl.searchParams.append('mkw', urlParams.adsAttributes.mkw.join(','));
  adFormUrl.searchParams.append('mkv', mkv);
  adFormUrl.searchParams.append('adid', urlParams.adId || '');
  adFormUrl.searchParams.append('callback', 'show');

  return adFormUrl;
};
