import BlockType10Component from '@headlines/components/block/1-10/BlockType10.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType10: AdminBlockType = {
  component: BlockType10Component,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    author: false,
    titleComment: true,
    channel: false,
    category: false,
    initialTab: 1,
    excludeContentMarketing: false,
    excludeIssueOnly: false,
    excludeSpecialProject: false,
    includeChannels: [],
    includeCategories: [],
    excludeCategories: [],
    articlesLinksHash: '',
  }),
  articlesCount: 1,
};

export default AdminBlockType10;
