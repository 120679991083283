const gridConfig = {
  grid_size: 12,
  breakpoints: {
    xl: 1199,
    lg: 991,
    md: 767,
    sm: 575,
  },
};

export { gridConfig };
