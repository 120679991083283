import Service from '@root/common/base/BaseService';
import { ServiceInterface } from '@root/common/types/service';

import { GetCategoriesWithHeadlines } from '@root/modules/category/types/categories';
import getCategoriesWithHeadlines from '@root/modules/category/graphql/getCategoriesWithHeadlines.graphql';

type ServiceVariables = GetCategoriesWithHeadlines['variables'];

export default class CategoriesWithHeadlinesService extends Service implements ServiceInterface {
  private async fetchCategoriesWithHeadlinesService(variables: ServiceVariables) {
    const apiProvider = this.createProvider('GraphQL');
    apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });

    const options = Object.assign({ query: getCategoriesWithHeadlines }, { variables });
    const response = await apiProvider.query<GetCategoriesWithHeadlines['data']>(options);

    return response.data;
  }

  public async fetch(variables: ServiceVariables) {
    const response = await this.fetchCategoriesWithHeadlinesService(variables);

    return response;
  }
}
