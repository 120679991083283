import dayjs from 'dayjs';
import 'dayjs/locale/et';
import utc from 'dayjs/plugin/utc';

dayjs.locale('et');
dayjs.extend(utc);

const todayStart = dayjs().startOf('day').utc().format();
const todayEnd = dayjs().endOf('day').utc().format();
const week = dayjs().startOf('week').utc().format();
const month = dayjs().startOf('month').utc().format();
const year = dayjs().startOf('year').utc().format();
const tenYears = dayjs().subtract(10, 'year').utc().format();

export type DateRange = { from: string; to: string };

const dateRangeToday: DateRange = { from: todayStart, to: todayEnd };
const dateRangeOngoingWeek: DateRange = { from: week, to: todayEnd };
const dateRangeOngoingMonth: DateRange = { from: month, to: todayEnd };
const dateRangeOngoingYear: DateRange = { from: year, to: todayEnd };
const dateRangeLastTenYears: DateRange = { from: tenYears, to: todayEnd };

export { dateRangeToday, dateRangeOngoingWeek, dateRangeOngoingMonth, dateRangeOngoingYear, dateRangeLastTenYears };
