import BlockType14Component from '@headlines/components/block/11-20/block-type-14/BlockType14.vue';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';

const AdminBlockType14: AdminBlockType = {
  component: BlockType14Component,
  attributes: AdminBlockTypeAttributes({
    type: 'list',
    icons: true,
    author: true,
    titleComment: true,
    channel: true,
    category: true,
    lead: false,
    pictureShadowColor: '',
    backgroundColor: '',
    iframe: false,
    embedName: '',
    articlesLinksHash: '',
  }),
  articlesCount: 2,
};

export default AdminBlockType14;
