export default {
  jwPlayerCdnUrl: 'https://g.delfi.ee/libs/jwplayer/8.36.2/jwplayer.js',
  jwPlayerKey: 'qH503S0xm1eV+AJWmxR/zHC+hJ7TQsy+Cg61KnMrfh+oa+Ag',
  api: {
    mediaGraphqlApiUrl: 'https://media.api.delfi.ee/media/v1/graphql',
    mediaApiUrl: 'https://images.delfi.ee/media-api-image-cropper/v1',
  },
  ads: {
    vastUrl: 'https://adx.adform.net/adx/?mid=%{vastId}&t=2&adid=%{ppid}&mkw=%{mkw}&rnd=%{time}',
    ids: {
      default: {
        pre: {
          no_skip: 568520,
          skip: 1753838,
        },
        post: 568638,
        overlay: 660120,
        mid: 1753845,
      },
      special: {
        pre: 568522,
        post: 568639,
        overlay: 668147,
      },
      stream: {
        pre: 568521,
        post: 568641,
        overlay: 668148,
      },
      audio: {
        pre: 1494845,
      },
    },
  },
  appUa: /(DelfiEE|DelfiLT|DelfiLV)([a-zA-Z]+)\/(\d+)/gi,
  analytics: {
    ga: {
      uid: 'UA-45281033-10',
      trackerName: 'portalMedia',
    },
    cXense: {
      siteId: '1145189970857384309',
      origin: 'eks-mediaplayer',
      persistedQueryId: '40d3cf897a4694c10c2a3c7d26cdb348146ea94c',
    },
    gemius: {
      uid: 'coWaeEbbIvjL5kJir7kjD3amnH7Zx1fKjrpKBO7BC0..Q7',
      appUid: 'coU6Fsd6y4M7awTwAaGWnMPbDmy_6xrAPvpev9FUXO7.q7',
      trackerUrl: 'https://see.hit.gemius.pl/gplayer.js',
      playerId: 'jwplayer8',
    },
    eventsMap: {
      // jwPlayer event name: tracker event name
      play: 'media_play',
      pause: 'media_pause',
      idle: 'media_stop',
      complete: 'media_end',
      seek: 'media_seek',
      fullscreen: 'media_fullscreen',
      // ad events
      adBlock: 'media_adBlock',
      adImpression: 'media_adImpression',
      adRequest: 'media_adRequest',
      adComplete: 'media_adComplete',
      adSkipped: 'media_adSkipped',
    },
    trackProgressEvents: {
      media_25: 1,
      media_50: 1,
      media_75: 1,
    },
  },
  castAppId: '08AAFF5A',
  player: {
    playbackRates: [0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 2.0],
    logoUrl: 'https://g.delfi.ee/tv/l/dtv_video_2021.png',
  },
};
