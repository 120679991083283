import BlockType8Component from '@headlines/components/block/1-10/BlockType8.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType8: AdminBlockType = {
  component: BlockType8Component,
  attributes: AdminBlockTypeAttributes({
    text: true,
    logo: false,
    secondaryLogo: false,
    links: false,
    colorBlock: '',
    articlesLinksHash: '',
  }),
  articlesCount: 0,
};

export default AdminBlockType8;
