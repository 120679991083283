
import Vue from 'vue';
import Alert from '@core/components/UI/alert/Alert.vue';
import { AlertComponentProps, EventEmitData } from '@core/components/UI/alert/types';

interface EventData {
  type: string;
  data: {
    title: string;
    linkTitle?: string;
  };
}

interface ProcessedAlert extends AlertComponentProps {
  isActive: boolean;
  id: number;
}

interface Data {
  processedAlerts: ProcessedAlert[];
}

interface Methods {
  closeAlert(id: number): void;
  trackEvent(data: EventEmitData): void;
}

interface Computed {
  activeAlerts: ProcessedAlert[];
}

interface Props {
  alerts: AlertComponentProps[];
}

// It is necessary to create proper keys for template loop
// proper keys are important for transition in alert component
let alertUniqueId = 0;
export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Alert,
  },
  props: {
    alerts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      processedAlerts: [],
    };
  },
  computed: {
    activeAlerts() {
      return this.processedAlerts.filter((alert) => alert.isActive);
    },
  },
  watch: {
    alerts: {
      handler(alertList: AlertComponentProps[]) {
        const processedAlerts = alertList.map((alert) => ({ ...alert, isActive: true, id: ++alertUniqueId }));

        if (Array.isArray(processedAlerts) && processedAlerts.length > 0) {
          const newItemsInArray = Math.max(alertList.length - this.processedAlerts.length, 1);
          const arrayToPush = processedAlerts.slice(-newItemsInArray);
          this.processedAlerts.push(...arrayToPush);
        }
      },
      immediate: true,
    },
  },
  methods: {
    closeAlert(id) {
      this.processedAlerts = this.processedAlerts.map((alert) => {
        if (alert.id === id) {
          alert.isActive = false;
        }
        return alert;
      });
    },
    trackEvent(event) {
      const eventData: EventData = {
        type: event.eventType,
        data: {
          title: event.title,
        },
      };

      if ('linkTitle' in event && event.linkTitle) {
        eventData.data.linkTitle = event.linkTitle;
      }

      this.$store.commit('analytics/setClickEvent', {
        cXense: {
          eventName: 'ALERT',
          eventData,
        },
      });
    },
  },
});
