
import Vue from 'vue';
import { AudioItem } from '@media/types/getAudio';

interface Data {
  activeEpisode: string;
}

interface Methods {
  handleTrackChange: (episodeData: AudioItem) => void;
}

interface Props {
  activeEpisodeId: string;
  playlist: AudioItem[];
  podcastId: string | null;
}

interface Computed {
  locale: string;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    playlist: {
      type: Array,
      required: true,
    },
    activeEpisodeId: {
      type: String,
      required: false,
    },
    podcastId: {
      type: String,
      required: false,
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    handleTrackChange(episodeData) {
      this.$emit('handle-track-change', episodeData.id);
    },
  },
});
