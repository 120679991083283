
import Vue from 'vue';
import { StreamsPerDay } from './types/blockType23';
import { formatDateForDay, getTime, isDateToday, isLive } from '@headlines/utils/BlockType23';
import { BaseButton, BaseIcon } from '@core/exports';
import _ from 'lodash';

interface Props {
  locale: string;
  streamsByDay: StreamsPerDay[];
  streamsPageUrl: string;
  streamsPerDayCount?: number[];
  streamsLimit: number;
}

interface Methods {
  formatDateForDay: typeof formatDateForDay;
  getTime: typeof getTime;
  isDateToday: typeof isDateToday;
  isLive: typeof isLive;
  scroll: (direction: string) => void;
}

interface Computed {
  adaptedStreamsByDay: StreamsPerDay[];
  countOfStreamsLeft: number;
  moreStreamsCountIndex: number;
}

interface Data {
  hideFade: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    locale: {
      type: String,
      required: true,
    },
    streamsByDay: {
      type: Array,
      required: true,
    },
    streamsPageUrl: {
      type: String,
      required: true,
    },
    streamsPerDayCount: {
      type: Array,
      required: true,
      default: null,
    },
    streamsLimit: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      hideFade: false,
    };
  },
  computed: {
    adaptedStreamsByDay() {
      const streams: StreamsPerDay[] = _.cloneDeep(this.streamsByDay);
      let u = 0;
      streams.forEach((day) => {
        if (u > this.streamsLimit - 1) {
          day.hideMobile = true;
        }
        day.streams.forEach((stream, index) => {
          if (u > this.streamsLimit - 1) {
            if (day.streams[index - 1]) {
              day.streams[index - 1].noBorder = true;
            }
            stream.hideMobile = true;
          }
          u++;
        });
      });
      return streams;
    },
    countOfStreamsLeft() {
      return this.adaptedStreamsByDay.reduce((count, day) => {
        return count + day.streams.filter((stream) => stream.hideMobile).length;
      }, 0);
    },
    moreStreamsCountIndex() {
      let dayIndexToShowMoreStreamCount = -1;

      if (this.adaptedStreamsByDay[0].streams.some((stream) => stream.hideMobile)) {
        return 0;
      } else if (this.adaptedStreamsByDay[1].streams.every((stream) => stream.hideMobile)) {
        return 0;
      }

      for (let index = 0; index < this.adaptedStreamsByDay.length; index++) {
        if (this.adaptedStreamsByDay[index + 1] && this.adaptedStreamsByDay[index + 1].streams.every((stream) => stream.hideMobile)) {
          dayIndexToShowMoreStreamCount = index;
          break;
        }

        if (this.adaptedStreamsByDay[index].streams.some((stream) => stream.hideMobile)) {
          dayIndexToShowMoreStreamCount = index;
          break;
        }
      }

      return dayIndexToShowMoreStreamCount;
    },
  },
  methods: {
    formatDateForDay,
    getTime,
    isDateToday,
    isLive,
    scroll(direction: string): void {
      const container = this.$refs.scrollingContainer as HTMLElement;

      if (!container) {
        return;
      }

      const scrollAmount = 225;
      const maxScrollLeft = container.scrollWidth - container.clientWidth; // Max scrollable width

      const newScrollLeft = direction === 'left' ? container.scrollLeft - scrollAmount : container.scrollLeft + scrollAmount;
      const remainingScroll = maxScrollLeft - container.scrollLeft;

      if (direction === 'right' && remainingScroll < 225) {
        this.hideFade = true;
      } else if (direction === 'left') {
        this.hideFade = false;
      }

      const scrollOptions: ScrollToOptions = {
        left: newScrollLeft,
        behavior: 'smooth',
      };
      container.scrollTo(scrollOptions);
    },
  },
});
