import { MutationTree, GetterTree, ActionTree } from 'vuex';
import { State } from '~/src/modules/customer/types/BookmarkStore';
import CustomerDataService from '@root/modules/customer/services/CustomerData.service';
export const state: () => State = () => ({
  bookmarks: [],
  requestedArticleIds: [],
});
export const getters: GetterTree<State, Record<string, unknown>> = {
  getBookmarks: (state) => {
    return state.bookmarks;
  },
};
export const mutations: MutationTree<State> = {
  setBookmarks(state, articleData) {
    state.bookmarks = [...new Set([...state.bookmarks, ...articleData.items])];
  },
  setRequestedArticleId(state, articleId) {
    state.requestedArticleIds.push(articleId);
  },
  deleteBookmark(state, bookmarkedArticleId) {
    const bookmarkedItemIndex = state.bookmarks.findIndex((bookmarkItem) => bookmarkItem.id === bookmarkedArticleId);

    if (bookmarkedItemIndex > -1) {
      state.bookmarks.splice(bookmarkedItemIndex, 1);
    }
  },
  addBookmark(state, bookmarkCreateResponseData) {
    state.bookmarks.push(bookmarkCreateResponseData);
  },
};

export const actions: ActionTree<State, Record<string, unknown>> = {
  async createBookmark({ commit }, data) {
    const { activeArticleId, customerToken } = data;
    try {
      const customerDataService = new CustomerDataService();
      customerDataService.setCustomerToken(customerToken);

      const bookmarkCreateResponse = await customerDataService?.create({ key: 'bookmark', value: activeArticleId });
      if (bookmarkCreateResponse?.createCustomerData.customerData) {
        commit('addBookmark', bookmarkCreateResponse.createCustomerData.customerData);
      }
    } catch (e) {
      this.$sentry.captureException(e, {
        tags: { 'process.type': process.server ? 'server' : 'client' },
      });
    }
  },

  async deleteBookmarkedArticle({ commit }, data) {
    const { bookmarkedArticleId, customerToken } = data;

    try {
      const customerDataService = new CustomerDataService();
      customerDataService.setCustomerToken(customerToken);
      const bookmarkDeleteResponse = await customerDataService.delete({ id: bookmarkedArticleId });

      if (bookmarkDeleteResponse?.deleteCustomerData.status) {
        commit('deleteBookmark', bookmarkedArticleId);
      }
    } catch (e) {
      this.$sentry.captureException(e, {
        tags: { 'process.type': process.server ? 'server' : 'client' },
      });
    }
  },
  async loadBookmarks({ state, commit }, data) {
    const { activeArticleId, customerToken } = data;

    if (state.requestedArticleIds.includes(activeArticleId)) {
      return;
    }

    commit('setRequestedArticleId', activeArticleId);

    try {
      const customerDataService = new CustomerDataService();
      customerDataService.setCustomerToken(customerToken);

      const bookmarksResponse = await customerDataService.get({
        key: 'bookmark',
        value: activeArticleId,
        limit: 1,
        offset: 0,
      });

      if (bookmarksResponse?.customerData) {
        commit('setBookmarks', bookmarksResponse?.customerData);
      }
    } catch (e) {
      this.$sentry.captureException(e, {
        tags: { 'process.type': process.server ? 'server' : 'client' },
      });
    }
  },
};
