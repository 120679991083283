
import Vue from 'vue';
import { formatCustomDate } from '@core/filters/dateFormater';
interface Props {
  date: string;
  dateFormat: string;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  filters: {
    formatCustomDate,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    /**
     * Supports dayjs formats
     */
    dateFormat: {
      type: String,
      default: 'DD.MM.YYYY, HH:mm',
      required: false,
    },
  },
});
