import BlockType12Component from '@headlines/components/block/11-20/BlockType12.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType12: AdminBlockType = {
  component: BlockType12Component,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    category: true,
    relatedTitles: true,
    author: true,
    isBorderEnabled: true,
    textColor: '',
    accentColor: '',
    backgroundColor: '',
    titleComment: true,
    articlesLinksHash: '',
  }),
  articlesCount: 1,
};

export default AdminBlockType12;
