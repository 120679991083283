
import Vue from 'vue';
import AdFormBanner from './AdFormBanner.vue';

interface Data {
  showSiteheader: boolean;
}

interface Computed {
  disableSiteheader: boolean;
  siteheaderKeySettings: {
    defaultPlacement: string;
    pagePlacement: string;
    uniqueKey: string[];
  };
}

// Portal Root updates component with key parameter
// after key change all Vue scope data is reset
// So, keep variables that are needed through all CSR
// out of Vue scope
const siteheaderKey = {
  activeKey: '',
  placement: '',
  uniqueKey: [],
};
export default Vue.extend<Data, unknown, Computed, unknown>({
  components: {
    AdFormBanner,
  },
  data() {
    return {
      showSiteheader: false,
      // As siteheaderKey variable is accessed in template
      // It have to be passed in Vue scope
      siteheaderKey,
    };
  },
  computed: {
    siteheaderKeySettings() {
      const defaultPlacement: string = this.$store.state.adForm.default.placements?.siteheader?.mid;
      const pagePlacement: string = this.$store.state.adForm.page.placements?.siteheader?.mid;

      const uniqueKey: string[] = this.$store.getters['adForm/getAdsUniqueKey'] || [];

      return {
        defaultPlacement,
        pagePlacement,
        uniqueKey,
      };
    },
    disableSiteheader() {
      const disabledAdsList: string[] = this.$store.getters['adForm/getDisabledAdsList'];
      return disabledAdsList.includes('siteheader');
    },
  },
  watch: {
    siteheaderKeySettings({ defaultPlacement, pagePlacement, uniqueKey }) {
      const uniqueKeyChange = uniqueKey.join('-') !== siteheaderKey.uniqueKey.join('-');

      // Re-render siteheader when page placement is active and uniqueKey has changed
      if (pagePlacement && uniqueKeyChange) {
        siteheaderKey.placement = pagePlacement;
        siteheaderKey.uniqueKey = uniqueKey;
        siteheaderKey.activeKey = pagePlacement + uniqueKey.join('-');
        return;
      }

      // Re-render siteheader if page placement is undefined and current placement not equal to default
      if (!pagePlacement && siteheaderKey.placement !== defaultPlacement) {
        siteheaderKey.placement = defaultPlacement;
        siteheaderKey.uniqueKey = uniqueKey;
        siteheaderKey.activeKey = uniqueKey.join('-');
      }
    },
    disableSiteheader: {
      handler(disable: Computed['disableSiteheader']) {
        if (disable) {
          const siteHeaderCloseButton = document.querySelector('#delfi-adx-siteheader .closeButton') as HTMLElement;
          siteHeaderCloseButton?.click();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.showSiteheader = !this.disableSiteheader;
    });
  },
});
