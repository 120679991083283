
import Vue from 'vue';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import ContentLayoutInner from './ContentLayoutInner.vue';
import ContentLayoutInnerCustom from './ContentLayoutInnerCustom.vue';
import type { SectionCustomAdsOptions } from '@root/modules/feed/types';

type Items = Record<string, any>[];

interface Props {
  styles: string | null;
  classes: Record<string, boolean>;
  items: Items;
  insertBannerAfterItems: number;
  insertBannerAfterSection: boolean;
  insertBannerTower: boolean;
  customAdsAlgorithm: SectionCustomAdsOptions[] | null;
  allowFullWidth: boolean;
}

interface Data {
  innerLayoutComponent: string;
}

interface Computed {
  contentLoop: number;
  justifyContent: boolean;
  innerProps: Record<string, unknown>;
  itemSlotName: string;
  disabledAdsList: string[];
}

export default Vue.extend<Data, unknown, Computed, Props>({
  components: {
    AdFormBanner,
    ContentLayoutInner,
    ContentLayoutInnerCustom,
  },
  props: {
    styles: {
      type: String,
      required: false,
      default: '',
    },
    classes: {
      type: Object,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    insertBannerAfterItems: {
      type: Number,
      default: 0,
      required: false,
    },
    insertBannerAfterSection: {
      type: Boolean,
      default: false,
      required: false,
    },
    insertBannerTower: {
      type: Boolean,
      default: true,
      required: false,
    },
    customAdsAlgorithm: {
      type: Array,
      required: false,
      default: null,
    },
    allowFullWidth: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      innerLayoutComponent: 'ContentLayoutInner',
    };
  },
  computed: {
    // Use v-slot:item when your list is made of rows or regular items
    // Use v-slot:row when your list is made of cols
    itemSlotName() {
      // Eslint error is wrong because $scopedSlots.row is undefined or function
      // eslint-disable-next-line no-extra-boolean-cast
      return !!this.$scopedSlots.row ? 'row' : 'item';
    },
    contentLoop() {
      const bannerCount = this.insertBannerAfterItems;
      const count = bannerCount > 0 ? this.items.length / this.insertBannerAfterItems : bannerCount;

      // Round number up to nearest even number
      const loopAmount = count > 1 ? Math.ceil(count) : 1;

      return loopAmount;
    },
    disabledAdsList() {
      return this.$store.getters['adForm/getDisabledAdsList'];
    },
    justifyContent() {
      return !this.allowFullWidth && this.disabledAdsList.includes('tower');
    },
    innerProps() {
      const { insertBannerAfterItems, styles, classes, itemSlotName, justifyContent, customAdsAlgorithm } = this;
      const hasRow = { row: itemSlotName === 'row' };
      const hasBannerOnSide = { 'col-lg-9 flex-fill': justifyContent };
      const fullWidth = { 'col-lg-12': this.allowFullWidth && this.disabledAdsList.includes('tower') };

      return {
        customAdsAlgorithm,
        insertBannerAfterItems,
        style: styles,
        class: ['col', hasRow, hasBannerOnSide, fullWidth, classes],
      };
    },
  },
  created() {
    this.innerLayoutComponent = this.customAdsAlgorithm ? 'ContentLayoutInnerCustom' : 'ContentLayoutInner';
  },
});
