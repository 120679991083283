
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import et_EE from '@core/locales/et_EE.json';

import { BlockCollection } from './index';

Vue.use(VueI18n);

const locale = 'et_EE';
const i18n = new VueI18n({ locale, messages: { et_EE } });

export default Vue.extend({
  i18n,
  components: {
    ...BlockCollection,
  },
  props: {
    blockType: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
});
