
import Vue from 'vue';
import BlockTypeSettings from '@headlines/types/block/21-30/blockType26';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';

const defaultSettings = {
  component: 'adFormBanner',
  adType: 'mobile',
  data: {},
};

interface Computed {
  getSettings: BlockTypeSettings;
}

interface Props {
  data: BlockTypeSettings['data'];
  settings: BlockTypeSettings;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    AdFormBanner,
  },
  computed: {
    getSettings() {
      const settings = this.data?.mid ? { ...{ data: this.data }, ...this.settings } : this.settings;
      return { ...defaultSettings, ...settings };
    },
  },
  props: {
    settings: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: false,
    },
  },
});
