import { Plugin } from '@nuxt/types';
import { AlertWithOptions } from '@root/common/types/alerts';
import LocalStorageService from '@root/modules/localstorage/services/LocalStorage.service';
import { StorageItem } from '@root/modules/localstorage/types/localStorage';
import { diffFromDate } from '@root/common/filters/dateFormater';
import { AlertComponentProps } from '@core/components/UI/alert/types';

interface AlertHandlerPlugin {
  addAlert: (alert: AlertWithOptions) => Promise<void>;
}

declare module 'vue/types/vue' {
  interface Vue {
    $alertHandler: AlertHandlerPlugin;
  }
}

const alertHandler: Plugin = async ({ store }, inject) => {
  const localStorageService = new LocalStorageService();
  await localStorageService.init();

  const shouldShowAlert = (storageItem: StorageItem | undefined, showAgainAfterNMinutes: number): boolean => {
    if (storageItem) {
      if (diffFromDate({ to: storageItem.value, unit: 'minute' }) <= showAgainAfterNMinutes) {
        return false;
      }
    }

    return true;
  };

  const addAlert = async (alert: AlertWithOptions) => {
    const { options, closeCallback, ...alertWithoutOptions } = alert;
    const { key, showAgainAfterNMinutes } = options;

    let updateLocalStorageCallback: (() => Promise<void>) | undefined = undefined;

    if (showAgainAfterNMinutes) {
      const localStorageResponse = await localStorageService.get({ key, limit: 1 });
      const localStorageAlert = localStorageResponse.find((data) => data && data.key === key);

      if (!shouldShowAlert(localStorageAlert, showAgainAfterNMinutes)) {
        return;
      }

      updateLocalStorageCallback = async () => {
        const now = new Date().toString();

        if (localStorageAlert) {
          await localStorageService.batchUpdate([
            {
              id: localStorageAlert.id,
              value: now,
            },
          ]);
        } else {
          await localStorageService.batchCreate([{ key, value: now }]);
        }
      };
    }

    const alertForComponent: AlertComponentProps = {
      ...alertWithoutOptions,
      closeCallback: async () => {
        if (updateLocalStorageCallback) {
          await updateLocalStorageCallback();
        }

        if (closeCallback) {
          closeCallback();
        }
      },
    };

    store.commit('alert', alertForComponent);
  };

  inject('alertHandler', {
    addAlert,
  });
};

export default alertHandler;
