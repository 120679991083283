import Service from '@ekd/portal-connector/dist/Service';
import { getEnv } from '../../../common/utils/getEnv';
import { Config } from '../types/channel';
import { validHosts } from '../config/channel.config';
import { dataLoader } from '../../../libs/redis/dataloader/dataLoader';
import { AxiosRequestConfig } from 'axios';

class ChannelConfigService extends Service {
  /**
   * Fetch configuration
   */
  public async fetch<Response extends Config>(id: string) {
    try {
      if (!id.match(validHosts)) {
        throw new Error(`ChannelConfigService fetch(${id}), hostname is not valid`);
      }

      const dataLoaderOptions = {
        local: {
          cache: true,
        },
        remote: {
          expireTimeMs: 60000, // 30 seconds
          gracePeriodMs: 24 * 3600 * 1000, // 24 hours
          keyPrefix: 'channel_config',
        },
        cacheKeyFn: (params: AxiosRequestConfig) => {
          return `site_${params.params.site}`;
        },
      };

      const options = {
        url: getEnv('configApi'),
        params: {
          site: id,
        },
        timeout: 3000,
      };

      const requestWrapper = (options: AxiosRequestConfig) => {
        const apiProvider = this.createProvider('HTTP');
        return apiProvider.request<Response>(options);
      };

      const redisDataLoader = dataLoader<AxiosRequestConfig, Response | Error>(requestWrapper, dataLoaderOptions);
      const config: Response | Error = redisDataLoader ? await redisDataLoader.load(options) : await requestWrapper(options);

      if (config instanceof Error) {
        throw config;
      }

      if (!config?.settings) {
        return null;
      }

      config.settings.domain = id;

      return config;
    } catch (error) {
      process.sentry?.captureException(`ChannelConfigService fetch(${id}) failed: ${error}`, {
        contexts: { data: { channelId: id } },
        tags: { 'process.type': process.server ? 'server' : 'client' },
      });
      return null;
    }
  }
}

export const channelConfigService = new ChannelConfigService();
