import BlockType2Component from '@headlines/components/block/1-10/block-type-2/BlockType2.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType2: AdminBlockType = {
  component: BlockType2Component,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    author: false,
    titleComment: true,
    channel: false,
    category: false,
    relatedTitles: true,
    lead: false,
    type: 'list',
    articlesLinksHash: '',
  }),
  articlesCount: 2,
};

export default AdminBlockType2;
