
import Vue from 'vue';
import { HeadlineTitle, HeadlineImage, HeadlinePublishDate } from '@headlines/components/fragments';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';
import getItemFromObject from 'lodash/get';

interface Props {
  data: {
    items: HeadlineWithRelatedArticles[];
  };
  settings: Record<string, unknown>;
}

interface Methods {
  removeBookmark: (id: HeadlineWithRelatedArticles['id']) => Promise<void>;
  getItemFromObject: typeof getItemFromObject;
}

export default Vue.extend<unknown, Methods, unknown, Props>({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlinePublishDate,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async removeBookmark(id) {
      this.$eventHandler.emit('delete-bookmark', id);
    },
    getItemFromObject,
  },
});
