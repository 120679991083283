
import { PropType } from 'vue';
import { Fragment } from './Fragment';
import srcConfig from '@core/config/src.config';

import { loadScript, Settings as LoadScriptSettings } from '@core/utils/helpers';

interface SettingsEmbed {
  [x: string]: {
    urlParams: string;
  };
}

// TODO: urlParams are they necessary?

const fragment = new Fragment();
fragment.setRequiredAttrs(['code', 'type']);

const FragmentComponent = fragment.createComponent();

export default FragmentComponent.extend({
  name: 'FragmentLiveblog',
  props: {
    settings: {
      type: Object as PropType<SettingsEmbed>,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      divid: null as string | null,
    };
  },
  mounted() {
    const { code, type } = this;

    if (!code || !type) {
      return;
    }

    this.setMountedState(true);

    // Set liveblog ids
    const id = this.extractId({ type, code });

    if (!id) {
      this.logger('alert', { message: 'Invalid id', debug: this.content });
      return;
    }

    // Load liveblog
    const settings = (this.settings && this.settings[type]) || null;
    this.loadScript({ settings, type, id });
  },
  methods: {
    extractId({ type, code }: { type: string; code: string }) {
      const fullRegex = this.getRegex(type);

      if (!fullRegex) {
        return null;
      }

      const numberRegex = /(\d+)/gm;

      const fullMatch = fullRegex.exec(code);
      const idMatch = fullMatch && fullMatch.length > 0 ? numberRegex.exec(fullMatch[0]) : [];

      const id = idMatch && idMatch.length > 0 ? idMatch[0] : '';

      return id;
    },
    async loadScript({ settings, type, id }: { settings: { urlParams: string }; type: string; id: string }) {
      const loaderUrl = this.getLoaderUrl(type, id);

      if (!loaderUrl) {
        return;
      }

      const loadScriptSettings: LoadScriptSettings = {
        attr: {
          async: true,
        },
        script: settings?.urlParams ? `${loaderUrl.toString()}${settings.urlParams}` : loaderUrl.toString(),
      };

      try {
        await loadScript(loadScriptSettings);
      } catch (e) {
        console.error('FragmentJsmk loadScript error', e);
      }
    },
    getLoaderUrl(type: string, id: string) {
      switch (type) {
        case 'rembi':
          return `${srcConfig.rembi}?rembi=${id}`;
        case 'liveblog':
          return `${srcConfig.liveblog}?event=${id}`;
        default:
          return null;
      }
    },
    getRegex(type: string) {
      switch (type) {
        case 'rembi':
          return /(rembi)=(\d+)/gm;
        case 'liveblog':
          return /(event)=(\d+)/gm;
        default:
          return null;
      }
    },
  },
});
