
import Vue from 'vue';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';

interface Props {
  headline: HeadlineWithRelatedArticles;
  onTop: boolean;
  size: string;
}

interface Data {
  iconList: Record<string, string>;
  contentType: {
    contentMarketing: boolean;
    video: boolean;
    gallery: boolean;
    stream: boolean;
    audio: boolean;
    trending: boolean;
  };
}

interface Computed {
  classModifier: Record<string, any>;
}

const iconList = {
  gallery: 'gallery',
  video: 'play',
  audio: 'microphone',
  trending: 'trending',
};

export default Vue.extend<Data, unknown, Computed, Props>({
  components: {
    BaseIcon,
  },
  props: {
    headline: {
      type: Object,
      required: false,
      default: null,
    },
    onTop: {
      type: Boolean,
      default: true,
      required: false,
    },
    size: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      iconList,
      contentType: {
        contentMarketing: this.headline?.settings?.find((setting) => setting.key === 'contentMarketing')?.value ?? false,
        video: this.headline?.relatedEntities?.hasVideo,
        gallery: this.headline?.relatedEntities?.hasGallery,
        stream: this.headline?.relatedEntities?.hasStream,
        audio: this.headline?.relatedEntities?.hasAudio,
        trending: this.headline?.settings?.find((setting) => setting.key === 'trending')?.value ?? false,
      },
    };
  },
  computed: {
    classModifier() {
      return {
        'headline-icons--on-top': this.onTop,
        [`headline-icons--${this.size}`]: !!this.size,
        'headline-icons--shadow': this.onTop && this.size === 'small' && this.contentType.contentMarketing,
      };
    },
  },
});
