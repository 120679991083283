
import { HeadlineTitle, HeadlineImage, HeadlineAuthor, HeadlineLabels, HeadlineIcons, HeadlineLead } from '../../../fragments';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/1-10/blockType3';

const defaultSettings = {
  icons: true,
  author: true,
  titleComment: true,
  channel: true,
  category: true,
  lead: true,
  type: 'grid',
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlineAuthor,
    HeadlineLabels,
    HeadlineIcons,
    HeadlineLead,
  },
});
