
import Vue from 'vue';
import Toggle from '@core/components/UI/form/Toggle.vue';
import { GVL, VendorList, Stack, Vendor } from '@iabtechlabtcf/core';
import { CookieConsentGroup, CookieConsentNonTcfVendor, CookieConsentGroupInfo } from '@root/modules/analytics/types/analytics';
import CookieConsentVendor from './CookieConsentVendor.vue';
import { getGroupConsentData } from '@root/modules/analytics/utils/cookieConsent';
import { fetchNonTcfVendorsListUrl } from '@root/modules/analytics/services/fetchConsentData.service';

interface vendorDetails extends Vendor {
  featuresList?: string[];
  specialFeaturesList?: string[];
  purposesList?: string[];
  specialPurposesList?: string[];
  legIntPurposesList?: string[];
}

interface Props {
  groups: CookieConsentGroup[];
  gvl: GVL;
  consentVendors: number[];
  legitimateInterestVendors: number[];
  vendorsListShow: boolean;
  consentNonTcfVendors: number[];
}

interface Data {
  showDetails: boolean;
  groupsSettings: CookieConsentGroup[];
  info: CookieConsentGroupInfo;
  vendorList: VendorList | null;
  vendorsConsent: Record<number, boolean>;
  nonTcfVendors: CookieConsentNonTcfVendor[];
  nonTcfVendorsConsent: Record<number, boolean>;
  vendorsLegitimateInterest: Record<number, boolean>;
  showVendors: boolean;
  vendorActiveDetails: number;
  allGroupsConsent: boolean;
  allLegitimateVendorsConsent: boolean;
  allVendorsConsent: boolean;
  singleChange: boolean;
}

interface Methods {
  selectGroup: (group: CookieConsentGroupInfo | CookieConsentGroup) => void;
  showInfo: (group: CookieConsentGroup) => void;
  getGroupVendorsCount: (group: CookieConsentGroup) => number;
  vendorsConsentList: (value?: boolean, id?: number) => void;
  allVendorsConsentList: () => void;
  allLegitimateVendorsConsentList: () => void;
  nonTcfVendorsConsentList: () => void;
  vendorsLegitimateInterestList: () => void;
  toggleVendorsList: () => void;
  vendorDetails: (vendor: Vendor) => void;
  toggleDetails: (id: number) => void;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  components: {
    Toggle,
    CookieConsentVendor,
  },
  props: {
    groups: {
      type: Array,
      required: true,
    },
    legitimateInterestVendors: {
      type: Array,
      required: true,
    },
    consentVendors: {
      type: Array,
      required: false,
      default: () => [],
    },
    gvl: {
      type: Object,
      required: true,
    },
    vendorsListShow: {
      type: Boolean,
      default: false,
    },
    consentNonTcfVendors: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    const consentGroupsLength = this.groups.reduce((consent, group) => consent + Number(group.selected), 0);

    return {
      showDetails: false,
      vendorList: null,
      showVendors: false,
      groupsSettings: this.groups,
      info: {
        name: '',
        type: '',
        selected: false,
        mandatory: false,
        groups: [],
      },
      vendorsConsent: {},
      nonTcfVendors: [],
      nonTcfVendorsConsent: {},
      vendorsLegitimateInterest: {},
      vendorActiveDetails: 0,
      allVendorsConsent: false,
      allGroupsConsent: consentGroupsLength === this.groups.length,
      singleChange: true,
      allLegitimateVendorsConsent: this.legitimateInterestVendors.length > 0,
    };
  },
  watch: {
    allLegitimateVendorsConsent() {
      this.singleChange = false;
      this.allLegitimateVendorsConsentList();
    },
    allVendorsConsent() {
      this.singleChange = false;
      this.allVendorsConsentList();
    },
    allGroupsConsent(value) {
      this.groupsSettings.forEach((group) => {
        // Skip mandatory group
        if (!group.mandatory) {
          group.selected = value;
        }
      });

      this.allLegitimateVendorsConsent = value;
      this.allVendorsConsent = value;
      this.$emit('consent-group', this.groupsSettings);
    },
  },
  async created() {
    this.showVendors = this.vendorsListShow;
    this.vendorList = this.$store.getters['analytics/getVendorsList'];

    // Fill consent vendors for list
    if (this.consentVendors.length > 0) {
      this.consentVendors.forEach((key) => {
        this.vendorsConsent[key] = true;
      });
    }

    this.legitimateInterestVendors.forEach((key) => {
      this.vendorsLegitimateInterest[key] = true;
    });

    // Fill consent non tcf vendors for list
    this.nonTcfVendors = await fetchNonTcfVendorsListUrl();
    if (this.consentNonTcfVendors.length > 0) {
      this.consentNonTcfVendors.forEach((key) => {
        this.nonTcfVendorsConsent[key] = true;
      });
    }
  },
  methods: {
    vendorDetails(vendor) {
      const vendorDetails: vendorDetails = vendor;
      const vendorFeatures = vendor.features.map((feature) => {
        return this.vendorList?.features[feature].name;
      });
      vendorDetails.featuresList = vendorFeatures as string[];

      const vendorSpecialFeatures = vendor.specialFeatures.map((feature) => {
        return this.vendorList?.specialFeatures[feature].name;
      });
      vendorDetails.specialFeaturesList = vendorSpecialFeatures as string[];

      const vendorPurposes = vendor.purposes.map((feature) => {
        return this.vendorList?.purposes[feature].name;
      });
      vendorDetails.purposesList = vendorPurposes as string[];

      const vendorSpecialPurposes = vendor.specialPurposes.map((feature) => {
        return this.vendorList?.purposes[feature].name;
      });
      vendorDetails.specialPurposesList = vendorSpecialPurposes as string[];

      const vendorLegIntPurposes = vendor.legIntPurposes.map((feature) => {
        return this.vendorList?.purposes[feature].name;
      });
      vendorDetails.legIntPurposesList = vendorLegIntPurposes as string[];

      return vendorDetails;
    },
    toggleDetails(id) {
      this.vendorActiveDetails = id;
    },
    showInfo(group) {
      this.showDetails = true;
      this.info.type = group.type;
      this.info.extraInfo = group.extraInfo;
      this.info.selected = group.selected;
      this.info.mandatory = group.mandatory;
      this.info.groups = [];

      let groupVendors: VendorList['vendors'] | null = null;
      group.settings.forEach((gr) => {
        gr.value.forEach((val) => {
          const infoGroup = (this.vendorList && gr.type && this.vendorList[gr.type]?.[val]) as Stack;
          if (infoGroup && infoGroup.purposes) {
            infoGroup.purposes.forEach((id: number) => {
              const vendors = this.gvl.getVendorsWithConsentPurpose(id) as VendorList['vendors'];
              groupVendors = { ...groupVendors, ...vendors };
            });
          }
          this.info.groups.push(infoGroup);
        });
      });
      this.info.vendors = groupVendors;
    },
    getGroupVendorsCount(group) {
      let selectedVendors: number[] = [];

      this.groupsSettings.forEach((gr) => {
        if (gr.type === group.type) {
          const { vendorsIds } = getGroupConsentData(this.gvl, gr);
          selectedVendors = [...new Set([...selectedVendors, ...vendorsIds])];
        }
      });

      return selectedVendors.length;
    },
    selectGroup(group) {
      let selectedVendors: number[] = [];
      this.groupsSettings.forEach((gr) => {
        if (gr.type === group.type) {
          gr.selected = group.selected;
        }

        if (gr.selected) {
          const { vendorsIds } = getGroupConsentData(this.gvl, gr);
          selectedVendors = [...new Set([...selectedVendors, ...vendorsIds])];
        }
      });

      this.vendorsConsent = {};
      this.vendorsLegitimateInterest = {};
      selectedVendors.forEach((id) => {
        this.vendorsConsent[Number(id)] = true;
        this.vendorsLegitimateInterest[Number(id)] = true;
      });

      this.$emit('consent-group', this.groupsSettings);
      this.vendorsConsentList();
    },
    vendorsConsentList() {
      const vendorsIds = Object.keys(this.vendorsConsent)
        .filter((key) => !!this.vendorsConsent[key as unknown as number])
        .map(Number);

      this.$emit('consent-vendors', vendorsIds);
    },
    async allVendorsConsentList() {
      if (this.vendorList) {
        Object.keys(this.vendorList.vendors).forEach((id) => {
          this.vendorsConsent[Number(id)] = this.allVendorsConsent;
        });

        this.vendorsConsentList();
        this.singleChange = true;
      }
    },
    vendorsLegitimateInterestList() {
      const vendorsIds = Object.keys(this.vendorsLegitimateInterest)
        .filter((key) => !!this.vendorsLegitimateInterest[key as unknown as number])
        .map(Number);

      this.$emit('legitimate-interest-vendors', vendorsIds);
    },

    async allLegitimateVendorsConsentList() {
      if (this.vendorList) {
        Object.keys(this.vendorList.vendors).forEach((id) => {
          this.vendorsLegitimateInterest[Number(id)] = this.allLegitimateVendorsConsent;
        });
      }
      this.vendorsLegitimateInterestList();
      this.singleChange = true;
    },
    nonTcfVendorsConsentList() {
      const vendorsIds = Object.keys(this.nonTcfVendorsConsent)
        .filter((key) => !!this.nonTcfVendorsConsent[key as unknown as number])
        .map(Number);
      this.$emit('consent-nontcf-vendors', vendorsIds);
    },
    toggleVendorsList() {
      this.showVendors = !this.showVendors;
    },
  },
});
