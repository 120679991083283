export default {
  VIEW_CONTENT: {
    types: {
      view: {
        eventType: 'view_content',
        params:
          '{ "event": "view_content", "page_info": { "page_type": "${data.page_type}", "is_paid_article": "${data.is_paid_article}" }, "user_info": { "logged_in": "${data.logged_in}", "product_type": "${data.product_type}" } }',
      },
    },
  },
  NEW_SUBSCRIPTION: {
    types: {
      click: {
        eventType: 'new_subscription',
        params: '{ "event": "new_subscription", "order_customer_id": "${data.order_customer_id}" }',
      },
    },
  },
  SIGN_UP: {
    types: {
      click: {
        eventType: 'sign_up',
        params: '{ "event": "sign_up" }',
      },
    },
  },
  LOGIN: {
    types: {
      click: {
        eventType: 'login',
        params: '{ "event": "login", "user_info": { "product_type":  "${data.product_type}" } }',
      },
    },
  },
};
