import { TrackingData } from '@root/common/types/fragment';
import TrackingInfoDecoder from '@root/common/utils/TrackingInfoDecoder';
import { QueryParameter } from '@root/common/types/queryParameter';
import { buildQueryUrl } from '@root/router/utils/buildRoutePath';
export function addTrackingParameterToHref(href: string, tracking?: TrackingData) {
  if (!tracking) {
    return href;
  }

  const trackingInfoDecoder = new TrackingInfoDecoder();
  const encodedString = trackingInfoDecoder.encode(tracking);

  if (encodedString) {
    const query: Record<string, string> = {
      [QueryParameter.TrackingParameter]: encodedString,
    };

    href = buildQueryUrl(href, { query });
  }

  return href;
}
