
import Vue from 'vue';
import { BlockCollection } from '@headlines/components/block';
import BookmarksHeadline from '@root/modules/customer/components/BookmarksHeadline.vue';
import { QueryResult, HeadlineSettings, DataWithHref, Data as HeadlinesData } from '@root/modules/headlines/types/headlines';
import ContentLayout from '@root/common/components/layout/ContentLayout.vue';
import getHeadlineWithHref from '@root/modules/headlines/utils/getHeadlineWithHref';

const Pagination = () => import(/* webpackChunkName: "HeadlineList/Pagination" */ '@root/common/components/base/pagination/Pagination.vue');

interface Props {
  type: string;
  list: QueryResult;
  settings: HeadlineSettings;
  pagination?: boolean;
  insertBannerAfterItems?: number;
}

interface Methods {
  getHeadlineDataWithHref: (data: HeadlinesData) => DataWithHref;
  getHeadline: (headline: HeadlinesData) => {
    items: DataWithHref[];
  };
}

export default Vue.extend<unknown, Methods, unknown, Props>({
  components: {
    BlockType5: BlockCollection.BlockType5,
    BlockType6: BlockCollection.BlockType6,
    BookmarksHeadline,
    ContentLayout,
    Pagination,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    list: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    pagination: {
      type: Boolean,
      default: false,
      required: false,
    },
    insertBannerAfterItems: {
      type: Number,
      default: 5,
      required: false,
    },
  },
  methods: {
    getHeadlineDataWithHref(headline) {
      const { domain, locale } = this.$channelConfig('settings');
      const fakeDomainEnabled = this.$config.FAKE_DOMAIN_ENABLED;
      return getHeadlineWithHref({ headline, domain, locale, fakeDomainEnabled });
    },
    getHeadline(headline) {
      return {
        items: [{ ...this.getHeadlineDataWithHref(headline) }],
      };
    },
  },
});
