
import Vue from 'vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import Dropdown from '@core/components/UI/dropdown/Dropdown.vue';
import DropdownItem from '@core/components/UI/dropdown/DropdownItem.vue';
import SocialTooltip from '@root/common/components/base/SocialTooltip.vue';
import HeaderMenuItem from './HeaderMenuItem.vue';
import HeaderNewBubble from './HeaderNewBubble.vue';
import { ListData, BottomNavigationProps } from '@root/modules/navigation/types/navigation';
import navigationCustomCss from '@root/modules/navigation/utils/navigationCustomCss';
import Meta from '@root/common/utils/Meta';

// This component can render header with multiple variations.
// Supported placements: header--menu-top, header--menu--primary and header--menu--bottom.
// Each placement supports menus with left, center and right navigation.

// Header with placement="bottom" supports :settings which can be altered to give an unique appearance for header--menu--bottom.
// Supported settings: background, logo and title.
// Props :settings can be overwritten from different pages (example: category page).

interface Props {
  menu: {
    left?: ListData[];
    center: ListData[];
    right?: ListData[];
  };
  placement: string;
  activeCategoryName: string | null;
  settings?: BottomNavigationProps | null;
}

interface Data {
  showMenu: {
    left: boolean;
    center: boolean;
    right: boolean;
  };
}

interface Computed {
  getSettings: BottomNavigationProps | null;
  placementClass: Record<string, any> | string[];
  background: Record<string, string> | null;
  logo: string | null;
  secondaryLogo: string | null;
  logoUrl: {
    href: string;
    target: string;
  } | null;
  breadcrumb: {
    href: string;
    text: string;
  } | null;
  title: string | null;
  description: string | null;
  theme: string | null;
  uniqueId: string | null;
  share: boolean;
}

interface Methods {
  checkSlots: () => void;
  openCookieConsent: () => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    LinkHandler,
    HeaderMenuItem,
    Dropdown,
    DropdownItem,
    HeaderNewBubble,
    SocialTooltip,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
    placement: {
      type: String,
      required: true,
    },
    activeCategoryName: {
      type: String,
      default: null,
      required: false,
    },
    settings: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      showMenu: {
        left: false,
        center: false,
        right: false,
      },
    };
  },
  head() {
    const meta = new Meta(this.$channelConfig('meta').base);

    if (this.getSettings?.css && this.getSettings?.uniqueId) {
      const uniqueSelector = `#${this.uniqueId}`;
      const cssText = navigationCustomCss(this.getSettings.css, uniqueSelector);
      meta.style = [{ cssText, hid: 'custom-header' }];
    }

    return {
      style: meta.style,
      __dangerouslyDisableSanitizers: ['style'],
    };
  },
  computed: {
    // Allow settings only for header with props placement="bottom" (header-menu--bottom)
    getSettings() {
      const { placement, settings } = this;
      const isBottomPlacement = placement === 'bottom';
      return !!settings && isBottomPlacement ? settings : null;
    },
    background() {
      const { getSettings } = this;
      if (!getSettings || getSettings.type !== 'big') {
        return null;
      }
      return getSettings?.background ? { 'background-image': `url(${getSettings.background})` } : null;
    },
    logo() {
      return (this.getSettings?.type === 'big' && this.getSettings?.logo) || null;
    },
    secondaryLogo() {
      return (this.getSettings?.type === 'big' && this.getSettings?.secondaryLogo) || null;
    },
    logoUrl() {
      return (this.getSettings?.type === 'big' && this.getSettings?.logoUrl) || null;
    },
    title() {
      return (this.getSettings?.type === 'big' && this.getSettings?.title) || null;
    },
    description() {
      return (this.getSettings?.type === 'big' && this.getSettings?.description) || null;
    },
    theme() {
      return (this.getSettings?.type === 'big' && this.getSettings?.theme) || null;
    },
    breadcrumb() {
      return (this.getSettings?.type === 'big' && this.getSettings?.breadcrumb) || null;
    },
    share() {
      return Boolean(this.getSettings?.type === 'big' && this.getSettings?.share);
    },
    // Create different class modifiers depending on settings
    placementClass() {
      const { placement, getSettings, theme } = this;
      return [
        `header-menu--${placement}`,
        {
          'header-menu--background': getSettings?.background && getSettings?.type === 'big',
        },
        {
          'header-menu--big': getSettings?.type === 'big',
        },
        {
          'header-menu--logo': getSettings?.logo,
        },
        {
          'display-none display-lg-block': getSettings && getSettings.type !== 'big',
        },
        theme ? `header-menu--${theme}` : '',
      ];
    },
    uniqueId() {
      return this.getSettings?.uniqueId ? `header-menu--${this.getSettings.uniqueId}` : null;
    },
  },
  created() {
    this.checkSlots();
  },
  methods: {
    // Check whether to show slot content or menu content
    // If both are empty then don't render anything
    checkSlots() {
      const { $slots, menu } = this;

      const left = $slots['menu-left'] !== undefined || (!!menu.left && menu.left.length > 0);
      const center = !!menu.center && menu.center.length > 0;
      const right = $slots['menu-right'] !== undefined || (!!menu.right && menu.right.length > 0);

      this.showMenu = {
        left,
        center,
        right,
      };
    },
    openCookieConsent() {
      this.$store.commit('analytics/setConsentSettingsVisibility', true);
    },
  },
});
