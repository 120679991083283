
import Vue from 'vue';
import { ListData, HeaderActions } from '@root/modules/navigation/types/navigation';
import CustomerMenuDropdown from '@root/common/components/layout/header/CustomerMenuDropdown.vue';
import HeaderBurger from './HeaderBurger.vue';
import { ExtendedProfile, PianoCustomerProfile } from '@piano/types/pianoState';

interface Props {
  misc: Record<string, ListData>;
  data: {
    isLoggedIn: boolean;
    profile: PianoCustomerProfile | null;
    extendedProfile: ExtendedProfile | null;
  };
  openLoginModal: () => void;
  logout: () => void;
}

interface Methods {
  closeMenu: () => void;
  toggleAction: (type: HeaderActions['type'], value: HeaderActions['value']) => void;
}

interface Computed {
  isSitemapOpen: boolean;
  isSearchOpen: boolean;
  isProfileInfoOpen: boolean;
  isIssueListOpen: boolean;
  isArticle: boolean;
  isIssuePage: boolean;
  hasChannelSubscription: boolean;
  maintenanceMode: boolean;
  hideOrdering: boolean;
  isFirstNameFilled: boolean;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: {
    CustomerMenuDropdown,
    HeaderBurger,
  },
  props: {
    misc: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    openLoginModal: {
      type: Function,
      required: true,
    },
    logout: {
      type: Function,
      required: true,
    },
  },
  computed: {
    isSitemapOpen() {
      return this.$store.getters['navigation/getHeaderAction']('sitemap');
    },
    isSearchOpen() {
      return this.$store.getters['navigation/getHeaderAction']('search');
    },
    isProfileInfoOpen() {
      return this.$store.getters['navigation/getHeaderAction']('profileInfo');
    },
    isIssueListOpen() {
      return this.$store.getters['navigation/getHeaderAction']('issueList');
    },
    isArticle() {
      return this.$route.name === 'article' || this.isIssuePage;
    },
    isIssuePage() {
      return this.$route.name === 'issue-page' || this.$route.name === 'issue-article';
    },
    hasChannelSubscription() {
      return this.$store.state.piano.access.channelAccess;
    },
    maintenanceMode() {
      return this.$store.state.piano.config.maintenanceMode;
    },
    hideOrdering() {
      const { specialFeatures } = this.$channelConfig('settings').application;
      return specialFeatures.hideSignupAndOrders.enabled;
    },
    isFirstNameFilled() {
      return this.$store.state.piano.profile?.firstName || false;
    },
  },
  methods: {
    toggleAction(type, value) {
      this.$store.commit('navigation/setHeaderAction', { type, value });
    },
    closeMenu() {
      if (!this.isSitemapOpen) {
        return;
      }
      this.toggleAction('sitemap', false);
    },
  },
});
