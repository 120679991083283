import BlockType24 from '@headlines/components/block/21-30/BlockType24.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType24: AdminBlockType = {
  component: BlockType24,
  attributes: AdminBlockTypeAttributes({
    titleComment: true,
    articlesLinksHash: '',
  }),
  articlesCount: 6,
};

export default AdminBlockType24;
