import BlockType11Component from '@headlines/components/block/11-20/block-type-11/BlockType11.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType11: AdminBlockType = {
  component: BlockType11Component,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    titleComment: false,
    backgroundColor: 'var(--color-grey-3)',
    accentColor: 'var(--color-color-1)',
    articlesLinksHash: '',
  }),
  articlesCount: 2,
};

export default AdminBlockType11;
