
import Vue from 'vue';
import { StreamsPerDay } from './types/blockType23';
import { getTime, isLive, isDateToday, formatDateForDay } from '@headlines/utils/BlockType23';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import BaseIcon from '@core/components/UI/BaseIcon.vue';

interface Props {
  locale: string;
  streamsByDay: StreamsPerDay[];
}

interface Methods {
  formatDateForDay: typeof formatDateForDay;
  getTime: typeof getTime;
  isDateToday: typeof isDateToday;
  isLive: typeof isLive;
}

export default Vue.extend<unknown, Methods, unknown, Props>({
  props: {
    locale: {
      type: String,
      required: true,
    },
    streamsByDay: {
      type: Array,
      required: true,
    },
  },
  components: {
    BaseIcon,
    AdFormBanner,
  },
  methods: {
    getTime,
    isLive,
    isDateToday,
    formatDateForDay,
  },
});
