import { routerComponents } from './routerComponents';
import combinedAliases from './routerAliases';

// Order: /static, /index, /:dynamic
// Match exact route before index: /login before /index/_slug
const routes = [
  {
    name: 'authors',
    path: '/authors',
    alias: combinedAliases.authors.map((alias) => `/${alias}`),
    component: routerComponents.Authors,
  },
  {
    name: 'latest',
    path: '/latest',
    alias: combinedAliases.latest.map((alias) => `/${alias}`),
    component: routerComponents.Latest,
  },
  {
    name: 'popular',
    path: '/popular',
    alias: combinedAliases.popular.map((alias) => `/${alias}`),
    component: routerComponents.Popular,
  },
  {
    name: 'search',
    path: '/search',
    alias: combinedAliases.search.map((alias) => `/${alias}`),
    component: routerComponents.Search,
  },
  {
    name: 'author',
    path: '/author/:id/:slug?',
    alias: combinedAliases.author.map((alias) => `/${alias}/:id/:slug?`),
    component: routerComponents.Author,
  },
  {
    name: 'issues',
    path: '/issues',
    alias: combinedAliases.issues.map((alias) => `/${alias}`),
    component: routerComponents.Issues,
  },
  {
    name: 'issue-page',
    path: '/issue/:issueid',
    alias: combinedAliases.issue.map((alias) => `/${alias}/:issueid`),
    component: routerComponents.Issue,
    children: [
      {
        name: 'issue-article',
        path: 'article/:id?/:slug?',
        alias: combinedAliases.article.map((alias) => `${alias}/:id?/:slug?`),
        component: routerComponents.Issue,
      },
    ],
  },
  {
    name: 'category',
    path: '/category/:id/:slug?',
    alias: combinedAliases.category.map((alias) => `/${alias}/:id?/:slug?`),
    component: routerComponents.Category,
  },
  {
    name: 'topic',
    path: '/topic/:id/:slug?',
    alias: combinedAliases.topic.map((alias) => `/${alias}/:id?/:slug?`),
    component: routerComponents.Tag,
  },
  {
    name: 'frontpage',
    path: '/',
    component: routerComponents.Frontpage,
  },
  {
    name: 'customPage',
    path: '/page/*',
    alias: combinedAliases.page.map((alias) => `/${alias}/*`),
    component: routerComponents.Custom,
  },
  {
    name: 'profile',
    path: '/client/profile',
    alias: combinedAliases.profile.map((alias) => `/${alias}`),
    component: routerComponents.Profile,
  },
  {
    name: 'resetPassword',
    path: '/client/password',
    alias: combinedAliases.password.map((alias) => `/${alias}`),
    component: routerComponents.Password,
  },
  {
    name: 'bookmarks',
    path: '/bookmarks',
    alias: combinedAliases.bookmarks.map((alias) => `/${alias}`),
    component: routerComponents.Bookmarks,
  },
  {
    path: '/article/:id/:slug?',
    alias: ['/a/:id/:slug?', ...combinedAliases.article.map((alias) => `/${alias}/:id/:slug?`)],
    component: routerComponents.ArticleBase,
    children: [
      {
        name: 'articleComments',
        path: 'comments',
        alias: combinedAliases.comments.map((alias) => `${alias}`),
        component: routerComponents.ArticleComments,
      },
      {
        name: 'article',
        path: '',
        component: routerComponents.Article,
      },
    ],
  },
];

export default routes;
