export enum AdBlockFallbackType {
  embed = 'embed',
  adnet = 'adnet',
}

export interface AdFormOptions {
  // unq?: string;
  mid?: number;
  mkw?: string[];
  mkv?: Record<string, string>;
  lazyload?: boolean;
  interscrollerWrappers?: boolean;
  widthAtLeast?: number;
  widthAtMost?: number;
  async?: boolean;
  portalRootMobile?: boolean;
  portalRootDesktop?: boolean;
  adblockFallback?: {
    type?: AdBlockFallbackType;
    hideInMobile?: boolean;
    hideInDesktop?: boolean;
    attrs?: {
      code?: string;
      width?: number;
      height?: number;
      responsive?: boolean;
    };
  };
}

export interface AdFormProps extends AdFormOptions {
  dom: string;
}

export interface AdFormConfig {
  types: AdFormPlacementType[];
  disableAds: AdFormPlacementType[];
  mobileAds: AdFormPlacementType[];
  mobileFallbackAds: Record<AdFormPlacementType, AdFormPlacementType>;
}

export type AdFormPlacementType =
  | 'siteheader'
  | 'mobilesiteheader'
  | 'square'
  | 'content'
  | 'leaderboard'
  | 'tower'
  | 'gallerytower'
  | 'mobile'
  | 'floating'
  | 'scroller'
  | 'floor'
  | 'teaser'
  | 'interstitial'
  | 'mobileinterstitial'
  | 'mobileleaderboard'
  | 'galleryscroller';

export type AdFormPlacements = Record<AdFormPlacementType, AdFormOptions>;

export interface AdFormState {
  allowAdsRendering: boolean;
  enableAdBlockLayer: boolean;
  //  Publisher provided identifier - https://support.google.com/dfp_premium/answer/2880055?hl=en
  ppid: string;
  disableAdsCondition: {
    adFree: boolean;
    subscriber: boolean;
  };
  default: {
    disableAds: string[];
    placements: AdFormPlacements | Record<string, unknown>;
    mkw: string[];
    mkv: Record<string, unknown>;
  };
  page: {
    disableAds: string[];
    enableAds: string[];
    placements: AdFormPlacements | Record<string, unknown>;
    mkw: string[];
    mkv: Record<string, unknown>;
    uniqueKey: string[];
  };
}
