
import Vue from 'vue';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';
import BaseButton from '@core/components/UI/BaseButton.vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import { gridConfig } from '@core/config/grid.config';
import { getRootConfig, convertArrayToObject } from '@core/utils/helpers';
import { extendRoutePath } from '@root/router/utils/buildRoutePath';
import getItemFromObject from 'lodash/get';

type ShareData = {
  url: string;
  title: string;
  text: string;
};

interface ShNavigator extends Navigator {
  share: (data?: ShareData) => Promise<void>;
}

interface ShWindow extends Window {
  [index: string]: any;
  navigator: ShNavigator;
}

declare let window: ShWindow;

interface Props {
  headline: HeadlineWithRelatedArticles;
  titleSize: string;
  mobileTitleSize: string;
  titleCommentEnabled: boolean;
  showFacebookShareCount: boolean;
  showCommentsCount: boolean;
}

interface Computed {
  titleClassModifier: Record<string, any>;
  facebookShareCount: number | null;
  facebookUrl: string;
  isMobile: boolean;
  commentsPath: string;
}

interface Methods {
  popupWindow: (url: string) => void;
  getItemFromObject: typeof getItemFromObject;
}

interface Data {
  popup: {
    width: number;
    height: number;
    name: string;
  };
  shareUrl: string;
}

const sizeList = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h6',
  h4: 'h6',
  h5: 'h6',
  h6: 'h6',
} as Record<string, string>;

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    LinkHandler,
    BaseButton,
  },
  data() {
    return {
      popup: {
        width: 500,
        height: 400,
        name: 'share_dialog',
      },
      shareUrl: `${getRootConfig('meta')?.base?.baseUrl}/a/${this.headline.id}`,
      comments: convertArrayToObject<{
        disableComments: boolean;
        onlyRegisteredUsersAllowedToComment: boolean;
      }>(this.headline?.comments, { disableComments: false, onlyRegisteredUsersAllowedToComment: false }),
    };
  },
  props: {
    /**
     * @values  {
     *  title: string,
     *  title_short: string,
     *  title_comment: string,
     *  comments: object,
     *  shares: array,
     *  href: string,
     * }
     */
    headline: {
      type: Object,
      required: true,
    },
    /**
     * @values 'h1', 'h2', 'h3', 'h4, 'h5, 'h6'
     */
    titleSize: {
      type: String,
      default: 'h2',
      required: false,
    },
    mobileTitleSize: {
      type: String,
      default: '',
      required: false,
    },
    titleCommentEnabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    showFacebookShareCount: {
      type: Boolean,
      default: true,
      required: false,
    },
    showCommentsCount: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    titleClassModifier() {
      const { titleSize } = this;
      let mobileTitleSize = this.mobileTitleSize;

      if (!mobileTitleSize && titleSize in sizeList) {
        mobileTitleSize = sizeList[titleSize];
      }

      return {
        [`headline-title--size-${titleSize}`]: titleSize,
        [`headline-title--size-sm-${mobileTitleSize}`]: mobileTitleSize,
      };
    },
    facebookShareCount() {
      const shares = this.getItemFromObject(this.headline, 'statistics.shares');
      // FIXME: Should be taken from config
      // but is workarounded during migration
      // to git submodules
      return shares >= 20 ? shares : null;
    },
    facebookUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(this.shareUrl)}&t=${encodeURI(
        this.getItemFromObject(this.headline, 'content.title.text') || ''
      )}`;
    },
    isMobile() {
      return screen.width <= gridConfig.breakpoints.lg;
    },
    commentsPath() {
      return extendRoutePath({ route: this.headline.href, suffix: String(this.$t('portal_core.comments')) });
    },
  },
  methods: {
    popupWindow(url) {
      const params = `width=${this.popup.width},height=${this.popup.height},directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no`;
      const lead = this.getItemFromObject(this.headline, 'content.lead.text') || '';
      const title = this.getItemFromObject(this.headline, 'content.title.text') || '';

      if (window.navigator.share && this.isMobile) {
        window.navigator.share({
          title,
          url: this.shareUrl,
          text: lead,
        });
      } else if (window.AndroidWebAppMobileAPI && window.AndroidWebAppMobileAPI.sharePage) {
        window.AndroidWebAppMobileAPI.sharePage(this.shareUrl, title, lead);
      } else {
        window.open(url, this.popup.name, params);
      }
    },
    getItemFromObject,
  },
});
