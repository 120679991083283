
import Vue from 'vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import BlockTypeSettings from '@headlines/types/block/1-10/blockType8';

type Links = {
  text: string;
  href: string;
  target: string;
};

const defaultSettings = {
  text: true,
  logo: false,
  secondaryLogo: false,
  links: false,
  colorBlock: null,
};

interface Props {
  settings: BlockTypeSettings;
  data: {
    title: {
      logo: string;
      text: string;
      href: string;
      target?: string;
    };
    secondaryLogo:
      | {
          href?: string;
          target?: string;
          src: string;
        }
      | string;
    links: Links[] | [];
  };
}

interface Computed {
  colorBlockStyles: string;
  getSettings: BlockTypeSettings;
  classModifier: Record<string, unknown>;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    LinkHandler,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getSettings() {
      return { ...defaultSettings, ...this.settings };
    },
    colorBlockStyles() {
      return this.getSettings.colorBlock ? `background: ${this.getSettings.colorBlock};` : '';
    },
    classModifier() {
      return { 'block-type-8--with-color-block': this.getSettings.colorBlock };
    },
  },
});
