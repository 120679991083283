import { ImageItem } from '@gallery/types/Gallery';

export const getPictureCredit = (pictureMetadata: ImageItem['metadata'] | undefined): string => {
  let result = '';

  const authors = pictureMetadata?.authors || [];
  const normalizedAuthors = authors.map((author) => author.trim());
  const validAuthors = normalizedAuthors.filter(Boolean);
  const authorsString = validAuthors.join(', ');

  const credit = pictureMetadata?.credit?.trim() || '';

  if (authorsString.toLowerCase() === credit.toLowerCase()) {
    result = authorsString;
  } else {
    result = [authorsString, credit].filter(Boolean).join('/');
  }

  return result;
};
