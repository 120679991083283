// import Vue from 'vue';
import { Plugin } from '@nuxt/types';
import { DelfiTEE } from '@analytics/trackers';
import { DelfiTeeEvent } from '@root/modules/analytics/types/analytics';
import events from '../config/delfiTEE';
import interpolate from '@root/common/utils/interpolate';
import getEventByKey from '../utils/getEventByKey';

interface EdTracker {
  signal(eventName: string, eventValue: Record<string, unknown> | string): void;
}

// Inject into Vue instances (this.$edTracker)
declare module 'vue/types/vue' {
  interface Vue {
    $edTracker: EdTracker;
  }
}

const DelfiTEEPlugin: Plugin = async ({ store, app }) => {
  const { locale } = app.$channelConfig('settings');

  const edTracker = new DelfiTEE();
  await edTracker.init();

  // Watch analytics store eventName change
  store.watch(
    (state) => state.analytics.tracker.edTracker.clickEventName,
    async (clickEventName) => {
      if (!clickEventName) {
        return;
      }
      const event = getEventByKey<DelfiTeeEvent>(events, clickEventName, locale);

      if (event) {
        if (!store.state.analytics.tracker.edTracker.clickEventData) {
          return false;
        }

        const { type, data } = store.state.analytics.tracker.edTracker.clickEventData;
        const eventData = event.types[type];
        const { action, label } = eventData;
        const eventLabel = label ? JSON.parse(interpolate(label, { data })) : '';
        edTracker.signal(action, eventLabel);
      }
      store.commit('analytics/setClickEvent', { edTracker: { eventName: null, eventData: null } });
    }
  );
};

export default DelfiTEEPlugin;
