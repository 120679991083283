import { CustomFieldValue, ExtendedUser } from '@piano/types/pianoTp';
import { ExtendedProfile } from '@piano/types/pianoState';
import { defaultExtendedProfile } from '@piano/config/defaults';
const pickValue = (fieldName: string, values: CustomFieldValue[]): string => {
  const field = values.find((item) => item.field_name === fieldName);
  let fieldValue = '';
  if (field) {
    fieldValue = field.value;
    if (fieldValue.startsWith('[') && fieldValue.endsWith(']')) {
      fieldValue = fieldValue.substring(2, fieldValue.length - 2);
    }
  }

  return fieldValue;
};
export const collectExtendedProfile = (data: ExtendedUser): ExtendedProfile => {
  if (Object.keys(data).length !== 0) {
    let abandonedCartData: ExtendedProfile['abandonedCartData'] = null;

    try {
      const abandonedCartDataString = pickValue('abandonedCartData', data.custom_field_values);
      abandonedCartData = abandonedCartDataString ? JSON.parse(abandonedCartDataString) : null;
    } catch (e) {
      console.log('Error parsing abandonedCartData', e);
    }

    return {
      birthday: pickValue('birthday', data.custom_field_values),
      phone: pickValue('phone', data.custom_field_values),
      sex: pickValue('sex', data.custom_field_values),
      location: pickValue('location', data.custom_field_values),
      language: pickValue('language', data.custom_field_values),
      displayName: pickValue('displayName', data.custom_field_values),
      cxenseUserId: pickValue('cxenseUserId', data.custom_field_values),
      cxenseUserHash: pickValue('cxenseUserHash', data.custom_field_values),
      agreedToMarketingConsent: pickValue('personal-data-processing-for-marketing-purposes', data.custom_field_values) === 'true',
      hasRecentlyEndedSubscription: pickValue('hasRecentlyEndedSubscription', data.custom_field_values) === 'true',
      abandonedCartData,
      isAbandonedCartActive: pickValue('isAbandonedCartActive', data.custom_field_values) === 'true',
    };
  }

  return Object.assign({}, defaultExtendedProfile);
};
