
import Vue from 'vue';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import { LinkData } from '@root/modules/navigation/types/navigation';

interface Props {
  labels: LinkData[];
  pill: boolean;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  name: 'HeadlineLabels',
  components: {
    LinkHandler,
  },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    pill: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
});
