import GalleryService from '@gallery/services/Gallery';
import { GetGalleries } from '@gallery/types/Gallery';

export default class GalleryData {
  private readonly _id: string = '';
  private readonly _galleryService: GalleryService = new GalleryService();
  private _isFetching = false;
  gallery: GetGalleries['data']['gallery']['items'][0] = {
    id: '',
    images: {
      items: [],
      pager: {
        count: 0,
        limit: 500,
        offset: 0,
      },
    },
    metadata: {
      adsDisabled: false,
    },
  };

  activePictureIndex = 0;

  constructor(id: string) {
    this._id = id;
  }

  async fetchInitialGallery() {
    await this.fetch(0);
  }

  async fetch(collectionOffset?: number) {
    const parsedCollectionOffset = collectionOffset ?? this.gallery.images.pager.offset + this.gallery.images.pager.limit;

    if ((parsedCollectionOffset !== 0 && !this.shouldFetchMore(parsedCollectionOffset)) || this._isFetching) {
      return;
    }

    this._isFetching = true;

    const collectionPager = {
      offset: parsedCollectionOffset,
      limit: this.gallery.images.pager.limit,
    };

    const response = await this._galleryService.fetch({ input: { id: this._id }, collectionInput: { pager: collectionPager } });
    const gallery = response.gallery.items[0];

    if (!gallery) {
      this._isFetching = false;
      return;
    }

    if (parsedCollectionOffset <= 0) {
      this.gallery = gallery;
    } else {
      this.gallery.images.items.push(...gallery.images.items);
      this.gallery.images.pager = gallery.images.pager;
    }

    this._isFetching = false;
  }

  private shouldFetchMore(offset: number) {
    return this.gallery.images.pager.count > offset;
  }
}
