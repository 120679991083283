// @ts-nocheck
/* eslint-disable */
/* tslint:disable */

import { loadScripts } from '@analytics/util/scriptLoader';

export default async function loadGoogleTagManagerScript(containerId: string) {
  await (async function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var dl = l != 'dataLayer' ? '&l=' + l : '';
    var j = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    await loadScripts(j, { async: true });
  })(window, document, 'script', 'dataLayer', containerId);
}
