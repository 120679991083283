import { Analytics } from '@analytics/types/Analytics';
import Awindow from '@analytics/types/Awindow';
import logger from '@analytics/util/logger';
import { loadScripts } from '@analytics/util/scriptLoader';

import { GetSiteControlOptions } from './types/GetSiteControl';

declare let window: Awindow;

export default class GetSiteControl implements Analytics {
  private options!: GetSiteControlOptions;
  private edTracker!: Awindow['EDTracker'];

  /**
   * Load script and initialize tracker
   */
  public async init(options?: GetSiteControlOptions) {
    if (options) {
      this.options = options;
    }

    if (!this.options?.url) {
      logger.error('GetSiteControl options are not provided');
      return;
    }

    await this.loadScript(this.options?.url);

    if (!window.gsc) {
      throw new Error('GetSiteControl script does not exists on page');
    }
  }

  public params(params: Record<string, unknown>) {
    window.gsc('params', params);
  }

  public event(type: string, cb: (widgetId: number, data: any) => void) {
    if (!window.gsc) {
      logger.error('GetSiteControl script does not exists on page');
      return;
    }

    window.gsc(type, (widgetId, data) => cb(widgetId, data));
  }

  private async loadScript(url: string) {
    if (!window.gsc) {
      await loadScripts(url, { async: true });
    }
  }
}
