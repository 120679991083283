import BaseService from '@root/common/base/BaseService';
import { ServiceInterface } from '@root/common/types/service';

import { GetPlayerArticlesData, GetPlayerArticlesVariables, AdaptedGetPlayerArticlesData } from '@root/modules/player/types/PlayerArticles';
import getPlayerArticles from '@root/modules/player/graphql/getPlayerArticles.graphql';
import { ArticleAdapter } from '@root/modules/player/utils/ArticleAdapter';

type ServiceVariables = GetPlayerArticlesVariables;
type Response = GetPlayerArticlesData;
type AdaptedResponse = AdaptedGetPlayerArticlesData;

export default class PlayerArticles extends BaseService implements ServiceInterface {
  private async fetchArticles(variables: ServiceVariables) {
    const apiProvider = this.createProvider('GraphQL');

    apiProvider.selectAPI('content-api-v3');
    apiProvider.setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });

    if (!variables.id.length) {
      this.handleError();
    }

    const options = Object.assign({ query: getPlayerArticles }, { variables });
    const response = await apiProvider.query<Response>(options);
    const data = response.data;

    if (!data.articles.items.length) {
      this.handleError();
    }

    const adaptedResponse: AdaptedResponse = {
      items: [],
    };

    for (const article of data.articles.items) {
      adaptedResponse.items.push(new ArticleAdapter(article).adapt());
    }

    return adaptedResponse;
  }

  public handleError() {
    console.error('Failed to get articles for player');
  }

  public async fetch(variables: ServiceVariables) {
    return await this.fetchArticles(variables);
  }
}
