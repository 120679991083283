import { render, staticRenderFns } from "./HeadlineList.vue?vue&type=template&id=112cbbdb&scoped=true"
import script from "./HeadlineList.vue?vue&type=script&lang=ts"
export * from "./HeadlineList.vue?vue&type=script&lang=ts"
import style0 from "./HeadlineList.vue?vue&type=style&index=0&id=112cbbdb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "112cbbdb",
  null
  
)

export default component.exports