
import Vue from 'vue';

interface Props {
  value: string;
  validate: boolean;
  rows: number;
  maxlength: number;
  placeholder: string;
  focus: boolean;
}

interface Data {
  isValid: boolean | null;
  savedValue: string;
}

interface Methods {
  validateValue: (value: string) => boolean;
  handleInput: (value: any) => void;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  directives: {
    focus: {
      inserted(el, binding) {
        if (binding.value) {
          el.focus();
        }
      },
    },
  },
  props: {
    value: {
      type: String,
      default: '',
      required: false,
    },
    validate: {
      type: Boolean,
      default: false,
      required: false,
    },
    rows: {
      type: Number,
      required: false,
    },
    maxlength: {
      type: Number,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    focus: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      isValid: null,
      savedValue: this.value,
    };
  },
  watch: {
    validate: {
      handler(value: boolean) {
        if (value) {
          this.handleInput(this.savedValue);
        }
      },
      immediate: true,
    },
  },
  methods: {
    validateValue(value) {
      return !!value;
    },
    handleInput(value) {
      if (this.validate) {
        this.isValid = this.validateValue(value);

        this.$emit('is-valid', {
          isValid: this.isValid,
        });
      }

      this.savedValue = value;
      this.$emit('input', value);
    },
  },
});
