import productionChannelConfig from './productionChannelConfig';
import testChannelConfig from './testChannelConfig';

export const validHosts = /^([a-z0-9_-]*\.)*(delfi\.)(ee|lv|lt)$/i;

const envConfig: { [key: string]: Channel[] } = {
  production: productionChannelConfig,
  review: productionChannelConfig,
  test: testChannelConfig,
};

const channelEnv = process.env.CHANNEL_CONFIG_ENV || 'production';

export default envConfig[channelEnv] as Channel[];

export interface Channel {
  name: string;
  external_id: string;
  url: string;
  domain: string;
  locale: string;
  tag_channel?: boolean;
}
