// Official vendors list, must be fetched in SSR
// const vendorsListUrl = 'https://quantcast.mgr.consensu.org/GVL-v2/';
const vendorsListUrl = 'https://vendor-list.consensu.org/v3';
const nonTcfVendorsListUrl = '/api/non-tcf-partners.json';
import { CookieConsentGroup } from '@root/modules/analytics/types/analytics';
const consentDefault: CookieConsentGroup[] = [
  {
    type: 'functional',
    selected: true,
    mandatory: true,
    settings: [
      {
        type: 'specialPurposes',
        value: ['1', '2'],
      },
      {
        type: 'features',
        value: ['1', '2', '3'],
      },
    ],
  },
  {
    type: 'analytical',
    selected: false,
    settings: [
      {
        type: 'stacks',
        value: ['21'],
      },
    ],
    mandatory: false,
  },
  {
    type: 'personalization',
    selected: false,
    settings: [
      {
        type: 'stacks',
        value: ['1', '12', '42'],
      },
    ],
    mandatory: false,
  },
  {
    type: 'geolocation',
    selected: false,
    settings: [
      {
        type: 'specialFeatures',
        value: ['1'],
      },
    ],
    mandatory: false,
  },
  {
    type: 'ads',
    selected: false,
    extraInfo: true,
    settings: [
      {
        type: 'stacks',
        value: ['7'],
      },
      {
        type: 'purposes',
        value: ['1'],
      },
    ],
    mandatory: false,
  },
];
const localConsentCookieName = 'consent';
const euConsentCookieName = 'em-euconsent-v2';
const nonEuConsentCookieName = 'addtl_consent';

const cmpData = {
  id: 363,
  version: 2,
  consentScreen: 1,
};

/**
 * Non-TCF Partners ids accepted by EM:
 * 89 - Facebook
 * 108 - Booking.com
 * 326 - Microsoft
 * 864 - Batch Media
 * 1205 - Netflix
 * 2046 - JW Player
 * 2677 - Vimeo
 */
const nonTcfPartners = [864, 89, 108, 1205, 326, 2677, 2046];

export { nonTcfVendorsListUrl, consentDefault, localConsentCookieName, euConsentCookieName, nonEuConsentCookieName, cmpData, nonTcfPartners, vendorsListUrl };
