
import Vue from 'vue';
import BaseIcon from '@core/components/UI/BaseIcon.vue';
import { CustomIcon, CustomIconWithSrc, CustomIconWithHtml } from '@core/types/CustomIcon';

interface Computed {
  customIconSrc: CustomIconWithSrc['src'];
  customIconHtml: CustomIconWithHtml['html'];
}

interface Props {
  icon: string;
  customIcon: CustomIcon;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  components: {
    BaseIcon,
  },
  props: {
    icon: {
      type: String,
      required: false,
    },
    customIcon: {
      type: Object,
      required: false,
    },
  },
  computed: {
    customIconSrc() {
      if (this.customIcon && 'src' in this.customIcon) {
        return this.customIcon.src;
      }

      return '';
    },
    customIconHtml() {
      if (this.customIcon && 'html' in this.customIcon) {
        return this.customIcon.html;
      }

      return '';
    },
  },
});
