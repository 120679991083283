import { loadScripts } from '@analytics/util/scriptLoader';

import { Analytics } from '@analytics/types/Analytics';

import Awindow from '@analytics/types/Awindow';
import logger from '@analytics/util/logger';
import {
  EventOptions,
  ExternalId,
  CxenseOptions,
  PageViewOptions,
  Params,
} from './types/CXense';

declare let window: Awindow;

export default class Cxense implements Analytics {
  private options!: CxenseOptions;
  private cX: Awindow['cX'];

  /**
   * Load script and initialize tracker
   */
  public async init(options: CxenseOptions) {
    this.options = options;

    if (!this.options?.uid) {
      logger.error('cXense id not provided');
      return;
    }

    await this.loadScript();

    if (!window.cX) {
      logger.error('cXense init called but library not found on page');
      return;
    }

    this.cX = window.cX;

    if (this.options.consent) {
      this.cX.callQueue.push(['requireConsent']);
      this.cX.callQueue.push(['setConsent', this.options.consent]);
    }

    if (this.cX.callQueue) {
      this.cX.callQueue.push(['setSiteId', this.options.uid]);
    }
  }

  public setExternalId(externalId: ExternalId) {
    this.options.externalId = externalId;
  }

  public getUserId(): string | void {
    if (!this.cX?.getUserId) {
      logger.error('cXense getUserId called but library not found on page');
      return;
    }

    return this.cX.getUserId();
  }

  public push(
    type: string,
    params: string | Record<string, any> | (() => void),
  ): void {
    if (!this.cX) {
      logger.error('cXense push called but library not found on page');
      return;
    }

    this.cX.callQueue.push([type, params]);
  }

  public pageView(options: PageViewOptions = {}, params?: Params) {
    if (!this.cX) {
      logger.error('cXense pageView called but library not found on page');
      return;
    }

    this.cX.callQueue.push(['initializePage']);

    if (this.options.externalId) {
      this.cX.callQueue.push(['addExternalId', this.options.externalId]);
    }

    if (params) {
      this.cX.callQueue.push(['setCustomParameters', params]);
    }

    this.cX.callQueue.push(['sendPageViewEvent'], options);
  }

  public sendEvent(
    type: string,
    customParams: Params = {},
    options: EventOptions = null,
  ) {
    if (!this.cX) {
      logger.error('cXense sendEvent called but library not found on page');
      return;
    }

    this.cX.callQueue.push(['sendEvent', type, customParams, options]);
  }

  private async loadScript() {
    const script = `http${
      window.location.protocol === 'https:' ? 's://s' : '://'
    }cdn.cxense.com/cx.js`;

    try {
      await loadScripts(script);
    } catch (e) {
      console.error('cXense loadScript error', e);
    }
  }
}
