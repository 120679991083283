const et_EE = {
  authors: 'autorid',
  latest: 'viimased',
  popular: 'loetumad',
  search: 'otsing',
  author: 'autor',
  issues: 'numbrid',
  issue: 'number',
  category: 'kategooria',
  topic: 'teema',
  page: 'leht',
  profile: 'klient/konto',
  password: 'klient/salasona',
  article: 'artikkel',
  comments: 'kommentaarid',
  bookmarks: 'jarjehoidja',
};

export default et_EE;
