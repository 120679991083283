import { Plugin } from '@nuxt/types';
import { IVueI18n } from 'vue-i18n';
import { loadScripts } from '@root/common/utils/scriptLoader';

interface OneSignalWindow extends Window {
  // https://github.com/OneSignal/OneSignal-Website-SDK/blob/main/types/globals/serviceworker.d.ts
  OneSignal: any;
}

declare const window: OneSignalWindow;

const oneSignal: Plugin = async (ctx) => {
  const { app, store } = ctx;
  const oneSignalSettings = app.$channelConfig('features').onesignal;
  const settings = app.$channelConfig('settings');
  const i18n = app.i18n as IVueI18n;

  if (!oneSignalSettings?.appId || !oneSignalSettings?.safariWebId) {
    return;
  }

  try {
    // Can't be loaded over HTTP
    await loadScripts('https://cdn.onesignal.com/sdks/OneSignalSDK.js');
  } catch (e) {
    // OneSignal can be handled as a tracker for some content blockers
    // So there's no point to capture these errors to Sentry
    return;
  }

  const OneSignal = window.OneSignal || [];

  const { actionMessage, acceptButtonText, cancelButtonText } = oneSignalSettings.promptOptions.slidedown;
  const { message } = oneSignalSettings.welcomeNotification;

  OneSignal.push(() => {
    OneSignal.init({
      appId: oneSignalSettings.appId,
      safari_web_id: oneSignalSettings.safariWebId,
      promptOptions: {
        slidedown: {
          enabled: true,
          autoPrompt: false,
          timeDelay: 5,
          pageViews: 2,
          actionMessage: actionMessage || i18n.t('onesignal.prompt_options.slidedown.action_message'),
          acceptButtonText: acceptButtonText || i18n.t('onesignal.prompt_options.slidedown.accept_button_text'),
          cancelButtonText: cancelButtonText || i18n.t('onesignal.prompt_options.slidedown.cancel_button_text'),
        },
      },
      persistNotification: false,
      welcomeNotification: {
        title: settings.name,
        message: message || i18n.t('onesignal.welcome_notification.message'),
      },
    });
  });
  // Emit onsignal push noitfication click event
  // https://documentation.onesignal.com/docs/google-analytics#web-push-ga-guide
  OneSignal.push([
    'addListenerForNotificationOpened',
    () => {
      OneSignal.getUserId((userId: string) => {
        store.commit('analytics/setClickEvent', {
          cXense: {
            eventName: 'ONSESIGNAL_NOTIFICATION',
            eventData: { type: 'click', data: { OneSignalUserId: userId, Channel: settings.id } },
          },
        });
      });
    },
  ]);
};

export default oneSignal;
