import dayjs, { OpUnitType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import 'dayjs/locale/et';
import 'dayjs/locale/ru';
import utc from 'dayjs/plugin/utc';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.locale('et');
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(updateLocale);

dayjs.updateLocale('ru', {
  weekdaysShort: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
});

dayjs.updateLocale('ru', {
  monthsShort: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
});

interface IsDateSame {
  date?: string;
  beforeDate: string;
  measureUnit: OpUnitType;
}
const defaultLocale = 'et';
const formatCustomDate = (val: string | number, formatType: string, locale: string = defaultLocale) =>
  String(val && formatType && dayjs(val).locale(locale).format(formatType));

const isDateBetween = (current: string, startTime: string, endTime: string) =>
  Boolean(current && startTime && endTime && dayjs(current).isBetween(startTime, endTime));
const isDateBefore = (date: string, beforeDate: string) => Boolean(date && beforeDate && dayjs(date).isBefore(beforeDate));
const isDateSameOrBefore = (date: string, beforeDate: string) => Boolean(date && beforeDate && dayjs(date).isSameOrBefore(beforeDate));
const isDateSame = ({ date, beforeDate, measureUnit }: IsDateSame) => dayjs(date).isSame(beforeDate, measureUnit);
const getDate = (date: string) => dayjs(date);

export { getDate, formatCustomDate, isDateBetween, isDateSameOrBefore, isDateSame, isDateBefore };
