// @ts-nocheck
/* eslint-disable */
/* tslint:disable */

import { loadScripts } from '@analytics/util/scriptLoader';

export default async function loadMarkerIoScript() {
  !(await (async function (e) {
    if (e.__Marker) {
      return;
    }

    e.__Marker = {};
    const t = [],
      n = { __cs: t };
    [
      'show',
      'hide',
      'isVisible',
      'capture',
      'cancelCapture',
      'unload',
      'reload',
      'isExtensionInstalled',
      'setReporter',
      'setCustomData',
      'on',
      'off',
    ].forEach(function (e) {
      n[e] = function () {
        // eslint-disable-next-line prefer-rest-params
        const r = Array.prototype.slice.call(arguments);
        r.unshift(e), t.push(r);
      };
    }),
      (e.Marker = n);

    await loadScripts('https://edge.marker.io/latest/shim.js', { async: true });
  })(window));
}
