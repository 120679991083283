import BlockType30 from '@headlines/components/block/21-30/BlockType30.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType30: AdminBlockType = {
  component: BlockType30,
  attributes: AdminBlockTypeAttributes({
    articlesLinksHash: '',
  }),
  articlesCount: 0,
};

export default AdminBlockType30;
