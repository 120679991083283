import cropperConfig from '../config/cropper.config';
import { getEnv } from '@root/common/utils/getEnv';

type CropperConfig = typeof cropperConfig;

type BuildPictureUrlArguments = { url?: string; id: string; extension?: string; cropperData?: CropperConfig | Record<string, any | unknown> };

function cropperDataToQueryString(cropperData?: CropperConfig | Record<string, any | unknown>) {
  const isEmptyObject = cropperData && Object.keys(cropperData).length === 0 && cropperData.constructor === Object;

  if (!cropperData || isEmptyObject) {
    return '';
  }

  const cropperDataParams = ['noup'];
  // Array cloning. Otherwise will refer to cropperDataParams.
  const insertedKeys = [...cropperDataParams];

  for (const [key, value] of Object.entries(cropperData)) {
    if (value === undefined || value === null) {
      continue;
    }

    if (typeof value === 'string' && value.toLowerCase() === 'original') {
      continue;
    }

    const mappedKey = cropperConfig[<keyof typeof cropperConfig>key];

    if (mappedKey && !insertedKeys.includes(mappedKey)) {
      cropperDataParams.push(`${mappedKey}=${value}`);
      insertedKeys.push(mappedKey);
    }
  }

  return cropperDataParams.length > 0 ? `?${cropperDataParams.join('&')}` : '';
}

const defaultUrl = getEnv('mediaApiCropper') || 'https://images.delfi.ee/media-api-image-cropper/v1';
const defaultExtension = 'jpg';

export default function buildPictureUrl({ id, cropperData, url = defaultUrl, extension = defaultExtension }: BuildPictureUrlArguments) {
  return `${url}/${id}.${extension}${cropperDataToQueryString(cropperData)}`;
}
