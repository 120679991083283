import { Plugin } from '@nuxt/types';
import { Gemius } from '@analytics/trackers';
import { ChannelPages } from '@root/libs/init-channel/types';

import { getUid } from '@root/modules/analytics/utils/getUid';

const GemiusPlugin: Plugin = async (ctx) => {
  const { app, store, route } = ctx;
  const analytics = app.$channelConfig('analytics');

  if (!analytics?.gemius?.id) {
    return;
  }

  const isNativeApp = Boolean(app.$channelConfig('settings').application.type);
  // channelConfig does not have articleComments or customPage sections
  // in analytics object, so undefined check is necessary
  const pageSettings = app.$channelConfig('page')[route.name as ChannelPages];

  const uid = getUid({ defaultSettings: analytics.gemius, pageSettings: pageSettings?.analytics?.gemius, isNativeApp });

  const gemiusTracker = new Gemius();

  await gemiusTracker.init({ uid });

  const isArticle = route.name === 'article' || route.name === 'issue-article';

  if (!isArticle) {
    gemiusTracker.sendPageEvent();
  }

  app?.router?.afterEach((to) => {
    // Send page view of all pages except article
    const isArticle = to.name === 'article' || to.name === 'issue-article';
    const pageSettings = app.$channelConfig('page')[to.name as ChannelPages];

    const uid = getUid({ defaultSettings: analytics.gemius, pageSettings: pageSettings?.analytics?.gemius, isNativeApp });

    gemiusTracker.changeTrackerUid(uid);

    if (!isArticle) {
      gemiusTracker.sendPageEvent();
    }
  });

  // Watch activeArticlePath store state to send article page view
  store.watch(
    (state) => state.article.activeArticlePath,
    async (activeArticlePath) => {
      if (activeArticlePath && store.state.article.activeArticle) {
        gemiusTracker.sendPageEvent();
      }
    },
    {
      immediate: true,
    }
  );
};

export default GemiusPlugin;
