import { render, staticRenderFns } from "./CookieConsent.vue?vue&type=template&id=5328f8dc&scoped=true"
import script from "./CookieConsent.vue?vue&type=script&lang=ts"
export * from "./CookieConsent.vue?vue&type=script&lang=ts"
import style0 from "./CookieConsent.vue?vue&type=style&index=0&id=5328f8dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5328f8dc",
  null
  
)

export default component.exports