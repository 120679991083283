import { ModuleTree } from 'vuex';
// @ts-ignore
import { pianoStoreModule } from '@piano/store/piano.store';
import { adFormStoreModule } from '@ads/store/adForm.store';

// A regexp matching files containing "store" in their filename
// Regexp must be a static not a variable!, ref: https://github.com/webpack/webpack/issues/4772
const indexModule = require.context('@root/store/', true, /^.*store.*\.ts$/);
const indexFilename = indexModule.keys().find((filename) => filename.includes('./index.'));

const storeModules = require.context('@root/modules/', true, /^.*store.*\.ts$/);
const storeModulesFiles = storeModules.keys();

const modules: ModuleTree<unknown> = {};
if (indexFilename) {
  modules['index'] = {
    ...indexModule(indexFilename),
  };
}

// Get store modules and import them.
for (const fileName of storeModulesFiles) {
  const moduleName: string = fileName.replace(/.*\/|\..*$/g, '');
  // If namespace is used then throw error.
  if (moduleName in modules) {
    throw new Error(`Namespace ${moduleName} already used in store.`);
  }

  modules[moduleName] = {
    namespaced: true,
    ...storeModules(fileName),
  };
}

const createModules = () => ({ ...modules, ...pianoStoreModule, ...adFormStoreModule });
export default createModules;
