import Vue from 'vue';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';
import getItemFromObject from 'lodash/get';
import { LinkData } from '@root/modules/navigation/types/navigation';
import { buildAbsoluteRoute } from '@root/router/utils/buildRoutePath';
import { TrackingData } from '@root/common/types/fragment';

type Settings<T> = T;
export default <T>(settings: Settings<T>) => {
  interface Methods {
    labels: (headline: HeadlineWithRelatedArticles) => (string | undefined)[];
    labelsWithLinks: (headline: HeadlineWithRelatedArticles) => (LinkData | undefined)[];
  }

  interface Props {
    data: {
      items: HeadlineWithRelatedArticles[];
    };
    settings: Settings<T>;
    tracking: TrackingData | undefined;
    domain: string | undefined;
  }

  interface Computed {
    getSettings: Settings<T>;
  }

  return Vue.extend<unknown, Methods, Computed, Props>({
    props: {
      data: {
        type: Object,
        required: true,
      },
      settings: {
        type: Object,
        required: true,
      },
      tracking: {
        type: Object,
        required: false,
      },
      domain: {
        type: String,
        required: false,
      },
    },
    computed: {
      getSettings() {
        return { ...settings, ...this.settings };
      },
    },
    methods: {
      labels(headline) {
        const labels = [];
        const settings = this.getSettings as unknown as Record<string, boolean>;
        settings.channel &&
          getItemFromObject(headline, 'primaryCategory.channel.name') &&
          labels.push(getItemFromObject(headline, 'primaryCategory.channel.name'));
        settings.category && getItemFromObject(headline, 'primaryCategory.name') && labels.push(getItemFromObject(headline, 'primaryCategory.name'));

        return labels;
      },
      labelsWithLinks(headline) {
        const labels = [];
        const settings = this.getSettings as unknown as Record<string, boolean>;
        const linkHash = '#dalcattitle';
        if (settings.channel && headline.primaryCategory?.channel) {
          const { channel } = headline.primaryCategory;
          const channelLabel = {
            name: channel.name,
            href: `https://${channel.domain}${linkHash}`,
          };
          labels.push(channelLabel);
        }
        if (settings.category && headline.primaryCategory) {
          const { id, slug, name, channel } = headline.primaryCategory;
          const pathOptions = {
            type: 'category',
            id,
            slug,
            channel,
          };
          const href = buildAbsoluteRoute({ pathOptions, linkHash });
          const categoryLabel = {
            name,
            href,
          };
          labels.push(categoryLabel);
        }

        return labels;
      },
    },
  });
};
