import { AccessObject, Conversion } from '@piano/types/pianoTp';
import { PianoState } from '@piano/types/pianoState';
import dayjs from 'dayjs';

export const collectSubscriptionInfo = async (
  ridArray: string[],
  userAccesses: AccessObject[],
  activeConversions: Conversion[] | null
): Promise<PianoState['subscriptionInfo'] | null> => {
  let access: AccessObject | null = null;

  access = userAccesses.find((obj) => ridArray.includes(obj.resource.rid) && obj.granted) || null;

  if (access) {
    const startDate = dayjs.unix(access.start_date);
    const accessConversion = activeConversions?.find((obj) => access?.resource.rid === obj.user_access.resource.rid);

    return {
      rid: access.resource.rid,
      type: accessConversion?.type === 'Shared subscription child grand access' ? 'shared' : 'main',
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      name: access.resource.name,
    };
  } else {
    return null;
  }
};
