// Imports in this file have to relative because import aliases are not supported in Nuxt server

import ServiceBase from '@ekd/portal-connector/dist/Service';

import { getEnv } from '../utils/getEnv';

import { ApiUrls } from '../types/apiUrls';
import { ApolloQueryResult } from '@apollo/client/core';
import { ServiceError } from '../types/ServiceError';

import { responseByErrorPatternConfig } from '../../config/responseByErrorPattern.config';

const allowedCodes = [403, 404, 400, 500, 503, 504];
export default class BaseService extends ServiceBase<ApiUrls> {
  // serviceType is needed to throw page-specific error messages
  // serviceType should match page key in translation file
  private readonly _serviceType: string | undefined;

  constructor(variables?: { req?: PortalRootIncomingMessage; serviceType?: string }) {
    super({ req: variables?.req });

    this._serviceType = variables?.serviceType;
    const urls: Record<ApiUrls, string> = {
      'config-api': getEnv('configApi'),
      'content-api-v3': getEnv('contentApiV3').concat('/graphql'),
      'customer-data-api': getEnv('customerDataApi').concat('/graphql'),
      'media-api': getEnv('mediaApi').concat('/graphql'),
      'article-extra-content-api': getEnv('articleExtraContentApi'),
    };

    this.setApiUrls(urls);
  }

  /**
   * Wraps requestWrapper with try catch block to prevent unexpected errors
   */
  public async requestWrapperHandler<T>(requestWrapper: () => Promise<T>): Promise<T> {
    try {
      const data = await requestWrapper();
      return data;
    } catch (e) {
      return e as T;
    }
  }

  public generateErrorData(e: any) {
    if (e instanceof ServiceError) {
      return e;
    }

    const extraData = e.debug?.networkError || null;
    const statusCode = extraData?.statusCode || e.statusCode;
    const error = {
      statusCode,
      message: e.message || 'error.unexpected_system_error',
      extraData: extraData?.result?.errors?.length ? JSON.stringify(extraData.result.errors) : '',
    };
    return error;
  }

  public handleError(error?: { statusCode?: number; extraData?: string; message?: string }) {
    const statusCode = error?.statusCode && allowedCodes.includes(error.statusCode) ? error.statusCode : 500;
    throw new ServiceError({
      message: error?.message || 'error.page_not_found',
      statusCode,
      extraData: error?.extraData || '',
    });
  }

  throwGraphqlOrApolloErrorIfExists(response: Pick<ApolloQueryResult<any>, 'errors' | 'error'>) {
    const graphqlErrors = response.errors; // GraphQL (aka API) errors
    const apolloClientError = response.error; // Apollo Client errors (e.g. network error)
    const firstError = graphqlErrors?.[0] || apolloClientError;

    if (!firstError) {
      return;
    }

    const errorObject = {
      statusCode: 500,
      message: 'error.unexpected_system_error',
      extraData: firstError.message,
    };

    // Some of GraphQL errors are caused by user (invalid input that does not match schema (UUID vs string), etc.)
    // These kind of errors should not throw 500, but 4xx instead
    for (const responseByErrorPattern of responseByErrorPatternConfig) {
      if (responseByErrorPattern.errorMessagePattern.test(firstError.message)) {
        errorObject.statusCode = responseByErrorPattern.responseCode;
        // Use serviceType to provide more specific error message
        // It is used accordingly to translation files to provide correct error message depending on page
        errorObject.message = this._serviceType
          ? `${this._serviceType}.${responseByErrorPattern.responseMessageWithoutPrefix}`
          : responseByErrorPattern.fallbackMessage;

        break;
      }
    }

    this.handleError(errorObject);
  }
}
