import Vue from 'vue';
import { Plugin } from '@nuxt/types';
import { GoogleTagManager } from '@analytics/trackers';
import getEventByKey from '../utils/getEventByKey';
import { GoogleTagManagerEvent } from '@root/modules/analytics/types/analytics';
import events from '../config/googleTagManager';
import interpolate from '@root/common/utils/interpolate';
import { Event } from '@root/modules/analytics/types/GoogleTagManager';

interface EventOnHold {
  data: any;
  params?: string;
}

const googleTagManagerPlugin: Plugin = async (ctx) => {
  const { app, store } = ctx;
  const analytics = app.$channelConfig('analytics');
  const containerId = analytics?.googleTagManager?.containerId;
  const articleViews = ['article', 'issue-article'];
  const _state = Vue.observable({
    eventsOnHold: [] as EventOnHold[],
  });

  if (!containerId) {
    return;
  }

  const googleTagManager = new GoogleTagManager();
  await googleTagManager.init({ containerId });

  const getUserInfoParams = () => ({
    logged_in: store.state.piano.isLoggedIn || false,
    product_type: store.state.piano.subscriptionInfo?.type || 'Empty',
  });

  const sendViewContentEvent = (routeName: string) => {
    const isArticleView = articleViews.includes(routeName);

    if (isArticleView) {
      return;
    }

    store.commit('analytics/setClickEvent', {
      googleTagManager: {
        eventName: 'VIEW_CONTENT',
        eventData: { eventType: 'view', data: { page_type: routeName, is_paid_article: '' } },
      },
    });
  };

  const sendArticleViewContentEvent = () => {
    const routeName = app.router?.currentRoute.name || 'article';
    const isArticleView = articleViews.includes(routeName);
    if (!isArticleView) {
      return;
    }

    const activeArticle = store.state.article.activeArticle;
    const is_paid_article = activeArticle.content.paywall.enabled;

    store.commit('analytics/setClickEvent', {
      googleTagManager: {
        eventName: 'VIEW_CONTENT',
        eventData: { eventType: 'view', data: { page_type: routeName, is_paid_article } },
      },
    });
  };

  const getEventParams = (data: any, params?: string) =>
    params
      ? JSON.parse(
          interpolate(params, {
            data: Object.assign({}, data, getUserInfoParams()),
          })
        )
      : '';

  new Vue({
    computed: {
      isScriptInited() {
        return store.state.piano.isScriptInited;
      },
      eventsOnHold() {
        return _state.eventsOnHold;
      },
      isNewCustomer() {
        return store.state.piano.isNewCustomer;
      },
    },
    watch: {
      isScriptInited: {
        handler(newVal) {
          if (newVal) {
            this.sendAllEvents();
          }
        },
        immediate: true,
      },
      eventsOnHold: {
        handler(newVal) {
          if (newVal.length && this.isScriptInited) {
            this.sendAllEvents();
          }
        },
        immediate: true,
      },
    },
    methods: {
      sendEvent(data: any, params?: string) {
        const event: Event = getEventParams(data, params);
        googleTagManager.sendEvent(event);
      },
      sendAllEvents() {
        for (let i = 0; i < this.eventsOnHold.length; i++) {
          const event = this.eventsOnHold.shift()!;
          this.sendEvent(event.data, event.params);
        }
      },
    },
    created() {
      store.subscribeAction((action) => {
        if (action.type === 'piano/dispatchEvent' && action.payload === 'loginSuccess') {
          store.commit('analytics/setClickEvent', {
            googleTagManager: {
              eventName: 'LOGIN',
              eventData: { eventType: 'click', data: {} },
            },
          });
        }
        if (action.type === 'piano/dispatchEvent' && action.payload === 'registrationSuccess') {
          store.commit('analytics/setClickEvent', {
            googleTagManager: {
              eventName: 'SIGN_UP',
              eventData: { eventType: 'click', data: {} },
            },
          });
        }
      });
    },
  });

  // Watch analytics store eventName change
  store.watch(
    (state) => state.analytics.tracker.googleTagManager.clickEventName,
    async (clickEventName) => {
      if (!clickEventName) {
        return;
      }

      const event = getEventByKey<GoogleTagManagerEvent>(events, clickEventName);

      if (event) {
        if (!store.state.analytics.tracker.googleTagManager.clickEventData) {
          return false;
        }

        const { eventType, data } = store.state.analytics.tracker.googleTagManager.clickEventData;
        const eventData = event.types[eventType];
        const { params } = eventData;

        _state.eventsOnHold.push({ data, params });
      }

      store.commit('analytics/setClickEvent', { googleTagManager: { eventName: null, eventData: null } });
    }
  );

  sendViewContentEvent(app.router?.currentRoute.name || '');

  app.router!.afterEach((to) => {
    sendViewContentEvent(to.name || '');
  });

  // Watch activeArticle store state to send article page view
  store.watch(
    (state) => state.article.activeArticlePath,
    async (activeArticlePath) => {
      if (activeArticlePath && store.state.article.activeArticle) {
        sendArticleViewContentEvent();
      }
    },
    {
      immediate: true,
    }
  );
};

export default googleTagManagerPlugin;
