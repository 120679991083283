import BlockType28 from '@headlines/components/block/21-30/BlockType28.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType28: AdminBlockType = {
  component: BlockType28,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    author: true,
    titleComment: true,
    channel: true,
    category: true,
    lead: false,
    pictureShadowColor: '',
    backgroundColor: '',
    iframe: false,
    embedName: '',
    articlesLinksHash: '',
  }),
  articlesCount: 1,
};

export default AdminBlockType28;
