import Vue, { PropType } from 'vue';

import { FragmentContent, FragmentAttrs } from '@core/types/fragment';

// TODO: required attrs { type, code, html } - log and use alert if required attrs are missing
// TODO: check if ID is valid and alert

type Attrs = keyof FragmentAttrs;
interface Settings {
  requiredAttrs: Record<Attrs, boolean>;
}

function createFragment(settings?: Settings) {
  return Vue.extend({
    props: {
      content: {
        type: Object as PropType<FragmentContent>,
        required: true,
      },
    },
    data() {
      return {
        contentType: this.content.type?.toLowerCase(),
        type: this.content.attrs?.type?.toLowerCase() || null,
        code: this.content.attrs?.code,
        alignment: this.content.attrs?.alignment?.toLowerCase() || 'center',
        id: this.content.attrs?.id,
        mounted: false,
      };
    },
    computed: {
      defaultClasses() {
        const { type, contentType, alignment } = this;

        const className: Record<string, boolean> = {};

        className[`fragment`] = true;
        className[`fragment--align-${alignment}`] = !!alignment;
        className[`fragment-${contentType}`] = !!contentType;
        className[`fragment-${contentType}--${type}`] = !!contentType && !!type;

        return className;
      },
    },
    methods: {
      logger(type: 'alert', { name, message, debug }: { name?: string; message: string; debug: unknown }) {
        const smartName = !name ? this.$options.name : name;

        if (type) {
          console.error(`${smartName}: ${message}`, { debug });
        }
      },
      setMountedState(mounted: boolean) {
        this.mounted = mounted;
      },
    },
    mounted() {
      const { attrs } = this.content;

      if (settings?.requiredAttrs['code'] && !attrs?.code) {
        this.logger('alert', { message: 'Code is missing.', debug: this.content });
      }

      if (settings?.requiredAttrs['type'] && !attrs.type) {
        this.logger('alert', { message: 'Type is missing.', debug: this.content });
      }

      if (settings?.requiredAttrs['alignment'] && !attrs.alignment) {
        this.logger('alert', { message: 'Alignment is missing, fallback to center alignment.', debug: this.content });
      }
    },
  });
}

interface ComponentCreator {
  createComponent(): typeof Vue;
}
class Fragment implements ComponentCreator {
  private settings: Settings = {
    requiredAttrs: {
      type: false,
      code: false,
      id: false,
      alignment: false,
    },
  };

  public setRequiredAttrs(attrs: Attrs[]) {
    for (const key of attrs) {
      this.settings.requiredAttrs[key] = true;
    }
  }

  public createComponent() {
    return createFragment(this.settings);
  }
}

export { Fragment };
