import { Plugin } from '@nuxt/types';
import { MarkerIo } from '@analytics/trackers';
import Cookie from 'cookie-universal';

interface MarkerIoPluginWindow {
  toggleMarkerIo: (enable: boolean) => Promise<void>;
}

declare let window: MarkerIoPluginWindow;

const MarkerIoPlugin: Plugin = async (ctx) => {
  const cookiesKey = 'EnableMarkerIo';

  const isMarkerIoEnabledInCookies = (): boolean => {
    const cookies = Cookie();
    return Boolean(cookies.get(cookiesKey)) || false;
  };

  const enableMarkerIoInCookies = () => {
    const cookies = Cookie();
    cookies.set(cookiesKey, true, {
      domain: '.delfi.ee',
    });
  };

  const removeMarkerIoInCookies = () => {
    const cookies = Cookie();
    cookies.remove(cookiesKey, {
      domain: '.delfi.ee',
    });
  };

  // Is needed to create button in article 17988368 and enable corresponding feature in native apps
  window.toggleMarkerIo = async (enable: boolean) => {
    if (!enable) {
      removeMarkerIoInCookies();
      return;
    }

    // Avoid creating duplicate entries
    if (!isMarkerIoEnabledInCookies()) {
      enableMarkerIoInCookies();
    }
  };

  const { app } = ctx;
  const analytics = app.$channelConfig('analytics');
  const project = analytics?.markerIo?.project;

  // Check if tracker can be enabled
  if (!project || !isMarkerIoEnabledInCookies()) {
    return;
  }

  // Setup tracker
  const markerIo = new MarkerIo();

  try {
    await markerIo.init({ project });
  } catch (e) {
    console.log('Marker.io init failed', e);
  }
};

export default MarkerIoPlugin;
