import { Plugin } from '@nuxt/types';
import { AdFormOptions } from '@ads/types/AdForm';

import getAdBlockLayerState from '@root/modules/ads/utils/getAdBlockLayerState';

const portalAds: Plugin = async (ctx) => {
  const { store, app } = ctx;
  // Access channel settings
  const { id, application } = app.$channelConfig('settings');
  const isNativeApp = Boolean(application.type);
  // Access default banner placements
  const placements: Record<string, AdFormOptions> | null = { ...app.$channelConfig('ads').component.adForm.placements };

  const defaultAdsSettings = ({ isLoggedIn, hasChannelAccess }: { isLoggedIn: boolean; hasChannelAccess: boolean }) => {
    return {
      mkw: ['channel_vue', `channel_${id}`, `logged_${isLoggedIn ? 'in' : 'out'}`, ...(isNativeApp ? ['delfi_native_app'] : [])],
      mkv: {
        channel: id,
        user_type: hasChannelAccess ? 'tellija' : 'm-tellija',
      },
      placements,
    };
  };

  store.dispatch('adForm/init');

  const isScriptInitedWatcher = store.watch(
    (state) => state.piano.isScriptInited,
    async (isScriptInited: boolean) => {
      if (isScriptInited) {
        const isLoggedIn = store.state.piano.isLoggedIn;
        const { channelAccess, adFree } = store.state.piano.access;
        const enableAdBlockLayer = !store.state.piano.config.maintenanceMode && getAdBlockLayerState({ application, hasChannelAccess: channelAccess, adFree });

        store.commit('adForm/setAdsRenderingState', true);
        store.commit('adForm/setDefaultAdsSettings', defaultAdsSettings({ isLoggedIn, hasChannelAccess: channelAccess }));
        store.commit('adForm/setAdBlockLayerState', enableAdBlockLayer);
        isScriptInitedWatcher();
      } else {
        store.commit('adForm/setAdsRenderingState', false); // TODO is this needed?
      }
    },
    {
      immediate: true,
    }
  );

  // Update ads settings when customer logs in and has access to channel or has adFree package
  store.watch(
    (state) => state.piano.access,
    async (access) => {
      const { channelAccess, adFree } = access;

      store.commit('adForm/setDisabledAdsCondition', { type: 'subscriber', disable: channelAccess });

      store.commit('adForm/setDisabledAdsCondition', { type: 'adFree', disable: adFree });

      const enableAdBlockLayer = !store.state.piano.config.maintenanceMode && getAdBlockLayerState({ application, hasChannelAccess: channelAccess, adFree });
      store.commit('adForm/setAdBlockLayerState', enableAdBlockLayer);

      const isLoggedIn = store.state.piano.isLoggedIn;

      store.commit('adForm/setDefaultAdsSettings', defaultAdsSettings({ isLoggedIn, hasChannelAccess: channelAccess }));
      store.commit('adForm/setAdsRenderingState', true);
    }
  );
};

export default portalAds;
