import loadGoogleTagManagerScript from './utils/loadGoogleTagManagerScript';
import { Analytics } from '@analytics/types/Analytics';
import Awindow from '@analytics/types/Awindow';
import logger from '@analytics/util/logger';

declare let window: Awindow;

interface GoogleTagManagerOptions {
  containerId: string;
}

export default class GoogleTagManager implements Analytics {
  private dataLayer: Awindow['dataLayer'];

  /**
   * Load script and initialize tracker
   */
  public async init(options: GoogleTagManagerOptions) {
    if (!options.containerId) {
      logger.error('GTM container ID is not provided');
      return;
    }

    try {
      await loadGoogleTagManagerScript(options.containerId);
    } catch (e) {
      console.error('GoogleTagManager loadScript error', e);
    }

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    this.dataLayer = window.dataLayer;
  }

  public sendEvent(event: Record<string, any>) {
    if (!this.dataLayer) {
      logger.error('GTM sendEvent called but library not found on page');
      return;
    }

    this.dataLayer.push(event);
  }
}
