
import Vue from 'vue';
import CustomerDataService from '@root/modules/customer/services/CustomerData.service';
import { CustomerData } from '@root/modules/customer/types/customer';

interface Data {
  customerDataService: CustomerDataService | null;
}

interface Computed {
  bookmarks: CustomerData[];
  isBookmarked: boolean;
  activeArticleId: string;
  customerToken: string;
  bookmarkedArticle: CustomerData | undefined;
}

interface Methods {
  toggleBookmark: () => Promise<void>;
  getBookmarks: () => Promise<void>;
}

interface Props {
  articleId: number | null;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    articleId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      customerDataService: null,
    };
  },
  computed: {
    isBookmarked() {
      return !!this.bookmarkedArticle;
    },
    bookmarks() {
      return this.$store.getters['bookmarks/getBookmarks'];
    },
    activeArticleId() {
      return String(this.articleId || this.$store.getters['article/getActiveArticle']?.id || '');
    },
    bookmarkedArticle() {
      return this.bookmarks.find((bookmark: CustomerData) => bookmark.value === this.activeArticleId);
    },
    customerToken() {
      return this.$store.state.piano.token;
    },
  },
  watch: {
    async activeArticleId() {
      await this.getBookmarks();
    },
    async customerToken() {
      this.customerDataService?.setCustomerToken(this.customerToken);

      if (this.customerToken) {
        await this.getBookmarks();
      }
    },
  },
  async beforeMount() {
    this.customerDataService = new CustomerDataService();

    if (this.customerToken) {
      this.customerDataService.setCustomerToken(this.customerToken);
      await this.getBookmarks();
    }
  },
  methods: {
    async toggleBookmark() {
      if (!this.isBookmarked) {
        await this.$store.dispatch('bookmarks/createBookmark', {
          activeArticleId: this.activeArticleId,
          customerToken: this.customerToken,
        });
      } else {
        await this.$store.dispatch('bookmarks/deleteBookmarkedArticle', {
          bookmarkedArticleId: this.bookmarkedArticle!.id,
          customerToken: this.customerToken,
        });
      }
    },
    async getBookmarks() {
      await this.$store.dispatch('bookmarks/loadBookmarks', {
        activeArticleId: this.activeArticleId,
        customerToken: this.customerToken,
      });
    },
  },
});
