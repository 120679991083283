
import Vue from 'vue';
import { GetGalleries } from '@gallery/types/Gallery';
import Thumb from '@gallery/components/thumb/Thumb.vue';
import BaseIcon from '@core/components/UI/BaseIcon.vue';

interface Methods {
  scrollIntoView: () => void;
  scrollThumb: (direction: boolean) => void;
}

interface Props {
  gallery: GetGalleries['data']['gallery']['items'][0];
  activePictureIndex: number | null;
  isLightboxed: boolean;
  showThumbWrapper: boolean;
}

export default Vue.extend<unknown, Methods, unknown, Props>({
  components: {
    Thumb,
    BaseIcon,
  },
  props: {
    gallery: {
      type: Object,
      required: true,
    },
    activePictureIndex: {
      type: Number,
      required: false,
      default: null,
    },
    isLightboxed: {
      type: Boolean,
      required: false,
    },
    showThumbWrapper: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  watch: {
    activePictureIndex() {
      this.scrollIntoView();
    },
  },
  methods: {
    scrollIntoView() {
      if (!Number.isFinite(this.activePictureIndex)) {
        return;
      }

      const row = this.$refs.thumbRow as HTMLDivElement | undefined;
      const thumb = row?.children[this.activePictureIndex!] as HTMLDivElement | undefined;

      if (!row || !thumb) {
        return;
      }

      const thumbLeftOffset = thumb.offsetLeft;
      const thumbWidth = thumb.clientWidth;

      const rowScrollLeft = row.scrollLeft;
      const rowClientWidth = row.clientWidth;

      if (!(rowScrollLeft <= thumbLeftOffset && thumbLeftOffset + thumbWidth <= rowScrollLeft + rowClientWidth)) {
        (this.$refs.thumbRow as HTMLDivElement).scrollLeft = thumbLeftOffset;
      }
    },
    scrollThumb(direction) {
      const thumbRow = this.$refs.thumbRow as HTMLDivElement;
      const thumbWidth = thumbRow.children[0].clientWidth + 12; // 6px padding from both sides
      const clientWidth = (this.$refs.thumbRow as HTMLDivElement).clientWidth;
      const endPosition = (this.$refs.thumbRow as HTMLDivElement).scrollLeft + (direction ? clientWidth - thumbWidth : -(clientWidth - thumbWidth));
      const totalWidth = thumbRow.scrollWidth;

      if (totalWidth - 500 < endPosition + clientWidth) {
        this.$emit('fetch-more-images');
      }

      (this.$refs.thumbRow as HTMLDivElement).scrollLeft = endPosition;
    },
  },
  mounted() {
    this.scrollIntoView();

    let pos = { left: 0, x: 0, startTime: 0 };
    const thumbRow = this.$refs.thumbRow as HTMLDivElement;

    const mouseDownHandler = (e: MouseEvent | Event) => {
      // @ts-ignore-next-line
      const touches = e.touches ? e.touches[0] : e;

      thumbRow.style.cursor = 'grabbing';
      thumbRow.style.userSelect = 'none';
      thumbRow.style.scrollBehavior = 'unset';

      pos = {
        // The current scroll
        left: thumbRow.scrollLeft,
        // Get the current mouse position
        x: touches.clientX,
        startTime: Date.now(),
      };

      thumbRow.addEventListener('mousemove', mouseMoveHandler);
      thumbRow.addEventListener('touchmove', mouseMoveHandler);
      thumbRow.addEventListener('mouseleave', mouseUpHandler);
      thumbRow.addEventListener('mouseup', mouseUpHandler);
      thumbRow.addEventListener('touchend', mouseUpHandler);
    };

    const mouseMoveHandler = (e: MouseEvent | Event) => {
      // @ts-ignore-next-line
      const touches = e.touches ? e.touches[0] : e;

      // How far the mouse has been moved
      const dx = touches.clientX - pos.x;
      const endPosition = pos.left - dx;

      const viewableWidth = thumbRow.clientWidth + endPosition;
      const totalWidth = thumbRow.scrollWidth;

      if (totalWidth - 500 < viewableWidth) {
        this.$emit('fetch-more-images');
      }

      // Scroll the element
      thumbRow.scrollLeft = endPosition;
    };

    const mouseUpHandler = (e: MouseEvent | Event) => {
      thumbRow.style.cursor = 'grab';
      thumbRow.style.removeProperty('user-select');
      thumbRow.style.scrollBehavior = 'smooth';

      // @ts-ignore-next-line
      const touches = e.touches ? e.touches[0] : e;

      const touchOffset = Math.abs(pos.x - touches.clientX);
      const touchDuration = Date.now() - pos.startTime;

      if (touchDuration < 300 && touchOffset < 25) {
        const indexAttribute = touches.target?.getAttribute('data-index') ?? undefined;
        const index = Number(indexAttribute);

        if (index >= 0) {
          this.$emit('toggle-lightbox', index);
        }
      }

      thumbRow.removeEventListener('mousemove', mouseMoveHandler);
      thumbRow.removeEventListener('touchmove', mouseMoveHandler);
      thumbRow.removeEventListener('mouseleave', mouseUpHandler);
      thumbRow.removeEventListener('mouseup', mouseUpHandler);
      thumbRow.removeEventListener('touchend', mouseUpHandler);
    };

    thumbRow.addEventListener('mousedown', mouseDownHandler);
    thumbRow.addEventListener('touchstart', mouseDownHandler);
  },
});
