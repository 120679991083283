import Vue from 'vue';
import dayjs, { UnitType } from 'dayjs';
import 'dayjs/locale/et';
import utc from 'dayjs/plugin/utc';

dayjs.locale('et');
dayjs.extend(utc);

const formatDate = (val: string) => val && dayjs(val).format('DD.MM.YYYY');
const formatDateWithTime = (val: string) => val && dayjs(val).format('DD.MM.YYYY, HH:mm');
const formatLongDate = (val: string) => val && dayjs(val).format('DD. MMMM YYYY');
const convertToLocal = (val: string) => val && dayjs(val).format();
const convertToISO = (val: string) => val && dayjs(val).utc().format();

const diffFromDate = (data: { to: string; unit: UnitType; from?: string }) => dayjs(data.from).diff(data.to, data.unit);

Vue.filter('formatDate', formatDate);
Vue.filter('formatDateWithTime', formatDateWithTime);
Vue.filter('formatLongDate', formatLongDate);
Vue.filter('convertToISO', convertToISO);
Vue.filter('convertToLocal', convertToLocal);

export { formatDate, formatDateWithTime, formatLongDate, convertToISO, convertToLocal, diffFromDate };
