const isPianoIframeOpen = (): boolean => {
  let isPianoIframeOpen = false;

  try {
    const pianoIframe = document.querySelector('.tp-modal[style*="display: block;"]')?.querySelector('iframe');

    isPianoIframeOpen = Boolean(pianoIframe?.contentWindow);
  } catch (e) {
    console.log(e);
  }

  return isPianoIframeOpen;
};

export default isPianoIframeOpen;
