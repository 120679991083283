import { Config } from '@root/modules/channel/types/channel';
import Cookie from 'cookie-universal';

type Application = Config['settings']['application'];
type GetAdBlockLayerStateArguments = { application: Application; hasChannelAccess: boolean; adFree: boolean };

interface AfpWindow extends Window {
  delfi_afp?: {
    loaded?: boolean;
  };
}

declare const window: AfpWindow;

const adBlockCookieName = '__adblocker';

export default function getAdBlockLayerState({ application, hasChannelAccess, adFree }: GetAdBlockLayerStateArguments) {
  const adBlockEnabledOld = typeof window !== 'undefined' && window.delfi_afp?.loaded === undefined;
  // Check Piano adblocker cookie
  const adBlockEnabled = Cookie().get(adBlockCookieName);
  const isAdFreeOrSubscription = hasChannelAccess || adFree;

  return (adBlockEnabled || adBlockEnabledOld) && !isAdFreeOrSubscription && !application.isSearchBot;
}
