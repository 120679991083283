
import Vue from 'vue';
import { gridConfig } from '@core/config/grid.config';
import LinkHandler from '@core/components/helpers/LinkHandler.vue';
import { HeadlinePublishDate } from './fragments';
import { HeadlineWithRelatedArticles } from '@headlines/types/headline';
import { isDateSame } from '@core/filters/dateFormater';
import { extendRoutePath } from '@root/router/utils/buildRoutePath';

interface Props {
  headlineList: HeadlineWithRelatedArticles[];
  listType: string;
  showComments: boolean;
  showDate: boolean;
  mobileHeadlineListLimit: number;
}

interface Methods {
  getDateFormat: (Headline: HeadlineWithRelatedArticles) => string;
  isCommentsDisabled: (headline: HeadlineWithRelatedArticles['comments']) => boolean;
  getCommentsPath: (route: string) => string;
}

interface Data {
  isMobileView: boolean | null;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  components: {
    LinkHandler,
    HeadlinePublishDate,
  },
  props: {
    headlineList: {
      type: Array,
      required: true,
    },
    listType: {
      type: String,
      default: 'none',
      required: false,
    },
    showComments: {
      type: Boolean,
      default: true,
      required: false,
    },
    showDate: {
      type: Boolean,
      default: true,
      required: false,
    },
    mobileHeadlineListLimit: {
      type: Number,
      default: 5,
      required: false,
    },
  },
  data() {
    return {
      isMobileView: null,
    };
  },
  mounted() {
    const screenWidth = window.screen.width;
    this.isMobileView = screenWidth <= gridConfig.breakpoints.lg;
  },
  methods: {
    getDateFormat(headline) {
      const isHeadlinePublishedToday = isDateSame({ beforeDate: headline.publishAt, measureUnit: 'day' });
      return isHeadlinePublishedToday ? 'HH:mm' : 'DD.MM';
    },
    isCommentsDisabled(comments) {
      return comments.find((comment) => comment.key === 'disableComments')?.value ?? false;
    },
    getCommentsPath(route) {
      return extendRoutePath({ route, suffix: String(this.$t('portal_core.comments')) });
    },
  },
});
