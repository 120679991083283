import Service from '@root/common/base/BaseService';
import { StreamsResponse } from '@headlines/components/block/21-30/block-type-23/types/blockType23';
import { getEnv } from '@root/common/utils/getEnv';

export const fetchStreamsData = async (channel: string) => {
  const service = new Service();
  const apiProvider = service.createProvider('HTTP');
  const streamsUrl = getEnv('streamsApi');

  try {
    return await apiProvider.request<StreamsResponse>({
      url: streamsUrl,
      method: 'get',
      params: {
        channel,
      },
      timeout: 3000,
    });
  } catch (e) {
    console.log('error', e);
    return null;
  }
};
