
/*
adblockFallback: {
  type: 'embed',
  attrs: {
    code: '<strong>html here</strong>',
  },
},
*/
import Vue from 'vue';
import { AdFormOptions } from '@ads/types/AdForm';
import FragmentEmbed from '@core/components/fragments/FragmentEmbed.vue';

interface Props {
  settings: AdFormOptions['adblockFallback'];
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  components: {
    FragmentEmbed,
  },
  props: {
    settings: {
      type: Object as () => AdFormOptions['adblockFallback'],
      default: () => ({}),
      required: false,
    },
  },
  computed: {
    content() {
      return {
        type: 'embed',
        attrs: {
          ...this.settings?.attrs,
          type: 'embed',
        },
      };
    },
  },
});
