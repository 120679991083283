interface Playlist {
  sources: never[];
  file: string;
  image: string;
  title: string;
  mediaid: string;
}

const basePlayerSettings = {
  preload: 'auto' as const,
  width: '100%',
  height: 1,
  volume: 50,
  mute: false,
  autoStart: false,
  playbackRateControls: true,
};

export const getPlayerSettings = ({ jwPlayerKey, playlist }: { jwPlayerKey: string; playlist: Playlist[] }) => {
  return {
    ...basePlayerSettings,
    playlist,
    key: jwPlayerKey,
  };
};
