export default {
  play: 'Mängima',
  pause: 'Pausile',
  nextUp: 'Järgmine video',
  related: 'Seotud videod',
  errors: {
    cantLoadPlayer: 'Vabandust, ei õnnestunud ladida video pleieri, uuendage lehe või proovige hiljem.',
    liveStreamDown: 'Otseülekanne pole hetkel kättesaadav',
    cantPlayInBrowser: 'Teie veebilehitseja ei toeta meie video mängimist. <br>Kui võimalik, proovige mõnda teist veebilehitsejat.',
  },
  settings: 'Sätted',
  fullscreen: 'Täisekraanile',
  exitFullscreen: 'Välju täisvaatest',
  sharing: {
    heading: 'Jaga videot',
  },
  advertising: {
    cuetext: '',
    displayHeading: 'Video algab pärast reklaami',
    loadingAd: 'Reklaam laetakse',
    skipmessage: 'Jäta reklaam vahele xx s',
    skiptext: 'Jäta reklaam vahele',
    admessage: 'Reklaam lõpeb xx sekundi pärast...',
    podmessage: 'Reklaam __AD_POD_CURRENT__/__AD_POD_LENGTH__',
  },
};
