export const getDefaultPromotion = (options: {
  promoCode?: string;
  offerId: string;
  promotions?: Record<string, string>;
  composerPromotions?: Record<string, string>;
}): string | undefined => {
  const { offerId, promoCode, composerPromotions, promotions } = options;
  const defaultPromotions = composerPromotions || promotions;

  return promoCode || defaultPromotions?.[offerId];
};
