import { Analytics } from '@analytics/types/Analytics';
import Awindow from '@analytics/types/Awindow';
import logger from '@analytics/util/logger';

import { HotjarOptions } from './types/Hotjar';
import loadHotjarScript from './utils/loadHotjarScript';

declare let window: Awindow;
export default class Hotjar implements Analytics {
  private options!: HotjarOptions;

  public init(options: HotjarOptions) {
    if (!options.id) {
      logger.error('Hotjar id or sv parameters not provided');
      return;
    }

    this.options = options;

    this.loadScript();
  }

  private loadScript() {
    if (!window.hj) {
      const { id, sv = 6 } = this.options;
      loadHotjarScript({ id, sv });
    }
  }
}
