import BlockType4Component from '@headlines/components/block/1-10/block-type-4/BlockType4.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType4: AdminBlockType = {
  component: BlockType4Component,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    author: false,
    titleComment: true,
    channel: false,
    category: false,
    type: 'list',
    articlesLinksHash: '',
  }),
  articlesCount: 4,
};

export default AdminBlockType4;
