import axios from 'axios';

interface RequestConfig {
  timeout: number;
  headers?: Record<string, string>;
}
interface AccessResponseData {
  access: true;
  token: string;
}
interface NoAccessesResponseData {
  access: false;
}

type ResponseData = AccessResponseData | NoAccessesResponseData;

export default class IpAccessService {
  private readonly _apiUrl: string = '';

  constructor(config?: { apiUrl?: string }) {
    const { apiUrl = '' } = config || {};
    this._apiUrl = apiUrl;
  }

  public async checkAccess(token: string | null): Promise<ResponseData> {
    const config: RequestConfig = {
      timeout: 3000,
    };

    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    try {
      const { data } = await axios.get<ResponseData>(`${this._apiUrl}/token`, config);
      return data;
    } catch (e) {
      console.log('error', e);
      return { access: false };
    }
  }
}
