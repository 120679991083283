import { OfferConfiguration } from '@piano/types/pianoState';
export const getOfferConfiguration = ({
  hasRecentlyEndedSubscription = false,
  offersConfiguration,
}: {
  hasRecentlyEndedSubscription?: boolean;
  offersConfiguration: Record<string, OfferConfiguration>;
}): OfferConfiguration | undefined => {
  const returningCustomerOffer = offersConfiguration['returningCustomer'];
  const defaultCustomerOffer = offersConfiguration['default'];

  return returningCustomerOffer && hasRecentlyEndedSubscription ? returningCustomerOffer : defaultCustomerOffer;
};
