export default [
  {
    name: 'delfi',
    external_id: '1',
    url: 'https://www.test.delfi.ee',
    domain: 'www.test.delfi.ee',
    locale: 'et_EE',
    tag_channel: true,
  },
  {
    name: 'rudelfi',
    external_id: '2',
    url: 'https://rus.test.delfi.ee',
    domain: 'rus.test.delfi.ee',
    locale: 'ru_EE',
    tag_channel: true,
  },
  {
    name: 'ekspress',
    external_id: '49',
    url: 'https://ekspress.test.delfi.ee',
    domain: 'ekspress.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'epl',
    external_id: '47',
    url: 'https://epl.test.delfi.ee',
    domain: 'epl.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'roheportaal',
    external_id: '90',
    url: 'https://roheportaal.test.delfi.ee',
    domain: 'roheportaal.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'maaleht',
    external_id: '45',
    url: 'https://maaleht.test.delfi.ee',
    domain: 'maaleht.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'kroonika',
    external_id: '65',
    url: 'https://kroonika.test.delfi.ee',
    domain: 'kroonika.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'annestiil',
    external_id: '62',
    url: 'https://annestiil.test.delfi.ee',
    domain: 'annestiil.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'eestinaine',
    external_id: '74',
    url: 'https://eestinaine.test.delfi.ee',
    domain: 'eestinaine.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'perejakodu',
    external_id: '60',
    url: 'https://perejakodu.test.delfi.ee',
    domain: 'perejakodu.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'maakodu',
    external_id: '76',
    url: 'https://maakodu.test.delfi.ee',
    domain: 'maakodu.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'omamaitse',
    external_id: '72',
    url: 'https://omamaitse.test.delfi.ee',
    domain: 'omamaitse.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'tervispluss',
    external_id: '64',
    url: 'https://tervispluss.test.delfi.ee',
    domain: 'tervispluss.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'lood',
    external_id: '88',
    url: 'https://lood.test.delfi.ee',
    domain: 'lood.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'arileht',
    external_id: '21',
    url: 'https://arileht.test.delfi.ee',
    domain: 'arileht.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'forte',
    external_id: '14',
    url: 'https://forte.test.delfi.ee',
    domain: 'forte.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'reisijuht',
    external_id: '31',
    url: 'https://reisijuht.test.delfi.ee',
    domain: 'reisijuht.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'bublik',
    external_id: '22',
    url: 'https://bublik.test.delfi.ee',
    domain: 'bublik.test.delfi.ee',
    locale: 'ru_EE',
  },
  {
    name: 'kinoveeb',
    external_id: '67',
    url: 'https://kinoveeb.test.delfi.ee',
    domain: 'kinoveeb.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'lemmikloom',
    external_id: '57',
    url: 'https://lemmikloom.test.delfi.ee',
    domain: 'lemmikloom.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'moodnekodu',
    external_id: '42',
    url: 'https://moodnekodu.test.delfi.ee',
    domain: 'moodnekodu.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'turist',
    external_id: '59',
    url: 'https://turist.test.delfi.ee',
    domain: 'turist.test.delfi.ee',
    locale: 'ru_EE',
  },
  {
    name: 'dekor',
    external_id: '81',
    url: 'https://dekor.test.delfi.ee',
    domain: 'dekor.test.delfi.ee',
    locale: 'ru_EE',
  },
  {
    name: 'jana',
    external_id: '61',
    url: 'https://jana.test.delfi.ee',
    domain: 'jana.test.delfi.ee',
    locale: 'ru_EE',
  },
  {
    name: 'naistekas',
    external_id: '3',
    url: 'https://naistekas.test.delfi.ee',
    domain: 'naistekas.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'tv',
    external_id: '44',
    url: 'https://tv.test.delfi.ee',
    domain: 'tv.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'tasku',
    external_id: '87',
    url: 'https://tasku.test.delfi.ee',
    domain: 'tasku.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'sport',
    external_id: '17',
    url: 'https://sport.test.delfi.ee',
    domain: 'sport.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'ilmateade',
    external_id: '48',
    url: 'https://ilmateade.test.delfi.ee',
    domain: 'ilmateade.test.delfi.ee',
    locale: 'et_EE',
  },
  {
    name: 'pogoda',
    external_id: '38',
    url: 'https://pogoda.test.delfi.ee',
    domain: 'pogoda.test.delfi.ee',
    locale: 'ru_EE',
  },
  {
    name: 'longread',
    external_id: '69',
    url: 'https://longread.test.delfi.ee',
    domain: 'longread.test.delfi.ee',
    locale: 'et_EE',
  },
];
