import { buildRoutePath } from '@root/router/utils/buildRoutePath';
import { Data as HeadlinesData, DataWithHref } from '@root/modules/headlines/types/headlines';
import { TrackingData } from '@root/common/types/fragment';
import { addTrackingParameterToHref } from '@root/common/utils/addTrackingParameterToHref';

type Variables = {
  headline: HeadlinesData;
  locale?: string;
  domain: string | string[];
  fakeDomainEnabled: boolean;
  articlesLinksHash?: string;
  tracking?: TrackingData;
};

export default function getHeadlineWithHref({ headline, domain, fakeDomainEnabled, articlesLinksHash, tracking }: Variables): DataWithHref {
  const pathOptions = {
    type: 'article',
    id: headline.id,
    slug: headline.slug,
    channel: headline.primaryCategory.channel,
  };
  let href = buildRoutePath({ pathOptions, domain: String(domain), fakeDomainEnabled, linkHash: articlesLinksHash });

  if (tracking) {
    href = addTrackingParameterToHref(href, tracking);
  }

  const headlineWithHref = { ...headline, href };
  return headlineWithHref;
}
