import { Plugin } from '@nuxt/types';
import { Clarity } from '@analytics/trackers';

const ClarityPlugin: Plugin = async (ctx) => {
  const { app } = ctx;
  const analytics = app.$channelConfig('analytics');

  // Check if tracker can be enabled
  if (!analytics?.clarity?.enabled) {
    return;
  }

  // Setup tracker
  const clarity = new Clarity();
  clarity.init({ key: analytics.clarity.key });
};
export default ClarityPlugin;
