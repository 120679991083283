
/*
adblockFallback: {
  type: 'adnet',
  attrs: {
    code: 'dee_600x500_live_blog',
    responsive: true,
    width: 600,
    height: 500,
  },
},
*/

import Vue from 'vue';
import { AdFormOptions } from '@ads/types/AdForm';

let counter = 0;
const htmlTemplate = (bannerId: string, width: number, height: number) => `<!doctype html>
<html>
<head>
<title>.</title>
\x3Cscript async src="https://keytarget.adnet.lt/stable/keytarget-delfiee.min.js">\x3C/script>
\x3Cscript>
  window.adnet = window.adnet || [];
  window.adnet.push(['${bannerId}']);
  window.adnet.push(['_initialize']);
\x3C/script>
</head>
<body>
<div id='${bannerId}' style='margin:0px;width:${width}px;height:${height}px;background:url(https://banners.adnetmedia.lt/creatives/Spinner200px.gif) center center no-repeat;background-color:#fafafa;'></div>
`;

interface Data {
  divId: string;
  iframeId: string;
  width: number;
  height: number;
}
interface Methods {
  resize(): void;
}
interface Computed {
  srcdoc: string;
}
interface Props {
  settings: AdFormOptions['adblockFallback'];
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    settings: {
      type: Object as () => AdFormOptions['adblockFallback'],
      default: () => ({}),
      required: false,
    },
  },
  data: () => ({
    divId: `vue-adnet-fb-${++counter}`,
    iframeId: `vue-adnet-fbi-${++counter}`,
    width: 300,
    height: 250,
  }),
  computed: {
    srcdoc() {
      return htmlTemplate(this.settings?.attrs?.code || '', this.width, this.height);
    },
  },
  methods: {
    resize() {
      const wrapper = this.$refs[this.divId] as HTMLDivElement;
      const iframe = this.$refs[this.iframeId] as HTMLIFrameElement;

      // get desired size from wrapper div size
      const rec = wrapper.getBoundingClientRect();
      const w = rec.width;
      const h = (w * this.height) / this.width;

      wrapper.style.height = `${h}px`;

      iframe.style.transformOrigin = '0 0';
      // iframe.style['-webkit-transform-origin'] = '0 0';
      // iframe.style.msTransformOrigin = '0 0';
      // eslint-disable-next-line no-var
      var s = `scale(${w / this.width})`;
      // iframe.style['-webkit-transform'] = s;
      // iframe.style.msTransform = s;
      iframe.style.transform = s + ' translateZ(0px)';
    },
  },
  async mounted() {
    await this.$nextTick();

    this.width = this.settings?.attrs?.width || this.width;
    this.height = this.settings?.attrs?.height || this.height;

    if (this.settings?.attrs?.responsive) {
      this.resize();
      window.addEventListener('resize', this.resize);
    }
  },
  beforeDestroy() {
    if (this.settings?.attrs?.responsive) {
      window.removeEventListener('resize', this.resize);
    }
  },
});
