import { Plugin } from '@nuxt/types';
import { Facebook } from '@analytics/trackers';

import { getConsentCookies } from '@root/modules/analytics/utils/cookieConsent';
import { localConsentCookieName } from '@root/modules/analytics/config/cookie-consent/cookieConsent.config';

import { FacebookPixelEvent } from '@root/modules/analytics/types/analytics';
import events from '../config/facebookPixel';
import interpolate from '@root/common/utils/interpolate';
import getEventByKey from '../utils/getEventByKey';

const FacebookPixel: Plugin = async (ctx) => {
  const { app, store } = ctx;
  const analytics = app.$channelConfig('analytics');
  const { locale, application } = app.$channelConfig('settings');

  if (!analytics?.facebook?.pixelId) {
    return;
  }

  if (application.type && analytics.facebook.disableApplication?.includes(application.type)) {
    return;
  }

  const { specialFeatures } = application;
  const disableConsent = specialFeatures.disableConsentModal || false;

  const { consent: consentCookie = null } = disableConsent
    ? {}
    : ((await getConsentCookies([localConsentCookieName], store.state.analytics.fetchSSRCookies)) as any);
  const consent = !consentCookie || consentCookie.ads.consent || consentCookie.personalization.consent;

  const fb = new Facebook();
  fb.init({ pixelId: analytics.facebook.pixelId, consent });

  // Watch analytics store eventName change
  store.watch(
    (state) => state.analytics.tracker.facebookPixel.clickEventName,
    async (clickEventName) => {
      if (!clickEventName) {
        return;
      }
      const event = getEventByKey<FacebookPixelEvent>(events, clickEventName, locale);

      if (event) {
        if (!store.state.analytics.tracker.facebookPixel.clickEventData) {
          return false;
        }

        const { eventType, data } = store.state.analytics.tracker.facebookPixel.clickEventData;
        const eventData = event.types[eventType];
        const { params } = eventData;
        const eventparams = params ? JSON.parse(interpolate(params, { data })) : '';
        fb.sendCustomEvent(eventparams.type, eventparams.name, { segmentIds: eventparams.segmentIds });
      }
      store.commit('analytics/setClickEvent', { facebookPixel: { eventName: null, eventData: null } });
    }
  );
};

export default FacebookPixel;
