const ru_EE = {
  authors: 'avtory',
  latest: 'poslednie',
  popular: 'populjarnye',
  search: 'poisk',
  author: 'avtor',
  issues: 'novera',
  issue: 'nomer',
  category: 'kategorija',
  topic: 'tema',
  page: 'stranica',
  profile: 'klient/konto',
  password: 'klient/salasona',
  article: 'statja',
  comments: 'kommentarii',
  bookmarks: 'zakladka',
};

export default ru_EE;
