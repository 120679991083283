
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/21-30/blockType23';
import { StreamsPerDay, Stream, StreamsResponse } from './types/blockType23';
import { fetchStreamsData } from '@headlines/services/FetchStreamsData';
import BlockType23StreamsList from '@headlines/components/block/21-30/block-type-23/BlockType23StreamsList.vue';
import { getTime, isDateToday, isLive, isStreamUpcoming } from '@headlines/utils/BlockType23';
import BlockType23StreamsScroller from '@headlines/components/block/21-30/block-type-23/BlockType23StreamsScroller.vue';
import BlockType23StreamsRibbon from '@headlines/components/block/21-30/block-type-23/BlockType23StreamsRibbon.vue';

const defaultSettings = {
  channel: 'tv.delfi.ee',
  streamsLimit: 5,
  showAllStreams: false,
  channelDomain: {
    id: '077cde60-2055-11ec-9724-0242c0a88502',
    name: 'www.delfi.ee',
  },
  streamsPageUrls: {
    et: 'https://tv.delfi.ee/leht/ulekanded-kava',
    ru: 'https://rus.delfi.ee/stranica/live-programma',
  },
  isRibbon: false,
};

interface Props {
  isStreamsPage: boolean;
  settings: BlockTypeSettings;
}

interface Data {
  ribbonRemainingStreamsCount: number | null;
  ribbonStream: Stream | null;
  streamData: StreamsResponse['attributes'] | null;
}

interface Methods {
  getTime: typeof getTime;
  isLive: typeof isLive;
  isStreamUpcoming: typeof isStreamUpcoming;
  processStreamsForRibbon: () => void;
}

interface Computed {
  isRibbonStreamLive: boolean;
  isRibbonStreamToday: boolean;
  locale: string;
  streamsByDay: StreamsPerDay[] | null;
  streamsPageUrl: string;
  streamsPerDayCount: number[];
  streamsLimit: number;
}

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend<Data, Methods, Computed, Props>({
  props: {
    isStreamsPage: {
      type: Boolean,
      default: false,
      required: false,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  components: {
    BlockType23StreamsRibbon,
    BlockType23StreamsList,
    BlockType23StreamsScroller,
  },
  data() {
    return {
      streamData: null,
      ribbonStream: null,
      ribbonRemainingStreamsCount: null,
    };
  },
  computed: {
    isRibbonStreamLive() {
      return this.ribbonStream ? this.isLive(this.ribbonStream) : false;
    },
    isRibbonStreamToday() {
      return this.ribbonStream ? isDateToday(this.ribbonStream?.date.start) : false;
    },
    locale() {
      return this.$i18n.locale;
    },
    streamsByDay() {
      const upcomingStreams = this.streamData?.data.streams.filter((stream) => {
        return this.isStreamUpcoming(stream);
      });

      if (!upcomingStreams) {
        return [];
      }

      const streamsGroupedByDay: StreamsPerDay[] = upcomingStreams
        .reduce((acc: StreamsPerDay[], stream: Stream) => {
          const streamDate = new Date(stream.date.start);
          streamDate.setHours(0, 0, 0, 0);
          const existingDate = acc.find((item) => new Date(item.date).getTime() === streamDate.getTime());

          if (existingDate) {
            existingDate.streams.push(stream);
          } else {
            acc.push({ date: String(streamDate), streams: [stream] });
          }

          return acc;
        }, [])
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      streamsGroupedByDay.forEach((day) => {
        day.streams.sort((a, b) => new Date(a.date.start).getTime() - new Date(b.date.start).getTime());
      });

      return streamsGroupedByDay;
    },
    streamsPageUrl() {
      const streamsPageUrls = this.getSettings.streamsPageUrls;

      if (!streamsPageUrls) {
        return '';
      }

      return this.locale === 'et_EE' ? streamsPageUrls.et : streamsPageUrls.ru;
    },
    streamsPerDayCount() {
      if (!this.streamData?.data.streams) {
        return [];
      }

      const streamsPerDay = this.streamData.data.streams.reduce(
        (acc, stream) => {
          const streamDate = new Date(stream.date.start).toDateString();
          acc[streamDate] = (acc[streamDate] || 0) + 1;
          return acc;
        },
        {} as Record<string, number>
      );
      return Object.values(streamsPerDay);
    },
    streamsLimit() {
      return this.getSettings.streamsLimit;
    },
  },
  methods: {
    getTime,
    isLive,
    isStreamUpcoming,
    processStreamsForRibbon() {
      const liveStreams = this.streamData?.data.streams.filter((stream: Stream) => this.isLive(stream));

      if (liveStreams && liveStreams.length) {
        this.ribbonStream = liveStreams[0];
        this.isRibbonStreamLive = true;
      }

      const upcomingStreams = this.streamData?.data.streams.filter((stream: Stream) => isStreamUpcoming(stream));

      if (upcomingStreams && upcomingStreams.length) {
        const showFirstStreams = upcomingStreams.filter((stream: Stream) => stream.show_first);

        if (showFirstStreams.length) {
          // When there are multiple items with showFirst flag then this should choose the one with
          // the lowest id value, since API returns streams sorted from lowest to highest id
          this.ribbonStream = showFirstStreams[0];
        }

        if (!this.ribbonStream) {
          const streamsSortedByDate = upcomingStreams.sort((a: Stream, b: Stream) => {
            return new Date(a.date.start).getTime() - new Date(b.date.start).getTime();
          });

          if (streamsSortedByDate.length) {
            this.ribbonStream = streamsSortedByDate[0];
          }
        }

        if (this.streamData && this.ribbonStream) {
          this.ribbonRemainingStreamsCount = this.streamData?.settings.streamsCount - 1 || 0;
        }
      }
    },
  },
  async created() {
    let channelToUse = this.getSettings.channelDomain.name;

    if (this.isStreamsPage) {
      channelToUse = this.locale === 'et_EE' ? 'tv.delfi.ee' : 'rus.delfi.ee';
    }

    const response = await fetchStreamsData(channelToUse);

    this.streamData = response?.attributes || null;

    if (this.getSettings.isRibbon) {
      this.processStreamsForRibbon();
    }
  },
});
