import { AdFormConfig } from '@ads/types/AdForm';

export default {
  // All available adForm types
  types: [
    'siteheader',
    'mobilesiteheader',
    'content',
    'tower',
    'gallerytower',
    'mobile',
    'floating',
    'scroller',
    'floor',
    'teaser',
    'interstitial',
    'mobileinterstitial',
    'leaderboard',
    'square',
    'mobileleaderboard',
    'galleryscroller',
  ],
  // These ads are disabled for subscribers
  disableAds: ['tower', 'floating', 'scroller', 'leaderboard', 'mobileleaderboard', 'galleryscroller'],
  mobileAds: ['mobile', 'mobilesiteheader', 'mobileinterstitial', 'floor', 'galleryscroller'],
  // Replaces desktop ads with mobile ads
  mobileFallbackAds: {
    square: 'mobile',
    content: 'mobile',
    siteheader: 'mobilesiteheader',
    scroller: 'floor',
    interstitial: 'mobileinterstitial',
    teaser: 'teaser',
    leaderboard: 'mobileleaderboard',
  },
} as AdFormConfig;
