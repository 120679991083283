import BlockType25 from '@headlines/components/block/21-30/BlockType25.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType25: AdminBlockType = {
  component: BlockType25,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    category: true,
    articlesLinksHash: '',
  }),
  articlesCount: 0,
};

export default AdminBlockType25;
