
import Vue from 'vue';
import HeaderMenu from './HeaderMenu.vue';
import HeaderBottomEmbed from './HeaderBottomEmbed.vue';
import { BottomNavigationProps, Header } from '@root/modules/navigation/types/navigation';

interface Computed {
  getNavBottomSettings: BottomNavigationProps | null;
  showRegularBigNavigation: boolean;
  getNavBottomParentSlug: string;
  getNavBottomItems: any;
}

interface Props {
  navigation: Header;
  hideHeaderMenu: boolean;
}

export default Vue.extend<unknown, unknown, Computed, Props>({
  name: 'Header',
  components: {
    HeaderMenu,
    HeaderBottomEmbed,
  },
  props: {
    navigation: {
      type: Object,
      required: true,
    },
    hideHeaderMenu: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    getNavBottomSettings() {
      return this.navigation?.bottom?.settings || null;
    },
    getNavBottomParentSlug() {
      return this.navigation?.bottom?.parentSlug || '';
    },
    getNavBottomItems() {
      return this.navigation?.bottom?.items || null;
    },
    showRegularBigNavigation() {
      const headerType = this.getNavBottomSettings?.type;
      const isHeaderBottomLinks = this.navigation?.bottom?.items && this.navigation?.bottom?.items.length > 0;
      return (isHeaderBottomLinks && !this.$store.state.navigation.header.overwrite.bottom) || headerType === 'big';
    },
  },
});
