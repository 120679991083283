
import Vue from 'vue';
import { directive as clickOutside } from 'v-click-outside-x';
import rootConfig from '@root/config/root.config';
import { TranslateResult } from 'vue-i18n';

type ShareData = {
  title: string;
  // Text field is never used
  // its value is empty string and it's not replaced
  // it's needed to avoid share problems on Android native app
  // https://gitlab.delfi.net/home/issues/-/issues/4063#note_449804
  text: string;
  url: string;
};

interface ShNavigator extends Navigator {
  share: (data?: ShareData) => Promise<void>;
}

interface ShWindow extends Window {
  [index: string]: any;
  navigator: ShNavigator;
}

declare let window: ShWindow;

interface Data {
  loaded: boolean;
  show: boolean;
  popup: {
    width: number;
    height: number;
    name: string;
  };
  share: ShareData;
  size: string | null;
}

interface Props {
  follow: boolean;
  position: string;
  isInHeader: boolean;
  label: string;
}

interface Computed {
  isMobile: boolean;
  isFollow: boolean;
  facebookUrl: string;
  twitterUrl: string;
}

interface Methods {
  toggleTooltip: (open?: boolean) => void;
  close: () => void;
  showLayer: (text: TranslateResult | string) => void;
  popupWindow: (url: string) => void;
  calculateSize: (childCount: number) => string | null;
  copyLink: (string: string) => void;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  directives: {
    clickOutside,
  },
  props: {
    follow: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    position: {
      // Tooltip can open to: right, left.
      type: String,
      default: () => 'right',
      required: false,
    },
    isInHeader: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    label: {
      // Icon text label
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      loaded: false,
      size: null,
      show: false,
      share: {
        title: '',
        text: '',
        url: '',
      },
      popup: {
        width: 500,
        height: 400,
        name: 'share_dialog',
      },
    };
  },
  computed: {
    isMobile() {
      return this.$ua && this.$ua.deviceType() === 'smartphone' && !this.$ua.isFromTablet();
    },
    isFollow() {
      return this.isMobile ? false : this.follow;
    },
    facebookUrl() {
      return `${rootConfig.social_share.facebook}?u=${encodeURI(this.share.url)}&t=${encodeURI((document && document.title) || this.share.title)}`;
    },
    twitterUrl() {
      return `${rootConfig.social_share.twitter}?u=${encodeURI(this.share.url)}&t=${encodeURI((document && document.title) || this.share.title)}`;
    },
  },
  mounted() {
    this.loaded = true;
  },
  updated() {
    // Get tooltip child count.
    // eslint-disable-next-line no-undef
    const childCount = (this.$refs['icons-social'] && (this.$refs['icons-social'] as ParentNode).childElementCount) || 0;
    this.size = this.calculateSize(childCount);
  },
  methods: {
    popupWindow(url) {
      const params = `width=${this.popup.width},height=${this.popup.height}`;
      window.open(url, this.popup.name, params);
    },
    close() {
      if (this.show) {
        this.toggleTooltip();
      }
    },
    toggleTooltip(open = false) {
      if (open) {
        this.share.url = window.location.href;
        this.share.title = document.title;
      }
      const { title, url, text } = this.share;

      if (window.navigator.share) {
        window.navigator.share({
          title,
          text,
          url,
        });
      } else if (window.AndroidWebAppMobileAPI && window.AndroidWebAppMobileAPI.sharePage) {
        window.AndroidWebAppMobileAPI.sharePage(url, title, text);
      } else {
        this.show = !this.show;
      }

      const $header = document.querySelector('.header-menu--primary');
      if (this.isInHeader) {
        if (!this.isMobile) {
          this.show ? $header?.classList.add('header-action-open') : $header?.classList.remove('header-action-open');
        } else {
          this.show ? document.body.classList.add('mobile-share-open') : document.body.classList.remove('mobile-share-open');
        }
      }
    },
    copyLink(string) {
      let textarea;
      let result;

      try {
        textarea = document.createElement('textarea');
        textarea.setAttribute('readonly', 'true');
        textarea.setAttribute('contenteditable', 'true');
        textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
        textarea.value = string;

        document.body.appendChild(textarea);

        textarea.select();

        const range = document.createRange();
        range.selectNodeContents(textarea);

        const sel = window.getSelection();
        sel?.removeAllRanges();
        sel?.addRange(range);

        textarea.setSelectionRange(0, textarea.value.length);
        result = document.execCommand('copy');
        this.showLayer(this.$t(`info_layer.url_copied`));
      } catch (err) {
        result = null;
      } finally {
        if (textarea) {
          document.body.removeChild(textarea);
        }
      }

      // manual copy fallback using prompt
      if (!result) {
        const isMac = navigator.platform.toUpperCase().includes('MAC');
        const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
        result = prompt(`Press ${copyHotkey}`, string);
        if (!result) {
          return false;
        }
      }
      return true;
    },
    showLayer(text) {
      if (!text) {
        return false;
      }
      const $infoLayer = document.createElement('div');
      $infoLayer.innerHTML = '<span>' + text + '</span>';
      $infoLayer.classList.add('info-layer');
      $infoLayer.style.display = 'block';
      document.body.appendChild($infoLayer);
      setTimeout(function () {
        $infoLayer.style.display = 'none';
        $infoLayer.parentNode?.removeChild($infoLayer);
      }, 2000);
    },
    calculateSize(childCount) {
      let size = null;

      // Calculate tooltip size depending on how many children it has.
      switch (childCount) {
        case 6:
        case 5:
          size = 'large';
          break;
        case 4:
        case 3:
          size = 'medium';
          break;
        default:
          break;
      }
      return size;
    },
  },
});
