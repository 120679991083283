import defaultsConfig from '@root/config/defaults.config';
import { convertArrayToObject } from '@root/common/utils/helpers';
import { RawArticle, Article } from '@root/modules/player/types/PlayerArticles';

export class ArticleAdapter {
  private readonly _article: RawArticle;

  constructor(originalData: RawArticle) {
    this._article = originalData;
    return this;
  }

  private article = (): Article => ({
    ...this._article,
    voiceSettings: convertArrayToObject<Article['voiceSettings']>(this._article.voiceSettings, defaultsConfig.article.voiceSettings),
    content: {
      title: {
        text: this._article.content.title.text || '',
      },
    },
    metaImage: {
      id: this._article.metaImage?.id || '',
    },
  });

  public adapt() {
    return this.article();
  }
}
