import Service from '@root/common/base/Service';
import { ServiceInterface } from '@root/common/types/service';
import { QueryOptions } from '@apollo/client/core';

import { GetMostReadArticles } from '@root/modules/headlines/types/headlines';
import getMostReadArticles from '@root/modules/headlines/graphql/getMostReadArticles.graphql';
import { dataLoader } from '@root/libs/redis/dataloader/dataLoader';

type ServiceVariables = GetMostReadArticles['variables'];

type FetchVariables = GetMostReadArticles['variables'];

type Response = GetMostReadArticles['data'];

export default class MostReadArticlesService extends Service implements ServiceInterface {
  private async fetchMostReadArticles(variables: FetchVariables) {
    try {
      const options = Object.assign({ query: getMostReadArticles }, { variables });
      const dataLoaderOptions = {
        remote: {
          keyPrefix: 'mostread_headlines',
        },
        cacheKeyFn: (params: Record<string, unknown>) => {
          const key = JSON.stringify(params.variables);
          return key;
        },
      };

      const requestWrapper = async (options: QueryOptions): Promise<Response | Error> => {
        const apiProvider = this.createProvider('GraphQL');
        apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });
        const response = await apiProvider.query<Response>(options);

        this.throwGraphqlOrApolloErrorIfExists(response);

        return response.data;
      };
      const redisDataLoader = dataLoader<QueryOptions, Response | Error>(requestWrapper, dataLoaderOptions);
      const response: Response | Error = redisDataLoader ? await redisDataLoader.load(options) : await requestWrapper(options);

      if (response instanceof Error) {
        throw response;
      }

      if (!response?.headlines?.items.length) {
        // FIXME: Throw error-like object
        throw 'Empty headlines';
      }

      return response?.headlines?.items || [];
    } catch {
      return [];
    }
  }

  public async fetch(variables: ServiceVariables) {
    const response = await this.fetchMostReadArticles(variables);

    return response;
  }
}
