export const getHumanReadableTime = (rawSeconds: number) => {
  const minutes = Math.floor((((rawSeconds % 31536000) % 86400) % 3600) / 60);
  const seconds = (((rawSeconds % 31536000) % 86400) % 3600) % 60;

  let resultMinutes = String(minutes);
  let resultSeconds = String(seconds);

  if (minutes < 10) {
    resultMinutes = `0${minutes}`;
  }

  if (seconds < 10) {
    resultSeconds = `0${seconds}`;
  }

  return `${resultMinutes}:${resultSeconds}`;
};
