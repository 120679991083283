import { Context } from '@nuxt/types';
import Cookie from 'cookie-universal';
import cookie from 'cookie';
import url from 'url';

import { isServer } from '../../../common/utils/helpers';

const QUERY_CHANNEL_ID_KEY = 'site';
const COOKIE_CHANNEL_KEY = 'site';

type Req = Context['req'] | PortalRootIncomingMessage;
type Res = Context['res'];

export function getDevChannelIdCookie(req?: Context['req']) {
  let devCookieValue: string | null = null;

  if (process.client) {
    const cookies = Cookie();
    devCookieValue = cookies.get(COOKIE_CHANNEL_KEY) || null;
  } else {
    const cookies = cookie.parse(req?.headers.cookie || '');
    devCookieValue = cookies[COOKIE_CHANNEL_KEY] || null;
  }

  return devCookieValue;
}

// Set channel ID
// When the page is loaded without channel query
// then Portal Root uses this cookie to determine which channel to render
export function setDevChannelIdCookie({ res, channelId }: { res: Res; channelId: string | null }) {
  if (channelId && isServer()) {
    res.setHeader(
      'Set-Cookie',
      cookie.serialize(COOKIE_CHANNEL_KEY, String(channelId), {
        httpOnly: false,
        path: '/',
        maxAge: 60 * 60 * 24 * 365, // 1 year
      })
    );
  }
}

// Get channel ID from url or from cookies
// Example: http://localhost:3000/?site=ekspress.delfi.ee - channelId = ekspress.delfi.ee
export function getDevChannelId(req?: Req) {
  let channelId: string | null = null;

  if (isServer()) {
    const query = req ? req.url && url.parse(req.url, true).query : null;
    channelId = query ? (query[QUERY_CHANNEL_ID_KEY] as string) : null;
  } else {
    const url = new URL(window.location.href);
    channelId = url.searchParams.get('site');
  }

  if (channelId) {
    return channelId;
  }

  // If no channel ID is found in query string, attempt to retrieve it from cookies
  channelId = getDevChannelIdCookie(req);

  return channelId as string;
}

export function getLiveChannelId(req?: Req) {
  const host: string | null = req ? req.headers.host || null : document.location.host;

  if (isServer() && host?.includes('localhost')) {
    console.error('Invalid host header', JSON.stringify(req?.headers));
  }
  return (host as string).replace('.test', '');
}

export function getChannelId(req?: Req) {
  return !process.env.FAKE_DOMAIN_ENABLED ? getLiveChannelId(req) : getDevChannelId(req);
}
