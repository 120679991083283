
import Vue from 'vue';
import { getDate } from '@core/filters/dateFormater';
import { selfAdjustingInterval } from '@media/utils/selfAdjustingInterval';

interface Props {
  startDate: Date;
}

interface Data {
  timer: ReturnType<typeof selfAdjustingInterval> | undefined;
  days: string | null;
  hours: string | null;
  minutes: string | null;
  seconds: string | null;
  isOngoing: boolean;
}

interface Methods {
  padZero(num: number): string;
  updateRemaining(distance: number): void;
  tick(): void;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  props: {
    startDate: {
      type: Date,
    },
  },
  data() {
    return {
      timer: undefined,
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
      isOngoing: true,
    };
  },
  methods: {
    padZero(num: number) {
      return num < 10 ? `0${num}` : String(num);
    },
    updateRemaining(distance) {
      if (isNaN(distance)) {
        return;
      }

      this.days = this.padZero(Math.floor(distance / (1000 * 60 * 60 * 24)));
      this.hours = this.padZero(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
      this.minutes = this.padZero(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      this.seconds = this.padZero(Math.floor((distance % (1000 * 60)) / 1000));
    },
    tick() {
      if (!this.isOngoing) {
        return;
      }

      const currentTime = getDate(new Date().toString());
      const startDate = getDate(this.startDate.toString());
      const distance = Math.max(Number(startDate) - Number(currentTime), 0);
      this.updateRemaining(distance);

      if (distance === 0) {
        // clear the interval
        this.timer?.();
        this.isOngoing = false;
        this.$emit('on-stream-start', true);
      }
    },
  },
  mounted() {
    if (!this.startDate) {
      return;
    }

    this.tick();

    this.timer = selfAdjustingInterval(this.tick.bind(this), 1000);
  },
  destroyed() {
    // clear the interval
    this.timer?.();
  },
});
