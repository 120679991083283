
import Vue from 'vue';
import { Headline } from '@headlines/types/headline';

interface Props {
  authors: Headline['authors'];
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  name: 'HeadlineAuthor',
  props: {
    authors: {
      type: Object,
      required: true,
    },
  },
});
