import getGalleriesQuery from '../apollo/graphql/getGalleries.graphql';
import { GetGalleries } from '../types/Gallery';
import BaseService from '@root/common/base/BaseService';
import { ServiceInterface } from '@root/common/types/service';

type ServiceVariables = GetGalleries['variables'];
type Response = GetGalleries['data'];

export default class Gallery extends BaseService implements ServiceInterface {
  async getGalleries(variables: ServiceVariables) {
    const apiProvider = this.createProvider('GraphQL');
    apiProvider.selectAPI('media-api').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });

    const { input, collectionInput } = variables;
    const queryOptions = {
      variables: {
        input: {
          id: input.id,
          pager: {
            limit: input.pager?.limit ?? 1,
            offset: input.pager?.offset ?? 0,
          },
        },
        collectionInput,
      },
    };
    const options = Object.assign({ query: getGalleriesQuery }, queryOptions);
    const response = await apiProvider.query<Response>(options);

    return response.data;
  }

  public async fetch(variables: ServiceVariables) {
    const response = await this.getGalleries(variables);

    return response;
  }
}
