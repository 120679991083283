const getEventByKey = <T>(events: Record<string, Record<string, T>>, key: string, locale?: string, customNamespace?: string) => {
  const fallbackNamespace = 'base';

  const eventsByNamespace = (locale && events[locale]) || (customNamespace && events[customNamespace]) || events[fallbackNamespace] || null;

  if (!eventsByNamespace) {
    return null;
  }

  return <T>eventsByNamespace[key] || null;
};

export default getEventByKey;
