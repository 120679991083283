import matchChannel from '@root/common/utils/matchChannel';
import getAliasByLocale from '@root/common/utils/getAliasByLocale';
import getLocaleByLang from '@root/common/utils/getLocaleByLang';
import rootConfig from '@root/config/root.config';

import { Context } from '@nuxt/types';
interface PathOptions {
  type: string;
  id: number | string;
  slug?: string;
  channel: {
    language: string;
    domain: string;
  };
}

interface UrlOptions {
  pathOptions: PathOptions;
  domain?: string;
  linkHash?: string;
  fakeDomainEnabled?: boolean;
}

interface ExtendRoutePath {
  route: string;
  prefix?: string;
  suffix?: string;
}

export const buildRelativeRoute = ({ pathOptions, linkHash }: UrlOptions): string => {
  const language = pathOptions.channel?.language || '';
  const path = getAliasByLocale(getLocaleByLang(language.toLowerCase()), pathOptions.type);
  const slug = pathOptions.slug ? `/${pathOptions.slug}` : '';
  return `/${path}/${pathOptions.id}${slug}${linkHash || ''}`;
};

export const buildAbsoluteRoute = ({ pathOptions, fakeDomainEnabled, linkHash }: UrlOptions): string => {
  const relativeRoute = buildRelativeRoute({ pathOptions, linkHash });
  const channel = pathOptions.channel;
  const isTest = typeof window === 'undefined' ? process.env.CHANNEL_CONFIG_ENV === 'test' : /.test.delfi/.test(document.location.hostname);
  const domain = isTest ? channel.domain.replace('.delfi', '.test.delfi') : channel.domain;
  let host = `https://${domain}`;

  if (fakeDomainEnabled) {
    host = typeof window !== 'undefined' ? document.location.origin : rootConfig.mainPortalUrl;
  }

  const absoluteUrl = new URL(relativeRoute, host);

  if (fakeDomainEnabled) {
    absoluteUrl.searchParams.set('site', domain);
  }

  return absoluteUrl.href;
};

export const buildRoutePath = ({ pathOptions, domain, fakeDomainEnabled, linkHash }: UrlOptions): string => {
  const sameChannel = domain && pathOptions.channel ? matchChannel(domain, pathOptions.channel.domain) : true;

  if (sameChannel) {
    return buildRelativeRoute({ pathOptions, linkHash });
  }

  return buildAbsoluteRoute({ pathOptions, fakeDomainEnabled, linkHash });
};

const isAbsolutUrl = (href: string) => {
  const protocolRegEx = /^https?:\/\//i;
  return protocolRegEx.test(href);
};

const getUrlObj = (href: string) => {
  const isAbsolute = isAbsolutUrl(href);
  return isAbsolute ? new URL(href) : new URL(href, rootConfig.mainPortalUrl);
};

export const extendRoutePath = ({ route, prefix, suffix }: ExtendRoutePath) => {
  const isAbsolute = isAbsolutUrl(route);
  const urlObj = getUrlObj(route);

  if (prefix) {
    urlObj.pathname = `/${prefix}${urlObj.pathname}`;
  }

  if (suffix) {
    urlObj.pathname = `${urlObj.pathname}/${suffix}`;
  }

  const updatedRoute = isAbsolute ? urlObj.href : `${urlObj.pathname}${urlObj.search}${urlObj.hash}`;

  return updatedRoute;
};

export const buildQueryUrl = (href: string, { query, route }: { query: Context['route']['query']; route?: Context['route'] }) => {
  const isAbsolute = isAbsolutUrl(href);
  const urlObj = getUrlObj(href);

  Object.keys(query).forEach((key) => {
    if (query[key]) {
      urlObj.searchParams.append(key, query[key].toString());
    }
  });

  if (route) {
    urlObj.hash = route.hash;
  }

  if (isAbsolute) {
    return urlObj.toString();
  } else {
    return `${urlObj.pathname}${urlObj.search}${urlObj.hash}`;
  }
};
